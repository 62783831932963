import React from 'react';

import * as c from '../../../common';

import { FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ResizableTextFieldProps {
  disabled?: boolean;
  field: keyof c.Customer;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  helperText?: string;
}

const ResizableInput = styled('textarea')(
  ({
    theme: {
      palette: { mode },
    },
  }) => `
  box-sizing: border-box;
  min-height: 240px;
  min-width: 320px;
  padding: 16px 12px 12px 12px;
  border-radius: 12px 12px 0 12px;
  color: ${c.themeTuple[mode].input.paper.color as string};
  background-color: ${c.themeTuple[mode].input.paper.backgroundColor as string};
  border: 1px solid var(--ups-input-paper-border-color);
  position: relative;

  &:focus {
    outline: 0;
    border-color: var(--ups-paper-highlight);
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    opacity: 0;
  }
`,
);

const StyledFormControl = styled(FormControl)(
  () => `
  position: relative;
  margin-top: 30px;
  width: 100%; 
  max-width: 618px;
  display: flex;
  align-items: start;
  justify-content: start;
`,
);

const StyledInputLabel = styled('label')<{ error: boolean }>(
  ({
    theme: {
      palette: { mode },
    },
    error,
  }) => `
  position: absolute;
  left: 0;
  top: -30px; 
  color: ${
    error
      ? c.themeTuple[mode].danger
      : (c.themeTuple[mode].input.paper.color as string)
  };
  fontFamily: ${c.themeTuple[mode].fontFamily};
`,
);

const ResizableTextField: React.FC<ResizableTextFieldProps> = ({
  disabled,
  field,
  onBlur,
  onChange,
  value,
  helperText,
}) => {
  const [isTouchDevice, setIsTouchDevice] = React.useState(false);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  const handleResize = React.useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, []);

  React.useEffect(() => handleResize(), [handleResize]);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!isTouchDevice) return;

    const target = e.target as HTMLTextAreaElement;
    const rect = target.getBoundingClientRect();
    const touch = e.touches[0];

    if (!touch) return;

    const startY = touch.clientY;
    const startX = touch.clientX;
    const startHeight = target.offsetHeight;
    const startWidth = target.offsetWidth;

    const withinCorner =
      startX >= rect.right - 25 && startY >= rect.bottom - 25;

    if (!withinCorner) return;

    const handleTouchMove = (moveEvent: TouchEvent) => {
      const moveTouches = moveEvent.touches;

      if (!moveTouches || !moveTouches[0]) return;

      const currentY = moveTouches[0].clientY;
      const newHeight = startHeight + (currentY - startY);
      const currentX = moveTouches[0].clientX;
      const newWidth = startWidth + (currentX - startX);
      target.style.height = `${newHeight}px`;
      target.style.width = `${newWidth}px`;
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      handleResize();
      onBlur(e);
    },
    [handleResize, onBlur],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      handleResize();
      onChange(e);
    },
    [handleResize, onChange],
  );

  return (
    <StyledFormControl error={!!helperText && !value}>
      <StyledInputLabel
        error={!!helperText && !value}
        htmlFor="outlined-multiline-static"
      >
        {c.getFieldLabel(field)}
      </StyledInputLabel>

      <ResizableInput
        disabled={disabled}
        id="outlined-multiline-static"
        onBlur={handleBlur}
        onChange={handleChange}
        onTouchStart={handleTouchStart}
        ref={textAreaRef}
        value={value}
      />
      {helperText && !value && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

export default ResizableTextField;
