import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import * as gs from '../gStyles';

type OnChangeInputNumber = React.Dispatch<
  React.SetStateAction<number | string>
>;

export type NumberInputProps = c.r<{
  on: c.Surface;
  onChange?: OnChangeInputNumber;
  value?: number | string;
}>;

const arrowIconSize = 24;

export default React.memo<NumberInputProps>(function NumberInput({
  on,
  onChange,
  value,
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on);
  const t = r.useTheme();

  const valueIsDefined = typeof value !== 'undefined';

  const handleUp = React.useCallback(() => {
    onChange?.((prevVal) =>
      prevVal || c.zeroes.includes(prevVal) ? Number(prevVal) + 1 : 1,
    );
  }, [onChange]);
  const handleDown = React.useCallback(() => {
    onChange?.((prevVal) => (Number(prevVal) > 1 ? Number(prevVal) - 1 : 1));
  }, [onChange]);

  return (
    <rn.View style={styles.root}>
      <rn.View style={styles.valueWrapper}>
        <rn.Text
          style={value === undefined ? styles.textPlaceholder : styles.text}
        >
          {valueIsDefined ? value : 'e.g. 5'}
        </rn.Text>
      </rn.View>

      <rn.View style={styles.buttons}>
        <rn.Pressable onPress={handleUp}>
          <Icon
            color={t.input[on].color as string}
            name="chevron-up"
            size={arrowIconSize}
          />
        </rn.Pressable>

        <rn.Pressable onPress={handleDown}>
          <Icon
            color={t.input[on].color as string}
            name="chevron-down"
            size={arrowIconSize}
          />
        </rn.Pressable>
      </rn.View>
    </rn.View>
  );
});

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const textBase = {
    color: t.input[on].color,
    fontFamily: t.fontFamily,
    fontSize: 14,
  };

  return {
    buttons: { ...rn.StyleSheet.absoluteFillObject, justifyContent: 'center' },
    root: { ...t.input[on], flexGrow: 1, minHeight: arrowIconSize * 2 },
    text: textBase,
    textPlaceholder: { ...textBase, color: t.input[on].placeholderColor },
    valueWrapper: {
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'center',
    },
  };
});
