import lodashDebounce from 'lodash.debounce';

import { Customer } from '../schema';

/**
 * Converts seconds/microseconds timestamps to milliseconds, leaves milliseconds
 * timestamps untouched. Works for timestamps no older than 2001.
 * @timestamp A timestamp that can be seconds, milliseconds or microseconds.
 * Should be no older than 2001.
 */
export function normalizeTimestampToMs(timestamp: number): number {
  if (timestamp === 0) {
    return timestamp;
  }

  const t = timestamp.toString();

  if (t.length === 10) {
    // is seconds
    return Number(t) * 1000;
  } else if (t.length === 13) {
    // is milliseconds
    return Number(t);
  } else if (t.length === 16) {
    // is microseconds
    return Number(t) / 1000;
  }

  log(`normalizeTimestamp() -> could not interpret timestamp -> ${timestamp}`);

  return Number(t);
}

export const EMPTY_OBJ: Readonly<Record<any, any>> = Object.freeze({});
export const emptyObj = EMPTY_OBJ;

export const EMPTY_ARRAY: readonly any[] = Object.freeze([]);
export const emptyArr: readonly unknown[] = EMPTY_ARRAY;
export const emptyArrStr = EMPTY_ARRAY as readonly string[];
export const emptyArrNumber = EMPTY_ARRAY as readonly number[];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EMPTY_FN = () => {};
export const emptyFn = EMPTY_FN;

/**
 * `[1, 2, 3, 4, 5]` -> `[[1,2], [3,4], [5]]`. `[]` -> `[]`.
 * @param arr The array to split into pairs.
 */
export const toPairs = <T>(arr: T[]): T[][] => {
  const pairs: T[][] = [];

  for (let i = 0; i < arr.length; i++) {
    if (i % 2 === 0) {
      pairs.push([]);
    }

    const toPush = arr[i];
    toPush && pairs[Math.floor(i / 2)]?.push(toPush);
  }

  return pairs;
};

/**
 * Returns true if different.
 * @param a
 * @param b
 * @returns {boolean}
 */
export const areShallowDifferent = <
  T extends Record<string, unknown>,
  U extends Record<string, unknown>,
>(
  newObj: T,
  prevObj: U,
) => {
  for (const key in newObj) {
    // @ts-expect-error I'll deal with this after merge
    if (newObj[key] !== prevObj[key]) return true;
  }
  return false;
};

export const processErr = (e: unknown): string => {
  if (typeof e === 'string') return e;
  if (
    typeof e === 'object' &&
    e !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (e as any).message === 'string'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e as any).message;
  }

  log(e);

  return 'Unknown Error (Could not parse error, check console)';
};

type ObjKey = number | string | symbol;

export const mapKeys = <T>(
  obj: Record<ObjKey, T>,
  mapper: (val: T, key: ObjKey) => ObjKey,
) => {
  const newObj = {} as Record<ObjKey, T>;

  for (const [key, value] of entries(obj)) {
    newObj[mapper(value, key)] = obj[key]!;
  }

  return newObj;
};

export const mapValues = <K extends string, T extends Record<K, unknown>, U>(
  obj: T,
  mapper: (val: unknown, key: string) => U,
): Record<K, U> => {
  const newObj = {} as Record<K, U>;

  for (const [key, value] of Object.entries(obj)) {
    newObj[key as K] = mapper(value, key);
  }

  return newObj;
};

export const pickBy = <K extends string, T>(
  // Partial<T> is used here to accept Partial<T>s as well, then we cast inside.
  obj: Partial<Record<K, T>>,
  predicate: (value: T, key: K) => boolean,
): Record<K, T> => {
  const newObj = {} as Record<K, T>;

  for (const [key, val] of entries(obj)) {
    /**
     * CAST: Typescript limitation 🙄. It's obvious entries() will not bring up
     * non-existing pairs from Partial<T>,
     */
    if (predicate(val as T, key)) {
      // CAST: ditto
      newObj[key] = val as T;
    }
  }

  return newObj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Logger = (...args: any[]) => void;

export let log = null as unknown as Logger;
export let debug = null as unknown as Logger;
export const setLogger = (logger: Logger) => {
  log = logger;
};
export const setLoggerDebug = (logger: Logger) => {
  debug = logger;
};

export const keys = <T extends object, K extends keyof T>(o: T): K[] => {
  return Object.keys(o) as K[];
};

export type ValueOf<T> = T[keyof T];

export const values = <T>(o: T): ValueOf<T>[] => {
  // TODO: Typescript updated typings for Object.values
  // @ts-expect-error
  return Object.values(o) as ValueOf<T>[];
};

export type Entries<T> = [key: keyof T, value: ValueOf<T>][];

export const entries = <T>(o: T): Entries<T> => {
  // TODO: Typescript updated typings for Object.entries
  // @ts-expect-error
  return Object.entries(o) as Entries<T>;
};

export const sortedEntries = <T>(o: T): Entries<T> => {
  const unsortedEntries = entries(o);
  const sortedEntries = unsortedEntries.sort(([keyA], [keyB]) => {
    if (typeof keyA === 'number' && typeof keyB === 'number') {
      return keyA - keyB;
    }
    if (typeof keyA === 'string' && typeof keyB === 'string') {
      return keyA.localeCompare(keyB);
    }
    throw new TypeError(`Expected string or number keys.`);
  });
  return sortedEntries;
};

export const reverseSortedEntries = <T>(o: T): Entries<T> => {
  const unsortedEntries = entries(o);
  const sortedEntries = unsortedEntries.sort(([keyA], [keyB]) => {
    if (typeof keyB === 'number' && typeof keyA === 'number') {
      return keyB - keyA;
    }
    if (typeof keyB === 'string' && typeof keyA === 'string') {
      return keyB.localeCompare(keyA);
    }
    throw new TypeError(`Expected string or number keys.`);
  });
  return sortedEntries;
};

export const uriIsVideo = (url: string) => {
  const isImage =
    url.endsWith('.jpg') ||
    url.endsWith('.jpeg') ||
    url.endsWith('.png') ||
    url.endsWith('.webp');

  return !isImage;
};

type EventBatcherListener<E> = (events: E[]) => void;

export interface EventBatcher<E> {
  eventReceived(event: E): void;
  flush(): void;
  onEvents(listener: EventBatcherListener<E>): () => void;
  off(): void;
}

export interface EventBatcherOpts {
  /**
   * Milliseconds.
   */
  debounceTime: number;
}

export const createEventBatcher = <E>({
  debounceTime,
}: EventBatcherOpts): EventBatcher<E> => {
  let pendingEvents: E[] = [];

  const listeners = new Set<EventBatcherListener<E>>();

  let currTimeout: NodeJS.Timeout | null = null;

  return {
    eventReceived(event) {
      if (currTimeout) {
        clearTimeout(currTimeout);
      }

      pendingEvents.push(event);

      currTimeout = setTimeout(() => {
        listeners.forEach((listener) => {
          listener(pendingEvents);
        });
        pendingEvents = [];
      }, debounceTime);
    },
    flush() {
      if (currTimeout) {
        clearTimeout(currTimeout);
      }
      listeners.forEach((listener) => {
        listener(pendingEvents);
      });
      pendingEvents = [];
    },
    off() {
      listeners.clear();
    },
    onEvents(listener) {
      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    },
  };
};

export type booleans = readonly boolean[];
export type numbers = readonly number[];
export type strings = readonly string[];

export type EmptyFn = () => void;
export type VoidFn = () => void;

export type Writable<T> = {
  -readonly [P in keyof T]: T[P];
};

export type DeepReadonly<T> = T extends Array<infer U>
  ? DeepReadonlyArray<U>
  : T extends Function
  ? T
  : T extends object
  ? DeepReadonlyObject<T>
  : T;

type DeepReadonlyArray<T> = readonly DeepReadonly<T>[];

type DeepReadonlyObject<T> = Readonly<{
  [P in keyof T]: DeepReadonly<T[P]>;
}>;

export type r<T> = DeepReadonly<T>;

export type RRecord<
  K extends number | string | symbol = string | number | symbol,
  T = unknown,
> = r<Record<K, T>>;

export type Dict<
  T,
  K extends number | string | symbol = string | number | symbol,
> = Readonly<Record<K, T>>;

export type DictW<
  T,
  K extends number | string | symbol = string | number | symbol,
> = Record<K, T>;

export type DictB<T, K extends number | string | symbol = string> = r<
  Record<K, T>
>;

export type ReadonlyRecord<T, K extends string = string> = Readonly<
  Record<K, T>
>;

export type ReadonlyRecordPartial<T, K extends string = string> = Partial<
  ReadonlyRecord<T, K>
>;

export type Primitive = boolean | number | string;

/**
 * Creates an array beginning from the lowest number provided and ending in the
 * highest number provided.
 * @param size
 * @param startAt Lowest number, inclusive.
 */
export const range = (size: number, startAt = 0): number[] =>
  new Array(size).fill(null).map((_, i) => i + startAt);

interface IsBetweenParams {
  readonly lowerBound: number;
  readonly upperBound: number;
  readonly value: number;
}

export const isBetween = ({
  lowerBound,
  upperBound,
  value,
}: IsBetweenParams): boolean => value >= lowerBound && value <= upperBound;

const NUMBER_CHARS: ReadonlyArray<string> = '0123456789'.split('');

export const stringIsNumber = (numberStr: string): boolean =>
  numberStr.split('').every((char) => NUMBER_CHARS.includes(char));

export const phoneValidationUrl = 'https://phonevalidation.abstractapi.com/v1';

export const isValidFBKey = (key: unknown) => {
  if (typeof key !== 'string') {
    return false;
  }
  if (key.length !== 20) {
    return false;
  }
  return true;
};

export const numberToMonth: Record<number, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

export const isNumber = (value: string): boolean => {
  let regExp = new RegExp(/^\d*\.?\d*$/);

  if (regExp.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const zip = <T, U>(arr1: T[], arr2: U[]): readonly [T, U][] => {
  const len = arr1.length;
  if (len !== arr2.length) {
    throw new Error(
      `zip() -> arrays not the same length (${arr1.length},${arr2.length})`,
    );
  }
  if (len === 0) {
    return EMPTY_ARRAY as readonly [T, U][];
  }
  const res: [T, U][] = [];
  for (let i = 0; i < len; i++) {
    // CAST: Length checked above so never empty
    res[i] = [arr1[i]!, arr2[i]!];
  }
  return res;
};

export type Zipper<T, U, V> = (a: T, b: U) => V;

export const zipWith = <T, U, V>(
  arr1: T[],
  arr2: U[],
  zipper: Zipper<T, U, V>,
): readonly V[] => {
  const zipped = zip(arr1, arr2);

  return zipped.map(([a, b]) => zipper(a, b));
};

export const zeroFill = (number: number, width: number) => {
  const numberOutput = Math.abs(number);
  const length = number.toString().length;
  const zero = '0';

  if (width <= length) {
    if (number < 0) {
      return '-' + numberOutput.toString();
    } else {
      return numberOutput.toString();
    }
  } else {
    if (number < 0) {
      return '-' + zero.repeat(width - length) + numberOutput.toString();
    } else {
      return zero.repeat(width - length) + numberOutput.toString();
    }
  }
};

export interface DateInfo {
  readonly day: string;
  readonly hour: string;
  readonly minutes: string;
  readonly month: string;
  readonly seconds: string;
  readonly year: string;
}

export const getDateInfo = (timestamp: number): DateInfo => {
  const date = new Date(normalizeTimestampToMs(timestamp));

  const month =
    (date.getUTCMonth() + 1).toString().length === 1
      ? '0' + (date.getUTCMonth() + 1).toString()
      : (date.getUTCMonth() + 1).toString();
  const day =
    date.getUTCDate().toString().length === 1
      ? '0' + date.getUTCDate().toString()
      : date.getUTCDate().toString();
  const year = date.getFullYear().toString();
  const hour = date.getHours().toString();
  const minutes = date.getMinutes().toString();
  const seconds = date.getSeconds().toString();

  return {
    day,
    hour,
    minutes,
    month,
    seconds,
    year,
  };
};

export const isObj = (
  o: unknown,
): o is Record<number | string | symbol, unknown> =>
  typeof o === 'object' && o !== null;

export const normalizePhoneNumber = (phone: string) => {
  const removedParenthesis = phone.replace('(', '').replace(')', '');

  const removedLines = removedParenthesis.replace('-', '');

  const removedSpaces = removedLines.replace(' ', '');

  return removedSpaces;
};
export const isValidUSPhoneNumber = (usPhoneNumber: string): boolean => {
  const normalized = normalizePhoneNumber(usPhoneNumber);

  if (usPhoneNumber !== normalized) {
    throw new TypeError(
      `Normalize phone number before passing it to isValidUSPhoneNumber(), this is to avoid bugs not in here but elsewhere as this hopefully reminds you of normalizing everywhere.`,
    );
  }

  if (normalized.length !== 10) {
    return false;
  }

  const allCharNumbers = stringIsNumber(normalized);
  const someCharsNotNumbers = !allCharNumbers;

  if (someCharsNotNumbers) {
    return false;
  }

  const areaCode = Number(normalized.slice(0, 3));

  return areaCode >= 201 && areaCode <= 989;
};

export const numberToDate = (data: number): string => {
  const date = normalizeTimestampToMs(data as number);

  let year = new Date(date).getUTCFullYear().toString();

  let day = new Date(date).getUTCDate().toString();
  day = day.length === 1 ? '0' + day : day;

  let month = (new Date(date).getUTCMonth() + 1).toString();
  month = month.length === 1 ? '0' + month : month;

  return `${year}-${month}-${day}`;
};

export const uniq = (v: any, i: number, a: readonly any[]): boolean =>
  a.indexOf(v) === i;

export const getObjectDifferences = (obj1: any, obj2: any): string[] => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const allKeys = [...keys1, ...keys2].filter(uniq);

  const differences: any[] = [];

  allKeys.forEach((key) => {
    if (obj1[key] !== obj2[key] && typeof obj1[key] !== 'object') {
      differences.push(key);
    } else if (
      typeof obj1[key] === 'object' &&
      JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
    ) {
      differences.push(key);
    }
  });

  return differences;
};

export const isArray = <T>(o: readonly T[] | unknown): o is readonly T[] =>
  Array.isArray(o);

export const strEmptyArr = '[]' as const;

export const zero = 0;
export const minusOne = -1;

export const zeroBoxed: readonly [0] = Object.freeze([0]);

/**
 * Min and max inclusive
 */
export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const parseInteger = (o: unknown): number => {
  try {
    let res: number = zero;
    if (o === null) return zero;
    if (typeof o === 'undefined') return zero;
    if (typeof o === 'boolean' && !o) return zero;
    if (typeof o === 'string') res = Number.parseInt(o, 10);
    if (typeof o === 'number') res = o;
    if (Number.isNaN(res)) return zero;
    else return res;
  } catch {
    return 0;
  }
};

export const parseInt = parseInteger;

export const placeholderTextColorDark = 'rgba(255,255,255,0.5)';

export const placeholderTextColorLight = 'rgba(0,0,0,0.4)';

export const zeroes = ['0', 0];

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number = 350,
) {
  return lodashDebounce(func, wait);
}

export const isNoteField = (field: keyof Customer) =>
  field.endsWith('Notes') ||
  field === 'site_survey_notes' ||
  field.endsWith('notes');

export const identity = <T>(_: T): T => _;

export interface Opt {
  readonly label: string;
  readonly value: string;
}
export type Opts = readonly Opt[];
export const naOpt: Opt = Object.freeze({
  label: 'n/a',
  value: 'n/a',
});
export const optsWithOnlyNA: Opts = Object.freeze([naOpt]);
