import * as React from 'react';

import * as env from '../env';
import * as schema from '../schema';
import * as utils from '../utils';
import { Customer, MediaItem, MediaKind } from '../schema';
import { Writable, isBetween, range, stringIsNumber } from '../utils';

export type FieldToTrait<T> = Partial<
  Readonly<Record<schema.CustomerField, T>>
>;

export const fieldToLabel: FieldToTrait<string> = {
  air_conditioner_cost: 'Cost',
  air_conditioner_customer_cost: 'Customer Cost',
  air_conditioner_replace_ducts: 'Are you replacing ducts?',
  air_conditioner_size: 'Size',
  air_conditioner: 'Air Conditioner',
  air_conditioner_working: 'Do you currently have an AC?',
  air_conditioner_new_tons: 'How many tons will the new AC be? ',
  air_conditioner_current_tons: 'How many tons is your current AC?',
  air_conditioner_unit_type: 'What type AC unit are you going to  install?',
  // air_conditioner_explain_situation: 'Explain the situation of the home',
  air_conditioner_type_installation: 'Type of Installation',
  air_conditioner_any_swamp_cooler: 'Do you have any swamp cooler(s)?',
  air_conditioner_swamp_cooler_use: 'Are you using a Swamp Cooler?',
  air_conditioner_tons_how_many: 'How many tons will the new AC be?',
  air_conditioner_swamp_cooler_use_remove:
    'Would you be removing any swamp coolers?',
  air_conditioner_replace_curve: 'Are you changing the AC size?',
  attic_fan_cost: 'Cost',
  attic_fan_customer_cost: 'Customer Cost',
  attic_fan_quantity: 'How Many?',
  attic_fan: 'Attic Fan',
  attic_insulation_type: 'What type of insulation?',
  attic_insulation: 'Attic Insulation',
  attic_insulation_how_much: 'Square footage to be insulated',
  attic_insulation_cost: 'Cost',
  attic_insulation_customer_cost: 'Customer Cost',
  attic_square_footage: 'Attic Square Footage',
  battery_cost: 'Cost',
  battery_customer_cost: 'Customer Cost',
  battery_notes: 'Notes',
  battery_type: 'Select Battery Type',
  battery_size: 'Select Battery Size',
  battery: 'Battery',
  cash_cost: 'Cost',
  cash_amount: 'Cashback Amount',
  cash_customer_cost: 'Customer Cost',
  cash_pool_pumps: 'Pool Pumps',
  cash_heat_pumps: 'Heat Pumps',
  cash_whole_house_fans_or_ventilation: 'Whole House Fans / Ventilation',
  cash_efficient_boilers: 'Efficient Boilers',
  cash_geothermal_heat_pumps: 'Geothermal Heat Pumps',
  cash_radiant_floors: 'Radiant Floors',
  cash_water_saving_fixtures: 'Water Saving Fixtures',
  cash_solar_water_heaters: 'Solar Water Heaters',
  cash_on_demand_or_tankless_hot: 'On Demand / Tank-less Hot Water Heater',
  cash_efficient_water_heaters: 'Efficient Water Heaters',
  cash_artificial_turf: 'Artificial Turf',
  cash_irrigation_systems: 'Irrigation Systems',
  cash_windows: 'Windows',
  cash_air_conditioner: 'Air Conditioner',
  cash_floor_insulation: 'Floor Insulation',

  cash: 'Cash',
  cost: 'Cost',
  createdAt: 'Created at',
  custom_eight_fields_cost: 'Cost',
  custom_eight_fields_customer_cost: 'Customer Cost',
  custom_eight_fields_description: 'Description',
  custom_eight_fields_name: 'Name',
  custom_eight_fields: 'Custom 8',
  custom_fields_cost: 'Cost',
  custom_fields_customer_cost: 'Customer Cost',
  custom_fields_description: 'Description',
  custom_fields_name: 'Name',
  custom_fields: 'Custom 1',
  custom_five_fields_cost: 'Cost',
  custom_five_fields_customer_cost: 'Customer Cost',
  custom_five_fields_description: 'Description',
  custom_five_fields_name: 'Name',
  custom_five_fields: 'Custom 5',
  custom_four_fields_cost: 'Cost',
  custom_four_fields_customer_cost: 'Customer Cost',
  custom_four_fields_description: 'Description',
  custom_four_fields_name: 'Name',
  custom_four_fields: 'Custom 4',
  custom_nine_fields_cost: 'Cost',
  custom_nine_fields_customer_cost: 'Customer Cost',
  custom_nine_fields_description: 'Description',
  custom_nine_fields_name: 'Name',
  custom_nine_fields: 'Custom 9',
  custom_seven_fields_cost: 'Cost',
  custom_seven_fields_customer_cost: 'Customer Cost',
  custom_seven_fields_description: 'Description',
  custom_seven_fields_name: 'Name',
  custom_seven_fields: 'Custom 7',
  custom_six_fields_cost: 'Cost',
  custom_six_fields_customer_cost: 'Customer Cost',
  custom_six_fields_description: 'Description',
  custom_six_fields_name: 'Name',
  custom_six_fields: 'Custom 6',
  custom_ten_fields_cost: 'Cost',
  customer_id_number: 'ID Number',
  customer_initials: 'Customer Initials',
  custom_ten_fields_customer_cost: 'Customer Cost',
  custom_ten_fields_description: 'Description',
  custom_ten_fields_name: 'Name',
  custom_ten_fields: 'Custom 10',
  custom_three_fields_cost: 'Cost',
  custom_three_fields_customer_cost: 'Customer Cost',
  custom_three_fields_description: 'Description',
  custom_three_fields_name: 'Name',
  custom_three_fields: 'Custom 3',
  custom_two_fields_cost: 'Cost',
  custom_two_fields_customer_cost: 'Customer Cost',
  custom_two_fields_description: 'Description',
  custom_two_fields_name: 'Name',
  custom_two_fields: 'Custom 2',
  customer_cost: 'Customer Cost',
  customerAddress: 'Customer Address',
  customerEmail: 'Customer Email',
  customerName: 'Customer Name',
  customerPhone: 'Customer Phone',
  customerPhoneAlt: 'Alt Phone Number (opt)',
  date: 'Created At',
  derate: 'Derate',
  derate_cost: 'Cost',
  derate_customer_cost: 'Customer Cost',
  derate_notes: 'Notes',
  duct_insulation_cost: 'Cost',
  duct_insulation_customer_cost: 'Customer Cost',
  duct_insulation: 'Duct Insulation',
  duct_repair_cost: 'Cost',
  duct_repair_customer_cost: 'Customer Cost',
  duct_repair_quantity: 'How Many?',
  duct_repair: 'Duct Repair',
  //#region duct_replacement
  duct_replacement: 'Duct Replacement',
  duct_replacement_qty: 'How many ducts will be replaced?',
  //#endregion duct_replacement
  duct_seal_cost: 'Cost',
  duct_seal_customer_cost: 'Customer Cost',
  duct_seal: 'Duct Seal',
  flat_roof_panels: 'Flat Roof Panels',
  flat_roof_panels_cost: 'Cost',
  flat_roof_panels_customer_cost: 'Customer Cost',
  flat_roof_panels_how_many: 'How Many?',
  globalNotes: 'Global Customer Notes',
  glow: 'Protocol Fee (Glow Gold)',
  glow_cost: 'Cost',
  glow_customer_cost: 'Customer Cost',
  glow_price_per_watt: 'Price Per Kw/h from the utility bill',
  glow_power_output_mw: 'System Size in Kw',
  incentive_adders_description: 'Incentive/Adders Description',
  main_panel_upgrade_cost: 'Cost',
  main_panel_upgrade_customer_cost: 'Customer Cost',
  main_panel_upgrade: 'Main Panel Upgrade (MPU)',
  main_panel_upgrade_needed_or_requested: 'Is needed or requested by customer?',
  main_panel_upgrade_installation_company: 'Installer',
  mini_split: 'Mini Split',
  mini_split_ton1: 'Mini Split System 1Ton',
  mini_split_ton2: 'Mini Split System 2Ton',
  mini_split_cost: 'Cost',
  mini_split_customer_cost: 'Customer Cost',
  manufactured_home_notes: 'Manufactured Home Notes',
  new_ducts_cost: 'Cost',
  new_ducts_customer_cost: 'Customer Cost',
  new_ducts_description: 'Description',
  new_ducts_quantity: 'How Many?',
  new_ducts_replace: 'Replacing Ducts?',
  new_ducts: 'New Ducts',
  // #region new_windows
  new_windows_areas: 'What Areas?',
  new_windows_color: 'Window Colors',
  new_windows_cost: 'Cost',
  new_windows_customer_cost: 'Customer Cost',
  new_windows_grid: 'Grid Windows?',
  new_windows_quantity: 'Total Number of Windows',
  new_windows_sqft_each_window: 'Sqft of each window?',
  new_windows_sliding_glass_sqft: 'Sqft of each sliding glass door?',
  new_windows_sliding_glass: 'Sliding Glass Doors included?',
  new_windows_sliding_glass_how_many:
    'Total Number of Glass Sliding Doors included:',
  new_windows_sqft: 'How Much Square Footage?',
  new_windows_replace: 'Are you replacing the windows on the whole house?',
  new_windows_replace_custom:
    'What areas of home windows will be installed in detail?',
  new_windows_notes: 'Notes',
  new_windows: 'Windows',
  new_windows_california_city: 'Choose a city',
  new_windows_sliding_glass_size: 'Sliding glass door Size',
  newWindows: 'Windows & Sliding Glass Doors',
  // #endregion new_windows
  payment_given_customer: 'Payment Given to Customer',
  pool_pump_cost: 'Cost',
  pool_pump_customer_cost: 'Customer Cost',
  pool_pump: 'Pool Pump',
  roof_cost: 'Cost',
  roof_california_city: 'Choose a city',
  roof_customer_cost: 'Customer Cost',
  roof_square_footage: 'Roof Squares',
  roof_fascia_included: 'Are we including fascia?',
  roof_fascia_included_square_footage:
    'How many linear feet are we going to included?',
  roof_layover_or_tear: 'Choose type of roof work',
  roof_tear_material: 'Material Duration',
  roof_patio_included: 'Are we including a patio?',
  roof_patio_how_many: 'How many sqft of torch down?',
  roof_plywood_replaced: 'Will you be replacing all the plywood?',
  roof_plywood_replaced_square: 'How many squares?',
  roof: 'Roof',
  // Closer
  homeRep: 'EcoHome Rep (Closer)',
  smart_thermostat_cost: 'Cost',
  smart_thermostat_customer_cost: 'Customer Cost',
  smart_thermostat_how_many: 'How Many?',
  smart_thermostat: 'Smart Thermostat',
  solar_tax_cost: 'Cost',
  solar_tax_customer_cost: 'Customer Cost',
  solar_tax: 'Solar Tax Consultant',
  solarCompany: 'Solar Company',
  solarEmail: 'Solar Rep Email',
  // Setter
  solarRep: 'Solar Rep (Setter)',
  sort_key: 'Last Update',
  sub_panel_upgrade: 'Sub Panel Upgrade',
  sub_panel_upgrade_cost: 'Cost',
  sub_panel_upgrade_customer_cost: 'Customer Cost',
  sub_panel_upgrade_notes: 'Sub Panel Upgrade Notes',
  site_survey_notes: 'Site Survey Notes',
  ssn: 'SSN',
  system_size: 'System Size',
  small_system: 'Small System',
  small_system_cost: 'Cost',
  small_system_customer_cost: 'Customer Cost',
  yearHomeBuilt: 'Year Home Was Built',
  panel_removal: 'Panel Removal',
  panel_removal_how_many: 'How many panels do you need to remove?',
  panel_removal_cost: 'Cost',
  panel_removal_customer_cost: 'Customer Cost',
  panel_removal_notes: 'Notes',

  // Checklist

  attach_thermal_camera: 'Attach thermal camera to the phone',

  take_picture_of_home: 'Take picture of home',
  get_debts_report: 'Get debts report',

  perform_energy_assessment: 'Perform energy assessment',
  /**/ use_thermal_camera: 'Use thermal camera',
  /**/ take_quiz: 'Take survey',
  /**/ use_room_that_gets_hot: 'Room that gets hot',
  /**/ use_smoke_pen: 'Use smoke pen',

  take_pictures: 'Take pictures',

  fill_out_prices: 'Fill out prices',

  verify_pricing_or_ppw: 'Verify pricing/PPW (info)',

  show_ecohome_report: `Show ${env.HOLDING_NAME} report`,

  show_solo_report: 'Show Solo report',

  /**/ /**/

  run_credit: 'Run credit',

  change_to_correct_email: 'Change to correct email',

  sign_financing_agreement: 'Sign financing agreement (info)',
  /**/ sign_cosigner: 'Sign co-signer',

  submit_install_docs_in_solo: 'Submit install docs in Solo (info)',
  /**/ counter_sign_rep_install_doc: 'Counter-sign rep install doc (info)',
  /**/ sign_customer_install_docs: 'Sign customer install docs (info)',

  ecohome_report: `${env.HOLDING_NAME} report`,
  /**/ make_sure_ecohome_and_solo_match:
    'Make Sure Ecohome/Solo #s match (info)',
  /**/ take_picture_of_utility_bill: 'Take picture of utility bill',
  /**/ take_picture_of_id: 'Take picture of ID',
  /**/ take_pictures_of_meter: 'Take pictures of meter (example)',
  /**/ submit_detailed_notes_for_efficiencies:
    'Submit detailed notes for efficiencies (info)',
  /**/ review_notes_with_customers: 'Review notes w/ customers',
  /**/ sign_ecohome_report: `Sign ${env.HOLDING_NAME} report`,
  /**/ get_google_review_and_or_pic_with_customer:
    'Get a 5-star Google Review from the client',
  /**/ take_pic_with_customer: 'Take picture with customer',
  /**/ offer_incentives_for_referrals: 'Offer incentives for referrals',

  update_notes_on_HL: 'Update notes on HL',

  report_sale_to_slack: 'Report sale to Slack!',

  // End Checklist
  // Roofing Claiming

  roof_claiming_date: 'Date',
  roof_claiming_homeowner: 'Homeowner',
  roof_claiming_phone: 'Phone',
  roof_claiming_phone_alt: 'Alt Phone',
  roof_claiming_email: 'Email',
  roof_claiming_address: 'Address',
  roof_claiming_city: 'City',
  roof_claiming_zip: 'Zip',
  roof_claiming_deductible: 'Deductible',
  roof_claiming_shingle_manufacturer: 'Shingle Manufacturer',
  roof_claiming_style: 'Style',
  roof_claiming_color: 'Color',
  roof_claiming_drip_color: 'Drip Color',

  roof_claiming_tear_off_layers_shingles: 'Tear off all layers of shingles',
  roof_claiming_install_felt: 'Install Felt',
  roof_claiming_close_valleys: 'Close Valleys ',
  roof_claiming_ridges_color_coordinated: 'Ridges Color Coordinated',
  roof_claiming_install_new_flashings: 'Install New Flashings',
  roof_claiming_replace_ventilation: 'Replace ventilation as needed',
  roof_claiming_install_nails: 'Install nails per code',
  roof_claiming_clean_job_waste_gutters: 'Clean job waste from gutters',
  roof_claiming_two_year_workmanship_warranty: '2 year workmanship warranty ',
  roof_claiming_magnetic_sweep_property:
    'Magnetic sweep property after install ',

  roof_claiming_age: 'Age',
  roof_claiming_layers: 'Layers',
  roof_claiming_predominate_pitch: 'Predominate Pitch',

  roof_claiming_pipe_jack: 'Piple Jack (QTY)',
  roof_claiming_chimney_flashing: 'Chimney Flashing (QTY)',
  roof_claiming_chimney_cap: 'Chimney Cap (QTY)',
  roof_claiming_digital_satellite: 'Digital Satellite (QTY)',
  roof_claiming_gas_cap: 'Gas Vent/Rain Cap (QTY)',
  roof_claiming_other_one: 'Other',

  roof_claiming_turtle_vent: 'Turtle Vent (QTY)',
  roof_claiming_ridge_vent: 'Ridge Vent (QTY)',
  roof_claiming_turbine_vent: 'Turbine Vent (QTY)',
  roof_claiming_power_attic_vent: 'Power Attic Vent (QTY)',
  roof_claiming_other_two: 'Other',
  roof_claiming_other_three: 'Other',

  roof_claiming_notes: 'Roof Claiming Notes',
  roof_claiming_documents_notes: 'Notes',

  roof_claiming_date_loss: 'Date of Loss',
  roof_claiming_insurance_company: 'Insurance Company',
  roof_claiming_claim: 'Claim #',

  roof_claiming_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign: 'Company Signature',

  roof_claiming_printed_name: 'Printed Name',
  roof_claiming_completion_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign_date: 'Date',
  roof_claiming_completion_client_sign_date: 'Date',

  // End Roofing Claiming

  qualify_glow_birth_date: 'Birth Date',
  qualify_glow_city: 'City',
  qualify_glow_email: 'Email',
  qualify_glow_first_name: 'First Name',
  qualify_glow_last_name: 'Last Name',
  qualify_glow_phone_number: 'Phone Number',
  qualify_glow_ssn: 'Last 4 Digits or Full SSN',
  qualify_glow_state: 'State',
  qualify_glow_street: 'Street',
  qualify_glow_zip: 'Zip',

  incentive_glow_carbon_credits: 'Carbon Credits Produce',
  incentive_glow_cash: 'Glow Cash',
  incentive_glow_tax_deduction: 'Tax Deduction',
  incentive_glow_federal_tax_credit: 'Federal Tax Credit',
};

/**
 * Will only contain one dependent for now.
 */
export const fieldToDependents: FieldToTrait<schema.CustomerFields> = {
  air_conditioner_swamp_cooler_use: ['air_conditioner_swamp_cooler_use_remove'],
  air_conditioner_working: ['air_conditioner_current_tons'],
  new_windows_replace: ['new_windows_replace_custom'],
  new_windows_sliding_glass: ['new_windows_sliding_glass_sqft'],
  roof_fascia_included: ['roof_fascia_included_square_footage'],
  roof_patio_included: ['roof_patio_how_many'],
  roof_plywood_replaced: ['roof_plywood_replaced_square'],
};

export const fieldToDependency: FieldToTrait<schema.CustomerField> = (() => {
  const res = {};
  // @ts-ignore
  for (const [dependency, [dependent]] of utils.entries(fieldToDependents)) {
    // @ts-ignore
    res[dependent] = dependency;
  }
  return res;
})();

/**
 * Sub-inputs are guaranteed to be 1 or 2 in length. Inputs should be rendered
 * in the order of this object's keys.
 */
export const efficiencyToInputs: FieldToTrait<schema.CustomerFields> = {
  attic_insulation: ['attic_insulation_type', 'attic_insulation_how_much'],
  duct_seal: [],
  roof: [
    'roof_square_footage',
    'roof_layover_or_tear',
    'roof_layers_how_many',
    'roof_tear_material',
    'roof_fascia_included',
    'roof_patio_included',
    'roof_plywood_replaced',
  ],
  new_windows: [
    'new_windows_sqft_each_window',
    'new_windows_color',
    'new_windows_grid',
    'new_windows_sliding_glass',
    'new_windows_replace',
  ],
  air_conditioner: [
    'air_conditioner_working',
    'air_conditioner_replace_ducts',
    'air_conditioner_swamp_cooler_use',
    'air_conditioner_new_tons',
    'air_conditioner_unit_type',
  ],
  battery: ['battery_type', 'battery_size'],
  main_panel_upgrade: ['main_panel_upgrade_needed_or_requested'],
  derate: [],
  mini_split: ['mini_split_tons'],
  smart_thermostat: ['smart_thermostat_how_many'],
  attic_fan: ['attic_fan_quantity'],
  pool_pump: [],
  duct_replacement: ['duct_replacement_qty'],
  duct_repair: ['duct_repair_quantity'],
  cash: ['cash_amount'],
  solar_tax: [],
  small_system: [],
  flat_roof_panels: ['flat_roof_panels_how_many'],
  panel_removal: ['panel_removal_how_many'],
  // sub_panel_upgrade: [],
  // glow: ['glow_price_per_watt', 'glow_power_output_mw'],
  custom_fields: ['custom_fields_name', 'custom_fields_description'],
  custom_two_fields: [
    'custom_two_fields_name',
    'custom_two_fields_description',
  ],
  custom_three_fields: [
    'custom_three_fields_name',
    'custom_three_fields_description',
  ],
  custom_four_fields: [
    'custom_four_fields_name',
    'custom_four_fields_description',
  ],
  custom_five_fields: [
    'custom_five_fields_name',
    'custom_five_fields_description',
  ],
  custom_six_fields: [
    'custom_six_fields_name',
    'custom_six_fields_description',
  ],
  custom_seven_fields: [
    'custom_seven_fields_name',
    'custom_seven_fields_description',
  ],
  custom_eight_fields: [
    'custom_eight_fields_name',
    'custom_eight_fields_description',
  ],
  custom_nine_fields: [
    'custom_nine_fields_name',
    'custom_nine_fields_description',
  ],
  custom_ten_fields: [
    'custom_ten_fields_name',
    'custom_ten_fields_description',
  ],
};

export const inputToEfficiency: FieldToTrait<schema.CustomerField> = (() => {
  const res: Writable<FieldToTrait<schema.CustomerField>> = {};

  for (const [efficiency, inputs] of utils.entries(efficiencyToInputs)) {
    for (const input of inputs!) res[input] = efficiency;
    // res[efficiency] = efficiency
  }

  // @ts-ignore Typescript whack here
  for (const [input, [subInput]] of utils.entries(fieldToDependents)) {
    // @ts-ignore Typescript whack here
    res[subInput] = res[input];
  }

  return res;
})();

// TODO: Do this via database
if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allFields = utils.keys(efficiencyToInputs);
    const nonGlowFields = allFields.filter(
      (field) => !schema.glowFields.includes(field),
    );
    for (const field of nonGlowFields) {
      delete efficiencyToInputs[field];
    }
  } else {
    for (const field of schema.mainOnlyFields) {
      delete efficiencyToInputs[field];
    }
  }
}

export const efficiencyKeys = utils.keys(efficiencyToInputs);
export const nonCustomEfficiencyKeys = efficiencyKeys.filter(
  (field) => !field.startsWith('custom_'),
);

export const getFieldLabel = (
  field: keyof Customer,
  customer?: schema.Customer,
): string => {
  if (field === 'homeRep' && env.CODENAME === 'glow') {
    return 'Sales Representative';
  }
  if (field === 'roof_layers_how_many') {
    const roofingType = customer?.roof_layover_or_tear;

    if (roofingType === schema.RoofWorkType.Layover) {
      return 'Layers to be installed';
    }
    if (roofingType === schema.RoofWorkType.TearOff) {
      return 'Current roof layers';
    }
    return '';
  }

  const maybeLabel = fieldToLabel[field];

  if (maybeLabel) return maybeLabel;
  if (field.endsWith('customer_cost')) return 'Customer Cost';
  if (field.endsWith('cost')) return 'Cost';
  if (field.endsWith('extra_notes')) return 'Extra Notes';
  if (field.toLowerCase().includes('notes')) return 'Notes';
  return field;
};

export const getMediaItemSrc = (mediaItem: MediaItem): string => {
  return mediaItem.status === 'uploaded'
    ? 'https://firebasestorage.googleapis.com/v0/b/' +
        env.commonEnv.FB_PROJECT_ID +
        '.appspot.com/o/' +
        mediaItem.order_id +
        '%2F' +
        // TODO: thumbnail
        mediaItem.name +
        `?alt=media&token=dummy`
    : 'file://' + mediaItem.path;
};

export const fieldToRadioOpts = (
  field: keyof Customer,
  customer?: Customer,
): utils.Opts => {
  if (field === 'roof_layers_how_many') {
    return customer?.roof_layover_or_tear === 'Layover'
      ? schema.layoverLayersOpts
      : schema.tearOffLayerOpts;
  }
  return [];
};

export const qualifyGlowPrimaryApplicantInputs: readonly schema.CustomerFields[] =
  [
    ['qualify_glow_first_name', 'qualify_glow_last_name'],
    ['qualify_glow_phone_number', 'qualify_glow_email'],
    ['qualify_glow_ssn', 'qualify_glow_birth_date'],
  ];

export const qualifyAddressInfoInputs: readonly schema.CustomerFields[] = [
  ['qualify_glow_street'],
  ['qualify_glow_city', 'qualify_glow_state', 'qualify_glow_zip'],
];

export const normalizeThumbnail = (
  customerID: string,
  thumbnail: string,
): string => {
  if (thumbnail === 'default_house.png') {
    return '';
  }
  if (thumbnail.length === 0) {
    return '';
  }
  if (thumbnail.startsWith('http')) {
    return thumbnail;
  }
  if (thumbnail.startsWith('data:image/')) {
    return thumbnail;
  }
  // Base64 jpg
  if (thumbnail.startsWith('/9j/')) {
    return 'data:image/jpeg;base64,' + thumbnail;
  }
  // Base64 png
  if (thumbnail.startsWith('iVBO')) {
    return 'data:image/png;base64,' + thumbnail;
  }

  return (
    'https://firebasestorage.googleapis.com/v0/b/' +
    env.commonEnv.FB_PROJECT_ID +
    '.appspot.com/o/' +
    customerID +
    '%2F' +
    thumbnail +
    '?alt=media&token=69e431fa-59ad-497a-b700-a3aebcc68fc6'
  );
};

export const mediaToLabel: Partial<Record<MediaKind, string>> = {
  owner_house: 'House View from Street',
  thermal: 'Thermal Camera',
  insulation: 'Attic',
  roof: 'Roof',
  windows: 'Windows',
  problem: 'Other Areas',
  hvac_system: 'HVAC',
  electrical: 'Electrical Panel',
  electricity: 'Electricity Bill',
  id: 'ID Picture',
  adders: 'Adjustment Screenshots',
  other_documents: 'Other Documents',
  solar_proposal: 'Solar Proposal',
};

if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allKinds = utils.keys(mediaToLabel);
    const nonGlowKinds = allKinds.filter(
      (field) => !schema.glowMedia.includes(field),
    );
    for (const field of nonGlowKinds) {
      delete mediaToLabel[field];
    }
  } else {
    for (const mediaKind of schema.mainOnlyMedia) {
      delete mediaToLabel[mediaKind];
    }
  }
}

export const mediaToLabelSiteSurvey: Partial<Record<MediaKind, string>> = {
  all_exterior_walls_site_survey: 'All exterior walls',
  rafters_site_survey: 'Rafters',
  electrical_panel_site_survey: 'Electrical Panel',
  all_planes_roof_site_survey: 'All planes roof',
  other_site_survey: 'Other',
};

export const mediaToLabelRoofClaims: Partial<Record<MediaKind, string>> = {
  rc_front_house: 'Front of the house',
  rc_address_verification: 'Address Verification',
  rc_3d_view: '3D View',
  rc_garage: 'Garage',
  rc_wires_home: 'Wires in to home',
  rc_siding: 'Siding',
  rc_layers: 'Shingle layers',
  rc_roof_inclination: 'Roof inclination angle',
  rc_roof_overview: 'Roof Overview',
  rc_drip_edge: 'Drip edge',
  rc_gutters: 'Gutters',
  rc_ice_shield: 'Ice Shield',
  rc_flashings: 'Flashings',
  rc_penetrations: 'Penetrations',
  rc_hail: 'Hail',
  rc_other_property_wind_damage: 'Property damages',
  rc_wind_damages: 'Wind damages',
  rc_length_shingle: 'Shingle length',
  rc_shingles_color: 'Shingles color',
};

export const mediaToLabelManufacturedHome: Partial<Record<MediaKind, string>> =
  {
    foundation_pic_mh: 'Foundation Pictures',
    hud_plate_mh: 'HUD Plate',
    install_certificate_mh: 'Install Certificate',
    deal_certificate_mh: 'Deal Certificate',
    property_title_mh: 'Property Title',
  };

export type Guideline = utils.r<{
  type: 'error' | 'info' | 'warning';
  text: string;
}>;

export const getFieldGuideline = (
  customer: schema.Customer,
  field: schema.CustomerField,
): Guideline | null => {
  if (field === 'air_conditioner_new_tons') {
    if (customer.attic_square_footage) {
      return {
        type: 'info',
        text: `Recommended tons = ${Math.round(
          utils.parseInt(customer.attic_square_footage) / 400,
        )}`,
      };
    } else {
      return {
        type: 'warning',
        text: 'Enter attic square footage to get recommended tons',
      };
    }
  }
  if (
    field === 'roof_layers_how_many' &&
    customer.roof_layover_or_tear === 'Layover' &&
    utils.parseInt(customer.roof_layers_how_many) > 2
  ) {
    return {
      type: 'warning',
      text: 'For more than 2 layers you need to do a tear off',
    };
  }
  if (
    field === 'battery_size' &&
    customer?.battery_type &&
    customer?.main_panel_upgrade_installation_company
  ) {
    if (customer?.main_panel_upgrade_installation_company === 'EcoManagement') {
      return {
        type: 'info',
        text: 'Battery size should be bigger than the system. Promo only applies to utility companies: SCE/PG&E/SDG&E',
      };
    }
    return {
      type: 'info',
      text: 'Battery size should be bigger than the system',
    };
  }
  return null;
};

export const disableInputs: FieldToTrait<boolean> = {
  cash_cost: true,
  createdAt: true,
  date: true,
  sort_key: true,
};

export const shouldDisableInput = (field: schema.CustomerField): boolean => {
  if (field === 'cash_cost' || field === 'cash_customer_cost') return true;

  if (field.endsWith('notes') && !field.endsWith('extra_notes')) {
    const efficiency = fieldToEfficiency(field);
    return !!efficiencyToCalculator[efficiency!];
  }
  return Boolean(disableInputs[field]);
};

export const shouldEnableInput = (field: schema.CustomerField): boolean =>
  !shouldDisableInput(field);

export const parseCashbackOptions = (options: string): utils.Opts => {
  try {
    const _options = JSON.parse(options) as unknown as utils.Opts;

    return _options;
  } catch (e) {
    return [];
  }
};

export const getTotalCashback = (options: utils.Opts, customer: Customer) => {
  let total = 0;

  if (options.length) {
    options.forEach((key) => {
      if (customer[key.value as keyof Customer]) {
        total += parseInt(customer[key.value as keyof Customer] as string);
      }
    });
  }
  return total;
};

export const isLayover = (value: string) => {
  if (value && value === 'Layover') {
    return true;
  }

  return false;
};

export const showIsNo = (state: boolean, field: keyof Customer) => {
  if (field === 'new_windows_replace') {
    return !state;
  }

  return state;
};

export const newWindowsCalifornia = (customer: Customer) => {
  if (customer.solarCompany !== 'CPS') {
    return true;
  }
  if (customer.solarCompany === 'CPS') {
    return true;
  }

  return false;
};

export const newWindowsSlidingGlassYes = (customer: Customer) => {
  if (
    customer.new_windows_sliding_glass === 'yes' &&
    customer.new_windows_sliding_glass_sqft
  ) {
    return true;
  }

  return false;
};

export const newWindowsSlidingGlassNo = (customer: Customer) => {
  if (customer.new_windows_sliding_glass === 'no') {
    return true;
  }
  return false;
};

export const newWindowsReplaceYes = (customer: Customer) => {
  if (customer.new_windows_replace === 'yes') {
    return true;
  }

  return false;
};

export const newWindowsReplaceNo = (customer: Customer) => {
  if (
    customer.new_windows_replace === 'no' &&
    customer.new_windows_replace_custom
  ) {
    return true;
  }
  return false;
};

export const verifyFillNewWindows = (customer: Customer) => {
  if (
    newWindowsCalifornia(customer) &&
    (newWindowsSlidingGlassYes(customer) ||
      newWindowsSlidingGlassNo(customer)) &&
    (newWindowsReplaceYes(customer) || newWindowsReplaceNo(customer))
  ) {
    return true;
  }
  return false;
};

export const isRoofCaliforniaCityFilledIn = (customer: Customer): boolean => {
  if (env.getCompanyState(customer.solarCompany) !== 'california') {
    return true;
  }
  if (
    env.getCompanyState(customer.solarCompany) === 'california' &&
    customer.roof_california_city
  ) {
    return true;
  }

  return false;
};

export const roofFasciaIncludedYes = (customer: Customer) => {
  if (
    customer.roof_fascia_included === 'yes' &&
    customer.roof_fascia_included_square_footage
  ) {
    return true;
  }
  return false;
};

export const roofFasciaIncludedNo = (customer: Customer) => {
  if (customer.roof_fascia_included === 'no') {
    return true;
  }

  return false;
};

export const roofPatioIncludedYes = (customer: Customer) => {
  if (customer.roof_patio_included === 'yes' && customer.roof_patio_how_many) {
    return true;
  }

  return false;
};

export const roofPatioIncludedNo = (customer: Customer) => {
  if (customer.roof_patio_included === 'no') {
    return true;
  }

  return false;
};

export const roofShingleLayers = (customer: Customer) => {
  if (customer.roof_layers_how_many) {
    return true;
  }

  return false;
};

export const roofPlywoodReplacedYes = (customer: Customer) => {
  if (customer.roof_plywood_replaced === 'yes') {
    return true;
  }

  return false;
};

export const roofPlywoodReplacedNo = (customer: Customer) => {
  if (customer.roof_plywood_replaced === 'no') {
    return true;
  }

  return false;
};

export const roofLayover = (customer: Customer) => {
  if (
    customer.roof_layover_or_tear === 'Layover' &&
    (roofFasciaIncludedNo(customer) || roofFasciaIncludedYes(customer)) &&
    (roofPatioIncludedYes(customer) || roofPatioIncludedNo(customer)) &&
    customer.roof_square_footage
  ) {
    return true;
  }

  return false;
};

export const roofTearOff = (customer: Customer) => {
  if (
    customer.roof_layover_or_tear === 'Tear off' &&
    (roofFasciaIncludedNo(customer) || roofFasciaIncludedYes(customer)) &&
    (roofPatioIncludedYes(customer) || roofPatioIncludedNo(customer)) &&
    (roofPlywoodReplacedNo(customer) || roofPlywoodReplacedYes(customer)) &&
    roofShingleLayers(customer) &&
    customer.roof_square_footage
  ) {
    return true;
  }

  return false;
};

export const verifyFillRoof = (customer: Customer) => {
  if (
    isRoofCaliforniaCityFilledIn(customer) &&
    (roofLayover(customer) || roofTearOff(customer)) &&
    customer.roof_cost &&
    customer.roof_customer_cost
  ) {
    return true;
  }

  return false;
};

export const verifyFillMiniSplit = (customer: Customer) => {
  if (customer.mini_split_tons) {
    return true;
  }

  return false;
};

export const verifyFillAtticInsulation = (customer: Customer) => {
  if (customer.attic_insulation_type && customer.attic_insulation_how_much) {
    return true;
  }

  return false;
};

export const fieldToEfficiency = (
  field: schema.CustomerField,
): schema.CustomerField | undefined => {
  if (field.endsWith('_cost')) {
    return field.replace('_cost', '') as schema.CustomerField;
  }
  if (field.endsWith('_customer_cost')) {
    return field.replace('_customer_cost', '') as schema.CustomerField;
  }
  if (field.endsWith('_notes')) {
    return field.replace('_notes', '') as schema.CustomerField;
  }
  if (field.endsWith('_extra_notes')) {
    return field.replace('_extra_notes', '') as schema.CustomerField;
  }
  const [efficiencyField] =
    utils
      .entries(efficiencyToInputs)
      .find(([_, subFields]) =>
        [subFields].flat(1).some((v) => v?.includes(field)),
      ) || [];

  return efficiencyField;
};

export const verifyEmptyFields = (customer: Customer) => {
  let emptyInputs: string[] = [];
  let emptyKeys: string[] = [];
  efficiencyKeys.forEach((key) => {
    if (key === 'attic_insulation') {
      if (
        customer.attic_insulation === 'yes' &&
        !verifyFillAtticInsulation(customer)
      ) {
        emptyInputs.push(key);
      }
    }

    if (key === 'new_windows') {
      if (customer.new_windows === 'yes' && !verifyFillNewWindows(customer)) {
        emptyInputs.push(key);
      }
    }

    // if (key === 'air_conditioner') {
    //   if (
    //     customer.air_conditioner === 'yes' &&
    //     !verifyFillAirConditioner(customer)
    //   ) {
    //     emptyInputs.push(key)
    //   }
    // }

    if (key === 'roof') {
      if (customer.roof === 'yes' && !verifyFillRoof(customer)) {
        emptyInputs.push(key);
      }
    }

    if (key === 'mini_split') {
      if (customer.mini_split === 'yes' && !verifyFillMiniSplit(customer)) {
        emptyInputs.push(key);
      }
    }

    if (
      key !== 'air_conditioner' &&
      key !== 'attic_insulation' &&
      key !== 'mini_split' &&
      key !== 'new_windows' &&
      key !== 'roof'
    ) {
      efficiencyToInputs[key as keyof Customer]?.forEach((value: any) => {
        if (Array.isArray(value)) {
          value.forEach((_field: keyof Customer) => {
            if (
              customer[key as keyof Customer] === 'yes' &&
              !_field.endsWith('notes') &&
              !customer[_field]
            ) {
              emptyInputs.push(key);
            }
          });
        } else {
          if (
            customer[key as keyof Customer] === 'yes' &&
            !(value as keyof Customer).endsWith('notes') &&
            !customer[value as keyof Customer]
          ) {
            emptyInputs.push(key);
          }
        }
      });

      // alert(JSON.stringify(otherKeys))
    }
  });

  if (emptyInputs.length && emptyInputs[0]) {
    return {
      empty: true,
      targetScroll: emptyInputs[0],
      emptyKeys,
    };
  }

  return {
    empty: false,
    targetScroll: '',
    emptyKeys: [],
  };
};

export const isSubField = (field: keyof Customer) => {
  let isSubfield = false;
  efficiencyKeys.forEach((key) => {
    if (field?.startsWith(key) && field !== key && !field.endsWith('notes')) {
      isSubfield = true;
    }
  });

  return isSubfield;
};

export const fieldCanBeEmpty = (field: schema.CustomerField) => {
  if (isSubField(field)) {
    return true;
  }

  // TODO: Later to be handled by the new schema validators
  return ['customerPhoneAlt', 'ssn'].includes(field);
};

export const fieldShouldBeFilled = (field: schema.CustomerField): boolean =>
  !fieldCanBeEmpty(field);

export const isLargeEfficiency = (field: keyof Customer) => {
  if (
    field === 'air_conditioner' ||
    field === 'attic_insulation' ||
    field === 'new_windows' ||
    field === 'roof'
  ) {
    return true;
  }

  return false;
};

//#region MPUCalculator

const MAX_PANELS = 42;

interface Inverter {
  readonly capacity: number;
  readonly name: string;
  readonly numOfPanels: readonly [lowerBound: number, upperBound: number];
}

const availableInverters: readonly Inverter[] = [
  {
    capacity: 1.21,
    name: 'Enphase IQ8+ Microinverters.',
    numOfPanels: [1, 1],
  },
  {
    capacity: 2.42,
    name: '2 x Enphase IQ8+ Microinverters.',
    numOfPanels: [2, 2],
  },
  {
    capacity: 3.63,
    name: '3 x Enphase IQ8+ Microinverters.',
    numOfPanels: [3, 3],
  },
  {
    capacity: 4.84,
    name: '4 x Enphase IQ8+ Microinverters.',
    numOfPanels: [4, 4],
  },
  {
    capacity: 6.05,
    name: '5 x Enphase IQ8+ Microinverters.',
    numOfPanels: [5, 5],
  },
  {
    capacity: 7.26,
    name: '6 x Enphase IQ8+ Microinverters.',
    numOfPanels: [6, 6],
  },
  {
    capacity: 8.47,
    name: '7 x Enphase IQ8+ Microinverters.',
    numOfPanels: [7, 7],
  },
  {
    capacity: 16,
    name: 'Solaredge Inv-3800',
    numOfPanels: [8, 12],
  },
  {
    capacity: 21,
    name: 'Solaredge Inv-5000',
    numOfPanels: [13, 16],
  },
  {
    capacity: 25,
    name: 'Solaredge Inv-6000',
    numOfPanels: [17, 20],
  },
  {
    capacity: 32,
    name: 'Solaredge Inv-7600',
    numOfPanels: [21, 25],
  },
  {
    capacity: 40,
    name: 'Solaredge Inv-10000',
    numOfPanels: [26, 33],
  },
  {
    capacity: 47.5,
    name: 'Solaredge Inv-11400',
    numOfPanels: [34, 38],
  },
  {
    capacity: 50,
    name: 'Solaredge Inv-6000 + Solaredge Inv-6000',
    numOfPanels: [39, 40],
  },
  {
    capacity: 53,
    name: 'Solaredge Inv-7600 + Solaredge Inv 5000',
    numOfPanels: [41, 42],
  },
];

const reverseInverters = availableInverters.slice().reverse();

interface MPU {
  readonly capacity: number;
  readonly name: string;
}

const availableMPUs: readonly MPU[] = [
  {
    capacity: 225,
    name: '225amp MPU',
  },
];

const [maxCapInverter] = (availableMPUs as [Inverter])
  .slice()
  .sort((a, b) => a.capacity - b.capacity);

export const { capacity: MAX_MPU_RATING } = maxCapInverter!;

export interface MPUCalculatorResult {
  readonly maxPanelsAsIs: number | null;
  readonly maxPanelsWithDerate: number | null;
  readonly inverterAsIs: Inverter | null;
  readonly inverterWithDerate: Inverter | null;
}

const VALID_DERATES = [100, 175, 200];

export const mpuCalculator = (
  busRating: number,
  mainBreakerRating: number,
): MPUCalculatorResult => {
  const res: Writable<MPUCalculatorResult> = {
    maxPanelsAsIs: null,
    maxPanelsWithDerate: null,
    inverterAsIs: null,
    inverterWithDerate: null,
  };

  // Information!G11 = numOfPanels

  // Information!F15 = chosenInverter according to numOfPanels
  // Information!K15 = chosenInverter.capacity * 1.25

  for (const inverter of reverseInverters) {
    const {
      capacity,
      numOfPanels: [, upperBound],
    } = inverter;
    /**
     * IF (B2*1.2)>B3+(Information!C48*1.25):
     *   CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A48),
     * else:
     *   IF (B2*1.2)>B3+(Information!C46*1.25):
     *      CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A46)
     *   else:
     *     IF (B2*1.2)>B3+(Information!C44*1.25):
     *       CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A44)
     *     else:
     *       IF (B2*1.2)>B3+(Information!C39*1.25):
     *         CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A39)
     *       else:
     *         IF (B2*1.2)>B3+(Information!C31*1.25):
     *           CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A31)
     *         else:
     *           IF (B2*1.2)>B3+(Information!C26*1.25):
     *             CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A26)
     *           else:
     *             IF (B2*1.2)>B3+(Information!C21*1.25):
     *               CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A21)
     *             else:
     *               IF (B2*1.2)>B3+(Information!C18*1.25):
     *                 CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A18),"No possible to install Solar Panels without the need of Derate or MPU"))))))))
     */
    if (busRating * 1.2 > mainBreakerRating + capacity * 1.25) {
      res.maxPanelsAsIs = upperBound;
      res.inverterAsIs = inverter;
      break;
    }
  }

  if (!res.maxPanelsAsIs || !res.inverterAsIs) {
    return res;
  }

  for (const numOfPanels of range(
    MAX_PANELS - res.maxPanelsAsIs,
    res.maxPanelsAsIs + 1,
  )) {
    /**
     * IF (B2*1.2)>B3+Information!K15:
     *   "Derate is not need"
     * else IF (B2*1.2)>B3-25+Information!K15:
     *   IF B3-25=200:
     *     CONCATENATE("Derate size should be: ",B3-25)
     *   else IF B3-25=175:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else IF B3-25=100:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else:
     *    "Derate is not possible"
     * else:
     *   "Derate is not possible"
     */
    const inverterViaPanels = availableInverters.find(
      ({ numOfPanels: [lowerBound, upperBound] }) =>
        isBetween({
          lowerBound,
          upperBound,
          value: numOfPanels,
        }),
    );
    if (!inverterViaPanels) {
      return res;
    }
    if (
      busRating * 1.2 >
      mainBreakerRating + inverterViaPanels.capacity * 1.25
    ) {
      // Derate not needed
      if (numOfPanels !== res.maxPanelsAsIs) {
        console.error('Assertion failed: numOfPanels === res.maxPanelsAsIs');
      }
      continue;
    }

    if (
      busRating * 1.2 >
      mainBreakerRating - 25 + inverterViaPanels.capacity * 1.25
    ) {
      /**
       * IF B3-25=200:
       *     CONCATENATE("Derate size should be: ",B3-25)
       *   else IF B3-25=175:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else IF B3-25=100:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else:
       *    "Derate is not possible"
       */
      if (VALID_DERATES.includes(mainBreakerRating - 25)) {
        res.maxPanelsWithDerate = numOfPanels;
        const inverterViaPanels = availableInverters.find(
          ({ numOfPanels: [lowerBound, upperBound] }) =>
            isBetween({
              lowerBound,
              upperBound,
              value: numOfPanels,
            }),
        );
        if (!inverterViaPanels) {
          console.error(
            `Found panels with derate but not Inverter for those panels?`,
          );
          continue;
        }
        res.inverterWithDerate = inverterViaPanels;
      }
    }
  }

  return res;
};

// TODO: Move to react utils
export function useMpuCalculator() {
  const [mpuBusRating, setMpuBusRating] = React.useState('');
  const [mpuMainBreakerRating, setMPUMainBreakerRating] = React.useState('');

  const mpuCalculatorResult = React.useMemo((): MPUCalculatorResult | null => {
    if ([mpuBusRating, mpuBusRating].every(stringIsNumber)) {
      // const parsedNumOfSolarPanels = parseInt(numOfSolarPanels, 10)
      const parsedMPUBusRating = parseInt(mpuBusRating, 10);
      const parsedMPUMainBreakerRating = parseInt(mpuMainBreakerRating, 10);

      return mpuCalculator(parsedMPUBusRating, parsedMPUMainBreakerRating);
    }

    return null;
  }, [mpuBusRating, mpuMainBreakerRating]);

  return {
    mpuBusRating,
    mpuCalculatorResult,
    mpuMainBreakerRating,
    setMpuBusRating,
    setMPUMainBreakerRating,
  };
}

//#endregion MPUCalculator

export const validateInputMaxLength = (
  field: keyof Customer,
): number | undefined => {
  if (field === 'yearHomeBuilt') {
    return 4;
  }
  return;
};

export const validateNumberOfPhotosAllowed = (ofWhat: MediaKind): number => {
  if (ofWhat === 'owner_house') {
    return 1;
  }
  return 40;
};

type GoogleLibrary =
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization';

export const googleLibraries: GoogleLibrary[] = ['places'];

export const invoiceEfficiencyOpts: utils.DeepReadonly<
  Record<string, string[] | Record<string, string[]>>
> = {
  artificial_turf: [
    'Evergreen Collection Waterproof Solid Grass 1x1 Indoor/Outdoor Artificial Grass Tile, 1 ft. x 1 ft',
  ],
  attic_insulation: ['Attic Insulation'],
  duct_seal: ['Duct Seal'],
  efficient_boiler: [
    'Rinnai M120SN Condensing Gas Boiler, Natural Gas: Materials+Labor',
    'Aspen Firetube 126,000 BTU Combi High Efficiency Wall Hung Condensing Gas Fired Boiler: Materials+Labor',
  ],
  heat_pump: [
    'Goodman 5 Ton 15.5 SEER Heat Pump GSZC180601, Coil CHPF4860D6, 120,000 BTU 97% AFUE Horizontal Gas Furnace GMVM971205DN',
    '56,000 BTU 4.6 Ton 17.5 SEER Ducted Central Split Air Conditioner Heat Pump System',
  ],
  hvac: [
    '2 T HVAC System Installation: Miscellaneous + Labor',
    '2.5 T HVAC System Installation: Miscellaneous + Labor',
    '3 T HVAC System Installation: Miscellaneous + Labor',
    '3.5 T HVAC System Installation: Miscellaneous + Labor',
    '4 T HVAC System Installation: Miscellaneous + Labor',
    '5 T HVAC System Installation: Miscellaneous + Labor',
  ],
  mini_split: [
    '1T HVAC mini split: Miscellaneous+Labor',
    '2T HVAC mini split: Miscellaneous+Labor',
  ],
  radiant_floor: ['WarmlyYours Tempzone Electric Radiant Floor  '],
  roof: {
    roof_layover: [
      'Install 30-year Shingles according to manufactures specifications\nSeal penetrations',
    ],
    shingles_tear_off: [
      'Tear off the existing roof.\nInstall one-layer of synthetic underlayment.\nInstall new 2x2 drep edge metal.\nInstall 30-year Shingles according to manufactures specifications.\nSeal penetrations.\n',
    ],
  },
  thermostat: [
    'Ecobee3 Lite Programmable Smart Thermostat - Energy Star Certified',
    'Nest Learning Thermostat - Smart Wi-Fi Thermostat Stainless Steel',
  ],
  whole_house_fans: [
    'Whole House Fan/Ventilation',
    'QA-Deluxe 6500 Includes Plug & Play Wireless On/Off Remote Control | Energy Efficient Whole House Fan | R-5 Insulated Damper',
  ],
  windows: ['Sliding Windows', 'Sliding Windows: Low-E Energy Efficient.'],
  windows_film: [
    'KESPEN Window Privacy Film One Way Daytime Privacy Static Cling Sun Blocking Anti UV Reflective Window Tint for Home and Office, Black-Silver',
  ],
};

export const companyToLabel: utils.ReadonlyRecord<string> = (() => {
  const res: Record<string, string> = {};

  for (const [subCompanyLabel, subCompanyValue] of utils.zip(
    env.COMPANY_LABELS,
    env.COMPANY_VALUES,
  ) as readonly [string, string][]) {
    res[subCompanyValue] = subCompanyLabel;
  }

  return res;
})();

//#region Glow
const nper = 10; // Commitment (years)
const pmt = 0; // Payment amount per period

const calcAvg = (rate: number, value: number): number => {
  const pv =
    (value * (1 - Math.pow(1 + rate, -nper))) / rate -
    (pmt * (1 - Math.pow(1 + rate, -nper))) / rate;
  return Number(pv.toFixed(2));
};

const cashFlowDiscount = 0.11; // Cashflow discount
const _powerOutputMW = 0.0156;

export const calculateProtocolFee = (
  pricePerWatt: number,
  hoursOfSunPerDay: number,
  escalator: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  const firstYearElectricityOldPrice = Number(
    (pricePerWatt * hoursOfSunPerDay * powerOutputMW * 365.25).toFixed(2),
  );

  const lifetimeOldElectricityValue = calcAvg(
    -escalator,
    firstYearElectricityOldPrice,
  );

  const protocolCashRequirements = calcAvg(
    cashFlowDiscount,
    lifetimeOldElectricityValue / nper,
  );

  return protocolCashRequirements;
};

export const calculateInstallationCost = (
  installationCostPerWatt: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  return installationCostPerWatt * powerOutputMW * 1000000;
};
//#endregion Glow

/**
 * Windows global color selectors cut-off.
 */
export const Jun4_2024 = 1717519937085;
export const shouldHideInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => {
  const dependency = fieldToDependency[field];
  if (dependency) {
    /* Special case */
    if (field === 'new_windows_replace_custom') {
      return customer.new_windows_replace !== 'no';
    }
    return customer[dependency] !== 'yes';
  }
  if (field === 'air_conditioner_current_tons') {
    return customer.air_conditioner_working !== 'yes';
  }
  if (field === 'air_conditioner_swamp_cooler_use_remove') {
    return customer.air_conditioner_swamp_cooler_use !== 'yes';
  }
  if (field === 'air_conditioner_replace_ducts') {
    return customer.air_conditioner_working === 'no';
  }
  if (field === 'battery') {
    if (!customer.solarCompany) return true;
    return env.getCompanyState(customer.solarCompany) !== 'california';
  }
  if (field === 'battery_size') {
    return customer.battery_type.toLowerCase() === '';
  }
  if (field === 'glow') {
    const shouldDisplay = env.IS_MAIN || env.CODENAME === 'glow';
    const shouldHide = !shouldDisplay;
    return shouldHide;
  }
  if (field === 'main_panel_upgrade_installation_company') {
    return env.getCompanyState(customer.solarCompany) !== 'california';
  }
  // Hide for older customers
  if (field === 'new_windows_color') {
    return utils.normalizeTimestampToMs(customer.createdAt) < Jun4_2024;
  }
  if (field === 'new_windows_sliding_glass_sqft') {
    return customer.new_windows_sliding_glass !== 'yes';
  }
  if (field === 'roof_fascia_included_square_footage') {
    return customer.roof_fascia_included !== 'yes';
  }
  if (field === 'roof_patio_how_many') {
    return customer.roof_patio_included !== 'yes';
  }
  if (field === 'roof_plywood_replaced') {
    return customer.roof_layover_or_tear !== schema.RoofWorkType.TearOff;
  }
  if (field === 'roof_plywood_replaced_square') {
    return customer.roof_plywood_replaced !== 'yes';
  }
  if (field === 'roof_tear_material') {
    return customer.roof_layover_or_tear !== schema.RoofWorkType.TearOff;
  }
  return false;
};

export const shouldDisplayInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => !shouldHideInput(field, customer);

export const fieldToGrow: FieldToTrait<boolean> = {
  customerAddress: true,
  new_windows_color: true,
  new_windows_replace: true,
  new_windows_replace_custom: true,
  new_windows_grid: true,
  new_windows_sliding_glass: true,
  new_windows_sqft_each_window: true,
};

// TODO: Move to settings
export const roofCompanies: readonly string[] = [
  'APS_RC',
  'CPS_RC',
  'NPS_RC',
  'affordable',
];

export const readableDateFormat = 'MMM Do YYYY';
export const readableDateFormatWTime = 'MMM Do YYYY HH:MM';

type Calculator = (
  customer: utils.DeepReadonly<schema.Customer>,
  prices: utils.DeepReadonly<schema.SaleTag>,
  state: 'california' | 'nevada',
) => [number, string];

export const efficiencyToCalculator: FieldToTrait<Calculator> = {
  air_conditioner(
    {
      air_conditioner_current_tons,
      air_conditioner_new_tons,
      air_conditioner_replace_ducts,
      air_conditioner_swamp_cooler_use,
      air_conditioner_swamp_cooler_use_remove,
      air_conditioner_unit_type,
      air_conditioner_working,
    },
    prices,
    state,
  ) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const priceList =
      state === 'california'
        ? prices.air_conditioner.california.pricesByCity[0]!.list
        : prices.air_conditioner.nevada.list;

    if (air_conditioner_swamp_cooler_use === 'yes') {
      if (air_conditioner_swamp_cooler_use_remove === 'yes') {
        notesLines.push('Swamp cooler will be removed');

        finalCost += priceList.find(
          (p) => p.label === 'Swamp Cooler Removal',
        )!.value;
      } else {
        notesLines.push('Swamp cooler is present');
      }
    }

    if (
      air_conditioner_unit_type &&
      air_conditioner_new_tons &&
      air_conditioner_working
    ) {
      const installationType =
        air_conditioner_working === 'yes' &&
        // Replacing the ducts brings up the price up to  new installation
        air_conditioner_replace_ducts === 'no'
          ? 'replacement'
          : 'new installation';

      let [splitOrRoof, gasOrElectric] = air_conditioner_unit_type
        .toLowerCase()
        .split('_');

      //#region notes
      if (air_conditioner_working === 'yes' && air_conditioner_current_tons) {
        notesLines.push(
          `The current unit of ${air_conditioner_current_tons}T will be replaced.`,
        );
      }
      notesLines.push(
        `A new ${splitOrRoof} ${gasOrElectric} unit of ${air_conditioner_new_tons}T will be installed`,
      );
      if (air_conditioner_replace_ducts === 'yes') {
        notesLines.push('All ducts will be replaced');
      }
      //#endregion notes

      if (splitOrRoof === 'roof') {
        gasOrElectric = 'roof';
      }

      const price: number = priceList.find(({ label: _label }) => {
        const label = _label.toLowerCase();

        return (
          label.includes(splitOrRoof!) &&
          label.includes(gasOrElectric!) &&
          label.includes(air_conditioner_new_tons + 't') &&
          label.includes(installationType)
        );
      })!.value;

      finalCost += price;
    }

    return [finalCost, notesLines.join('\n')];
  },
  attic_fan({ attic_fan_quantity }, prices, state) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const price = (() => {
      if (state === 'california') {
        return prices.attic_fan.california.pricesByCity[0]!.list[0]!.value;
      }
      return prices.attic_fan.nevada.list[0]!.value;
    })();

    if (attic_fan_quantity) {
      finalCost += price * utils.parseInteger(attic_fan_quantity);
      notesLines.push(`${attic_fan_quantity} attic fans will be installed`);
    }

    return [finalCost, notesLines.join('\n')];
  },
  attic_insulation(
    { attic_insulation_how_much, attic_insulation_type },
    prices,
    state,
  ) {
    if (!attic_insulation_how_much || !attic_insulation_type) {
      return [0, ''];
    }
    let finalCost = 0;
    const notesLines: string[] = [];
    const priceList =
      state === 'nevada'
        ? prices.attic_insulation.nevada.list
        : prices.attic_insulation.california.pricesByCity[0]!.list;
    const price = priceList[0]!.value;
    finalCost = price * Number(attic_insulation_how_much);
    //#region notes
    if (attic_insulation_how_much) {
      notesLines.push(`Total sq of insulation: ${attic_insulation_how_much}`);
    }
    if (attic_insulation_type) {
      notesLines.push(`Type of insulation: ${attic_insulation_type}`);
    }
    //#endregion notes
    return [finalCost, notesLines.join('\n')];
  },
  battery(
    { main_panel_upgrade_installation_company, battery_size, battery_type },
    prices,
    state,
  ) {
    let finalCost = 0;
    const notesLines: string[] = [];

    if (
      main_panel_upgrade_installation_company &&
      battery_size &&
      battery_type
    ) {
      const priceList = (() => {
        if (state === 'california') {
          return prices.battery.california.pricesByCity[0]!.list;
        }
        return prices.battery.nevada.list;
      })();

      const batteryKey = `Battery ${battery_type}-${main_panel_upgrade_installation_company}-${battery_size}`;

      const price = priceList.find((x) => x.label === batteryKey)!.value || 0;

      finalCost += price;

      notesLines.push(`Installer: ${main_panel_upgrade_installation_company}`);
      notesLines.push(`Battery type: ${battery_type}`);
      notesLines.push(`Battery size: ${battery_size}`);
    }

    return [finalCost, notesLines.join('\n')];
  },
  derate({ main_panel_upgrade_installation_company }, prices, state) {
    if (!main_panel_upgrade_installation_company) {
      return [0, ''];
    }

    const price = (() => {
      if (state === 'california') {
        return prices.derate.california.pricesByCity[0]!.list.find(
          (p) =>
            p.label === `Derate (${main_panel_upgrade_installation_company})`,
        )!.value;
      }
      return prices.derate.nevada.list.find(
        (p) =>
          p.label === `Derate (${main_panel_upgrade_installation_company})`,
      )!.value;
    })();

    return [
      price,
      `A derate will be performed\nInstaller: ${main_panel_upgrade_installation_company}`,
    ];
  },
  duct_seal(_, prices, state) {
    const price = (() => {
      if (state === 'california') {
        return prices.duct_seal.california.pricesByCity[0]!.list[0]!.value;
      }
      return prices.duct_seal.nevada.list[0]!.value;
    })();

    return [price, 'Duct seal will be applied.'];
  },
  flat_roof_panels({ flat_roof_panels_how_many }, prices, state) {
    let finalCost = 0;
    const notesLines: string[] = [];
    const price = (() => {
      if (state === 'california') {
        return prices.flat_roof_panels.california.pricesByCity[0]!.list[0]!
          .value;
      }
      return prices.flat_roof_panels.nevada.list[0]!.value;
    })();

    if (flat_roof_panels_how_many) {
      finalCost += price * utils.parseInteger(flat_roof_panels_how_many);
    }

    return [finalCost, notesLines.join('\n')];
  },
  main_panel_upgrade(
    {
      main_panel_upgrade_installation_company,
      main_panel_upgrade_needed_or_requested,
    },
    prices,
    state,
  ) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const priceList = (() => {
      if (state === 'california') {
        return prices.main_panel_upgrade.california.pricesByCity[0]!.list;
      }
      return prices.main_panel_upgrade.nevada.list;
    })();

    const mpuKey = `Main Panel Upgrade-${main_panel_upgrade_installation_company}`;

    const price = priceList.find((x) => x.label === mpuKey)!.value;

    if (main_panel_upgrade_installation_company) {
      if (main_panel_upgrade_needed_or_requested === 'needed') {
        notesLines.push('An upgrade is needed');
        finalCost += price;
      }
      if (main_panel_upgrade_needed_or_requested === 'requested') {
        notesLines.push('An upgrade was requested by the customer');
        finalCost += price;
      }
      if (main_panel_upgrade_needed_or_requested === 'relocation') {
        notesLines.push(
          "Relocation requested by customer, a site survey is needed before it's performed",
        );
      }
      notesLines.push(`Installer: ${main_panel_upgrade_installation_company}`);
    }

    return [finalCost, notesLines.join('\n')];
  },
  mini_split({ mini_split_tons }, prices, state) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const [miniSplitTon1Price, miniSplitTon2Price] = (() => {
      const priceList =
        state === 'california'
          ? prices.mini_split.california.pricesByCity[0]!.list
          : prices.mini_split.nevada.list;

      return [
        priceList.find((cp) => cp.label === 'Mini Split System 1Ton')!.value,
        priceList.find((cp) => cp.label === 'Mini Split System 2Ton')!.value,
      ];
    })();

    const miniSplits = JSON.parse(
      mini_split_tons || '[]',
    ) as schema.MiniSplit[];

    for (const split of miniSplits.filter((ms) => ms.qty && ms.tons)) {
      if (
        // @ts-ignore
        split.tons === '1' ||
        // @ts-ignore
        split.tons === 'mini_split_1t' ||
        split.tons === 1
      ) {
        finalCost += utils.parseInteger(split.qty) * miniSplitTon1Price;
      }
      if (
        // @ts-ignore
        split.tons === '2' ||
        // @ts-ignore
        split.tons === 'mini_split_2t' ||
        split.tons === 2
      ) {
        finalCost += utils.parseInteger(split.qty) * miniSplitTon2Price;
      }
    }

    return [finalCost, notesLines.join('\n')];
  },
  new_windows(
    {
      createdAt,
      new_windows_color,
      new_windows_grid,
      new_windows_replace,
      new_windows_replace_custom,
      new_windows_sliding_glass,
      new_windows_sliding_glass_sqft,
      new_windows_sqft_each_window,
    },
    prices,
    state,
  ) {
    let finalCost = 0;
    const notesLines: string[] = [];
    const pricePerSqft = (() => {
      if (state === 'california') {
        return prices.new_windows.california.pricesByCity[0]!.list.find(
          (cp) => cp.label === 'Per Sqft',
        )!.value;
      }
      return prices.new_windows.nevada.list.find(
        (cp) => cp.label === 'Per Sqft',
      )!.value;
    })();
    // @ts-ignore Special Case
    const priceColors = prices.new_windows.priceColors;

    const windowGroups: readonly schema.WindowGroup[] = JSON.parse(
      new_windows_sqft_each_window || '[]',
    );
    if (!utils.isArray(windowGroups)) {
      throw new TypeError('new_windows_sqft_each_window not an array');
    }
    for (const obj of windowGroups) {
      if (!schema.isWindowGroup(obj)) {
        throw new TypeError(`Object inside sqftWindow[] not a WindowGroup`);
      }
    }
    const filledWindowGroups = windowGroups.filter(
      (sw) => !!sw.size && !!sw.window,
    );
    let totalSqft = 0;
    let totalWindows = 0;
    let amtColor = 0;
    for (const windowGroup of filledWindowGroups) {
      const qty = utils.parseInteger(windowGroup.window);
      const size = utils.parseInteger(windowGroup.size);

      totalSqft += qty * size;
      totalWindows += qty;

      if (createdAt > Jun4_2024) {
        // Newer customer, has a global color selector
        const colorPrice = priceColors[new_windows_color];
        if (colorPrice) amtColor += colorPrice * qty;
      } else {
        // Older customer, has an individual color selector for each window
        // group
        const colorPrice = priceColors[windowGroup.color];
        if (colorPrice) amtColor += colorPrice * qty;
      }
    }
    finalCost += utils.parseInteger(pricePerSqft) * totalSqft + amtColor;
    notesLines.push(`Total number windows ${totalWindows}`);
    notesLines.push(`Total measure of windows in sqft ${totalSqft}`);
    notesLines.push('--Windows Details--');
    notesLines.push(
      ...filledWindowGroups.map(
        (g) =>
          `Number of windows: ${g.window} - Size ${g.size} sqft${
            createdAt < Jun4_2024 && g.color
              ? ` - Color ${g.color.toLowerCase()}`
              : ''
          }`,
      ),
    );
    if (new_windows_color) {
      notesLines.push(`All windows color ${new_windows_color.toLowerCase()}`);
    }

    if (new_windows_replace === 'yes') {
      notesLines.push('All the windows of the house will be replaced');
    }
    if (new_windows_replace === 'no' && new_windows_replace_custom)
      notesLines.push(
        `We will just replace windows in the ${new_windows_replace_custom} of the house`,
      );

    if (new_windows_grid === 'yes') notesLines.push('All windows gridded');

    if (new_windows_sliding_glass === 'yes') {
      const slidingDoorPrices = (() => {
        if (state === 'california') {
          return {
            'Sliding Doors (5 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (5 ft)',
              )!.value,
            'Sliding Doors (6 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (6 ft)',
              )!.value,
            'Sliding Doors (8 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (8 ft)',
              )!.value,
          };
        }
        return {
          'Sliding Doors (5 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (5 ft)',
          )!.value,
          'Sliding Doors (6 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (6 ft)',
          )!.value,
          'Sliding Doors (8 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (8 ft)',
          )!.value,
        };
      })();
      const slidingDoors = JSON.parse(
        new_windows_sliding_glass_sqft || '[]',
      ) as schema.SlidingGlassDoorGroup[];
      const filledSlidingDoors = slidingDoors.filter(
        (sd) => !!sd.size && !!sd.total,
      );
      for (const slidingDoor of filledSlidingDoors) {
        const price =
          slidingDoorPrices[slidingDoor.size as keyof typeof slidingDoorPrices];
        finalCost += price * utils.parseInteger(slidingDoor.total);
      }

      if (filledSlidingDoors.length) {
        const totalSliding = filledSlidingDoors.reduce(
          (count, g) => count + Number(g.total),
          0,
        );
        notesLines.push(`Total number sliding glass doors ${totalSliding}`);
        notesLines.push('--Sliding doors details--');
        notesLines.push(
          ...filledSlidingDoors.map(
            (g) =>
              `Number of sliding doors: ${g.total} - Size of the sliding door ${
                schema.slidingToSize[
                  g.size as keyof typeof schema.slidingToSize
                ]
              } sqft`,
          ),
        );
      }
    }

    return [finalCost, notesLines.join('\n')];
  },
  panel_removal({ panel_removal_how_many }, prices, state) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const price = (() => {
      if (state === 'california') {
        return prices.attic_fan.california.pricesByCity[0]!.list[0]!.value;
      }
      return prices.attic_fan.nevada.list[0]!.value;
    })();

    if (panel_removal_how_many) {
      finalCost += price * utils.parseInteger(panel_removal_how_many);
      notesLines.push(`${panel_removal_how_many} panels will be removed`);
    }

    return [finalCost, notesLines.join('\n')];
  },
  roof(
    {
      roof_fascia_included_square_footage,
      roof_fascia_included,
      roof_layers_how_many,
      roof_layover_or_tear,
      roof_tear_material,
      roof_patio_how_many,
      roof_patio_included,
      roof_plywood_replaced_square,
      roof_plywood_replaced,
      roof_square_footage,
    },
    prices,
    state,
  ) {
    const priceList = (() => {
      if (state === 'california') {
        return prices.roof.california.pricesByCity[0]!.list;
      }
      return prices.roof.nevada.list;
    })();
    let finalCost = 0;
    const notesLines: string[] = [];

    if (roof_square_footage && roof_layover_or_tear && roof_layers_how_many) {
      const layoverPrice = (() => {
        if (state === 'california') {
          return priceList.find(
            (cp) => cp.label === schema.RoofWorkType.Layover,
          )!.value;
        }
        return priceList.find((cp) => cp.label === schema.RoofWorkType.Layover)!
          .value;
      })();
      const tearOffPrice = (() => {
        if (state === 'california') {
          const price30y = priceList.find(
            (cp) => cp.label === schema.RoofWorkType.TearOff,
          )!.value;
          const price50y = priceList.find(
            (cp) => cp.label === 'Tear off (50 Years)',
          )!.value;

          return roof_tear_material === '50' ? price50y : price30y;
        }

        const price30y = priceList.find(
          (cp) => cp.label === schema.RoofWorkType.TearOff,
        )!.value;
        const price50y = priceList.find(
          (cp) => cp.label === 'Tear off (50 Years)',
        )!.value;

        return roof_tear_material === '50' ? price50y : price30y;
      })();

      const pricePerSq =
        roof_layover_or_tear === schema.RoofWorkType.Layover
          ? layoverPrice
          : tearOffPrice;

      const roofSquares = utils.parseInteger(roof_square_footage);

      finalCost += pricePerSq * roofSquares;
      notesLines.push(`Roof measure sq: ${roof_square_footage}`);
      notesLines.push(`Type of roof work: ${roof_layover_or_tear}`);
      if (
        roof_layover_or_tear === schema.RoofWorkType.TearOff &&
        roof_tear_material
      ) {
        notesLines.push(`Material duration: ${roof_tear_material} years`);
        const additionalLayersPrice = priceList.find(
          (cp) => cp.label === 'Tear-off cost per additional layer',
        )!.value;

        notesLines.push(
          `Number of current shingle layers ${roof_layers_how_many}`,
        );
        const additionalLayers = utils.parseInteger(roof_layers_how_many) - 1;
        if (additionalLayers > 0) {
          finalCost += additionalLayersPrice * additionalLayers * roofSquares;
          notesLines.push(
            `Number of additional layers (Tear-off): ${additionalLayers}`,
          );
        }

        if (roof_plywood_replaced === 'yes' && roof_plywood_replaced_square) {
          const roofPlywoodPrice = (() => {
            if (state === 'california') {
              return prices.roof.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Plywood replacement per sq',
              )!.value;
            }
            return prices.roof.nevada.list.find(
              (cp) => cp.label === 'Plywood replacement per sq',
            )!.value;
          })();

          finalCost +=
            roofPlywoodPrice * utils.parseInteger(roof_plywood_replaced_square);
          notesLines.push(
            `${roof_plywood_replaced_square} sq of plywood will be replaced`,
          );
        } else {
          notesLines.push('No plywood will be replaced');
        }
      } else {
        notesLines.push(
          `Number of layers to be installed: ${roof_layers_how_many}`,
        );
      }
    } else {
      notesLines.push('No main roof work will be performed');
    }

    if (roof_fascia_included === 'yes' && roof_fascia_included_square_footage) {
      const roofFasciaPrice = (() => {
        if (state === 'california') {
          return prices.roof.california.pricesByCity[0]!.list.find(
            (cp) => cp.label === 'Roof Fascia per linear ft',
          )!.value;
        }
        return prices.roof.nevada.list.find(
          (cp) => cp.label === 'Roof Fascia per linear ft',
        )!.value;
      })();

      finalCost +=
        roofFasciaPrice *
        utils.parseInteger(roof_fascia_included_square_footage);

      notesLines.push(
        `Fascia included with ${roof_fascia_included_square_footage} sq`,
      );
    } else {
      notesLines.push('No fascia included');
    }

    if (roof_patio_included === 'yes' && roof_patio_how_many) {
      const roofPatioPrice = (() => {
        if (state === 'california') {
          return prices.roof.california.pricesByCity[0]!.list.find(
            (cp) => cp.label === 'Flat roof per sq',
          )!.value;
        }
        return prices.roof.nevada.list.find(
          (cp) => cp.label === 'Flat roof per sq',
        )!.value;
      })();

      finalCost += roofPatioPrice * utils.parseInteger(roof_patio_how_many);
      notesLines.push(`Patio included with ${roof_patio_how_many} sq`);
    } else {
      notesLines.push('No patio included');
    }

    return [finalCost, notesLines.join('\n')];
  },
  small_system(_, prices, state) {
    const price = (() => {
      if (state === 'california') {
        return prices.small_system.california.pricesByCity[0]!.list[0]!.value;
      }
      return prices.small_system.nevada.list[0]!.value;
    })();

    return [price, ''];
  },
  smart_thermostat({ smart_thermostat_how_many }, prices, state) {
    let finalCost = 0;
    const notesLines: string[] = [];

    const price = (() => {
      if (state === 'california') {
        return prices.smart_thermostat.california.pricesByCity[0]!.list[0]!
          .value;
      }
      return prices.smart_thermostat.nevada.list[0]!.value;
    })();

    if (smart_thermostat_how_many) {
      finalCost += price * utils.parseInteger(smart_thermostat_how_many);
      notesLines.push(
        `${smart_thermostat_how_many} smart thermostats will be installed`,
      );
    }

    return [finalCost, notesLines.join('\n')];
  },
  solar_tax(_, __, state) {
    if (state === 'california' || state === 'nevada') {
      return [600, ''];
    }
    return [0, ''];
    // const price = (() => {
    //   if (state === 'california') {
    //     return prices.solar_tax.california.pricesByCity[0]!.list[0]!.value
    //   }
    //   return prices.solar_tax.nevada.list[0]!.value
    // })()

    // return [price, '']
  },
  sub_panel_upgrade(
    { main_panel_upgrade_installation_company },
    prices,
    state,
  ) {
    const price = (() => {
      if (state === 'california') {
        return prices.sub_panel_upgrade.california.pricesByCity[0]!.list[0]!
          .value;
      }
      return prices.sub_panel_upgrade.nevada.list[0]!.value;
    })();

    return [main_panel_upgrade_installation_company ? price : 0, ''];
  },
};

export const addUpWindows = (customer: Customer): number | string => {
  try {
    const windows = JSON.parse(
      customer.new_windows_sqft_each_window as string,
    ) as schema.WindowGroup[];

    return windows
      .filter((w) => !!w.size)
      .reduce((acc, curr) => acc + utils.parseInt(curr.window), 0);
  } catch (e) {
    const msg = `Error adding up windows: ${utils.processErr(e)}`;
    utils.log(e);
    return msg;
  }
};

export const addUpSlidingDoors = (customer: Customer): number | string => {
  try {
    if (customer.new_windows_sliding_glass === 'no') {
      return 0;
    }
    const slidingDoors = JSON.parse(
      (customer.new_windows_sliding_glass_sqft as string) || '[]',
    ) as schema.SlidingGlassDoorGroup[];

    return slidingDoors
      .filter((s) => !!s.size)
      .reduce((acc, curr) => acc + utils.parseInt(curr.total), 0);
  } catch (e) {
    const msg = `Error adding up sliding doors: ${utils.processErr(e)}`;
    utils.log(e);
    return msg;
  }
};
