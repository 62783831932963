import React from 'react';

import * as c from '../../../common';
import * as r from '../../../react-utils';

import * as Mui from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import { SxProps, useTheme } from '@mui/material/styles';
import axios from 'axios';
import Carousel, { ResponsiveType } from 'react-multi-carousel';

import { Dropzone, FileItem, FileValidated } from '@dropzone-ui/react';
// @ts-expect-error
import { Fancybox as FancyBox } from '@fancyapps/ui';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { FadeLoader } from 'react-spinners';
import { v1 } from 'uuid';
import '@fancyapps/ui/dist/fancybox.css';
import 'react-multi-carousel/lib/styles.css';

import ToastAlert from '../ToastAlert';
import decalCert from '../../img/manufactured/decal_certificate.png';
import foundationHome from '../../img/manufactured/foundation_home.png';
import hudPlate from '../../img/manufactured/hud_plate.png';
import installationCert from '../../img/manufactured/installation_certificate.png';
import linkImage from '../../icons/enlace-externo.png';
import propertyTitle from '../../img/manufactured/property_title.png';
import {
  ROOF_CLAIMS_3D_VIEW,
  ROOF_CLAIMS_ADDRESS_VERIFICATION,
  ROOF_CLAIMS_DRIP,
  ROOF_CLAIMS_FLASHINGS,
  ROOF_CLAIMS_FRONT_HOUSE,
  ROOF_CLAIMS_GUTTER,
  ROOF_CLAIMS_HAIL,
  ROOF_CLAIMS_LAYERS,
  ROOF_CLAIMS_LENGTH_SHINGLE,
  ROOF_CLAIMS_OTHER_PROPERTY,
  ROOF_CLAIMS_PENETRATIONS,
  ROOF_CLAIMS_ROOF_INCLINATION,
  ROOF_CLAIMS_ROOF_OVERVIEW,
  ROOF_CLAIMS_SHINGLES_COLOR,
  ROOF_CLAIMS_SIDING,
  ROOF_CLAIMS_WIRES_HOME,
  ROOF_CLAIMS_GARAGE,
  ROOF_CLAIMS_ICE_SHIELD,
} from '../../img/RoofClaims';

import MediaImage from '../MediaImage';
import Pad from '../Pad';

import styles from './MediaInput.module.css';

import './Dropzone.css';

let zip = require('jszip')();

export interface MediaInputProps {
  customerID: string;
  ofWhat: c.MediaKind;
}

export default React.memo<MediaInputProps>(function MediaInput({
  customerID,
  ofWhat,
}) {
  const colorScheme = useTheme().palette.mode;
  const isDark = colorScheme === 'dark';
  const dispatch = useDispatch();
  const isMounted = r.useIsMounted();

  const sliderRef = React.useRef<HTMLDivElement>(document.createElement('div'));
  const selectCustomerMedia = React.useMemo(() => c.makeSelectMedia(), []);
  const [files, setFiles] = React.useState<FileValidated[]>([]);

  const [successfulUpload, setSuccessfulUpload] = React.useState(false);
  const [loadImages, setLoadImages] = React.useState(false);

  const showSuccessMessage = successfulUpload && !loadImages ? true : false;

  const selectorArgs = React.useMemo(
    () => ({
      customerID,
      mediaKind: ofWhat,
    }),
    [customerID, ofWhat],
  );

  const customerMedia = c.useSelector((_) =>
    selectCustomerMedia(_, selectorArgs),
  );

  const filteredMedia = React.useMemo(
    () =>
      c.pickBy(
        customerMedia,
        (mediaItem) =>
          mediaItem.image_of.toLowerCase().includes(ofWhat) ||
          mediaItem.video_of.toLowerCase().includes(ofWhat),
      ),
    [customerMedia, ofWhat],
  );

  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomerField> =>
      c.makeSelectCustomerField(),
    [],
  );
  const selectCustomerFieldArgs = React.useMemo(
    (): c.SelectCustomerFieldParams => ({
      customerID,
      field: 'customerName',
    }),
    [customerID],
  );
  // Remember to subscribe to the customer elsewhere!
  const customerName = c.useSelector(
    (_): c.ValueOf<c.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  );

  const handleSuccessFullUpload = React.useCallback(() => {
    setSuccessfulUpload((current) => !current);
  }, [setSuccessfulUpload]);

  const updateFiles = React.useCallback(
    (incomingFiles: FileValidated[]) => {
      setFiles(incomingFiles);
    },
    [setFiles],
  );

  React.useEffect((): c.EmptyFn => {
    dispatch(c.subToCustomerMedia({ customerID }));

    return (): void => {
      dispatch(
        c.unSubFromCustomerMedia({
          customerID,
        }),
      );
    };
  }, [customerID, dispatch]);

  const mediaItems = c.entries(filteredMedia);

  React.useEffect(() => {
    let mediaIDs: string[] = [];
    for (const [key, mediaItem] of mediaItems) {
      if (mediaItem.err) {
        mediaIDs.push(key);
        alert(`
           An error occurred while uploading the image: ${mediaItem.err}`);
      }
    }

    for (const id of mediaIDs) {
      c.dispatch(c.deleteMediaItem(customerID, id));
    }
  }, [customerID, mediaItems]);

  const uploadFiles = React.useCallback(async () => {
    if (!files.length) return;
    if (!isMounted()) return;
    setLoadImages(true);

    files.forEach((file) => {
      handleFile(customerID, file, ofWhat);
    });
    if (!navigator.onLine) {
      setLoadImages(false);
    } else {
      setSuccessfulUpload(true);
    }

    setFiles([]);
  }, [
    customerID,
    files,
    isMounted,
    ofWhat,
    setFiles,
    setLoadImages,
    setSuccessfulUpload,
  ]);

  const handlePreviewImages = React.useCallback(() => {
    if (manufacturedHomeImage[ofWhat]) {
      FancyBox.show([
        {
          src: manufacturedHomeImage[ofWhat],
          thumb: manufacturedHomeImage[ofWhat],
          caption: c.mediaToLabelManufacturedHome[ofWhat],
        },
      ]);
    }
    if (roofingClaimsImage[ofWhat]) {
      const fancyBoxMedia = roofingClaimsImage[ofWhat]?.map((media) => ({
        src: media.source,
        thumb: media.source,
        caption: media.label,
      }));

      FancyBox.show(fancyBoxMedia);
    }
  }, [ofWhat]);
  const downloadImages = React.useCallback(() => {
    const urls = c
      .values(filteredMedia)
      .map((value) => c.getMediaItemSrc(value));

    if (!navigator.onLine) {
      alert('No internet connection');
      return;
    }

    if (!urls || !urls.length) return;

    setLoadImages(true);
    let temp: string[] = [];

    urls.forEach(async (value, i) => {
      try {
        const res = await axios.get(`${value}`, {
          responseType: 'arraybuffer',
        });

        let blob = new Blob([res.data]);

        let reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onload = function () {
          const formattedString = reader.result as string;
          const _formatted = formattedString.replace(
            'data:application/octet-stream;base64,',
            // `data:${res.headers['content-type']}`
            '',
          );

          temp.push(_formatted);
          if (temp.length !== urls.length) {
            return;
          }
          temp.map((file, i) =>
            zip.file(`${ofWhat}-${i + 1}.jpg`, file, { base64: true }),
          );

          zip
            .generateAsync({ type: 'blob' })
            .then(function (content: string | Blob) {
              if (!navigator.onLine) {
                setLoadImages(false);
                return;
              }
              saveAs(content, `${customerName} ${ofWhat} Images.zip`);
            })
            .then(() => {
              setLoadImages(false);
            });
        };
      } catch (error) {
        alert(
          `Error downloading image number ${i + 1} of ${
            c.mediaToLabel[ofWhat]
          }, please try again\n${error}`,
        );
        setLoadImages(false);
      }
    });

    zip = require('jszip')();
  }, [customerName, filteredMedia, ofWhat]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted()) {
        setLoadImages(false);
      }
    }, 2000);
  }, [filteredMedia, isMounted]);

  const maxFilesUpload = c.validateNumberOfPhotosAllowed(ofWhat);

  const disableUpload =
    !files.length ||
    maxFilesUpload === 0 ||
    c.keys(filteredMedia).length >= maxFilesUpload;

  return (
    <React.Fragment>
      <div className={styles['container']}>
        <Pad amt={20} />
        <div className={styles['title-drag-wrapper']}>
          <div className={styles['group-title']}>
            <label htmlFor={ofWhat} className={styles['label']}>
              {ofWhat.endsWith('site_survey')
                ? c.mediaToLabelSiteSurvey[ofWhat]
                : ofWhat.endsWith('mh')
                ? c.mediaToLabelManufacturedHome[ofWhat]
                : ofWhat.startsWith('rc')
                ? c.mediaToLabelRoofClaims[ofWhat]
                : c.mediaToLabel[ofWhat]}
            </label>
            {ofWhat.endsWith('mh') || ofWhat.startsWith('rc') ? (
              <img
                alt="Example Pic"
                // data-caption={common.mediaToLabelManufacturedHome[ofWhat]}
                // data-fancybox
                // data-src={manufacturedHomeImage[ofWhat]}
                // data-thumb={manufacturedHomeImage[ofWhat]}
                height={20}
                onClick={handlePreviewImages}
                src={linkImage}
                style={{ cursor: 'pointer' }}
                width={20}
              />
            ) : null}

            <div style={{ marginLeft: 'auto' }}>
              <MuiIcon.Download
                onClick={downloadImages}
                sx={loadImages ? sx['hidden'] : sx['downloadIcon']}
              />
            </div>
          </div>

          <Pad amt={20} />

          <Mui.Box sx={sx['dropzoneBox']}>
            <Dropzone
              accept="image/*,video/*"
              backgroundColor={
                c.themeTuple[colorScheme].paper.backgroundColor as string
              }
              className={styles['dropzone']}
              maxFiles={maxFilesUpload}
              onChange={updateFiles}
              value={files}
            >
              <div
                className={
                  files.length ? styles['hidden'] : styles['dropzone-text']
                }
              >
                <pre
                  className={
                    files.length ? styles['hidden'] : styles['dropzone-label']
                  }
                >
                  {`Click here to upload`}
                </pre>
                <pre className={styles['dropzone-lg-label']}>{`or`}</pre>
                <pre
                  className={styles['dropzone-lg-label']}
                >{`Drag and drop your files`}</pre>
              </div>

              {files.map((file) => (
                <FileItem
                  {...file}
                  key={(() => {
                    if (!file.id) {
                      console.error(`File without id: ${file.id}`, file);
                    }
                    console.log({ file });
                    return file.id;
                  })()}
                  preview
                />
              ))}
            </Dropzone>
            <Pad amt={20} />
            <Mui.Button
              disabled={disableUpload}
              onClick={uploadFiles}
              startIcon={<MuiIcon.Publish />}
              variant="contained"
            >
              Upload
            </Mui.Button>
          </Mui.Box>
        </div>
        <Pad amt={40} />
        <Mui.Box
          sx={
            (Object.keys(filteredMedia).length
              ? isDark
                ? sx['carouselBoxDark']
                : sx['carouselBox']
              : sx['hidden']) as SxProps
          }
          ref={sliderRef}
        >
          {/* @ts-ignore */}

          <Carousel
            itemClass="item-slider"
            responsive={responsive}
            swipeable={true}
          >
            {c.keys(filteredMedia).map((id) => (
              <MediaImage
                customerID={customerID}
                key={id}
                mediaItemID={id}
                mediaKind={ofWhat}
              />
            ))}
          </Carousel>
        </Mui.Box>

        <Mui.Modal onClose={() => {}} open={loadImages}>
          <Mui.Box>
            <FadeLoader color="grey" cssOverride={cssOverride} />
          </Mui.Box>
        </Mui.Modal>
        {/* <p>{numOfPhotos} photos</p>

      <p>{numOfVideos} videos</p> */}
      </div>

      <ToastAlert
        duration={6000}
        handleClose={handleSuccessFullUpload}
        message="Files Successfully Uploaded!"
        open={showSuccessMessage}
        severity="success"
        type="alert"
      />
    </React.Fragment>
  );
});

const cssOverride: React.CSSProperties = {
  display: 'block',
  left: '50%',
  margin: '0 auto',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

const carouselBoxBaseStyle: React.CSSProperties = {
  margin: '0 auto',
  paddingBottom: '10px',
  paddingTop: '10px',
  width: '100%',
};

const sx = {
  alertUpload: { fontSize: '18px', width: '298px' },
  carouselBox: {
    ...carouselBoxBaseStyle,
    backgroundColor: c.themeTuple.light.paper.backgroundColor!,
  },
  carouselBoxDark: {
    ...carouselBoxBaseStyle,
    backgroundColor: c.themeTuple.dark.paper.backgroundColor!,
  },
  downloadIcon: { cursor: 'pointer', fontSize: '40' },
  dropzoneBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  hidden: { display: 'none' },
  snackbar: { width: '298px' },
};

const manufacturedHomeImage: c.DeepReadonly<
  Partial<Record<c.MediaKind, string>>
> = {
  hud_plate_mh: hudPlate,
  install_certificate_mh: installationCert,
  deal_certificate_mh: decalCert,
  property_title_mh: propertyTitle,
  foundation_pic_mh: foundationHome,
};

const roofingClaimsImage: c.DeepReadonly<
  Partial<Record<c.MediaKind, c.ExampleImage[]>>
> = {
  rc_3d_view: ROOF_CLAIMS_3D_VIEW,
  rc_address_verification: ROOF_CLAIMS_ADDRESS_VERIFICATION,
  rc_drip_edge: ROOF_CLAIMS_DRIP,
  rc_flashings: ROOF_CLAIMS_FLASHINGS,
  rc_front_house: ROOF_CLAIMS_FRONT_HOUSE,
  rc_gutters: ROOF_CLAIMS_GUTTER,
  rc_hail: ROOF_CLAIMS_HAIL,
  rc_layers: ROOF_CLAIMS_LAYERS,
  rc_length_shingle: ROOF_CLAIMS_LENGTH_SHINGLE,
  rc_other_property_wind_damage: ROOF_CLAIMS_OTHER_PROPERTY,
  rc_wind_damages: ROOF_CLAIMS_OTHER_PROPERTY,
  rc_penetrations: ROOF_CLAIMS_PENETRATIONS,
  rc_roof_inclination: ROOF_CLAIMS_ROOF_INCLINATION,
  rc_roof_overview: ROOF_CLAIMS_ROOF_OVERVIEW,
  rc_siding: ROOF_CLAIMS_SIDING,
  rc_wires_home: ROOF_CLAIMS_WIRES_HOME,
  rc_shingles_color: ROOF_CLAIMS_SHINGLES_COLOR,
  rc_garage: ROOF_CLAIMS_GARAGE,
  rc_ice_shield: ROOF_CLAIMS_ICE_SHIELD,
};

const responsive: ResponsiveType = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const handleFile = async (
  customerID: string,
  file: FileValidated,
  ofWhat: c.MediaKind,
) => {
  if (!navigator.onLine) {
    alert(
      `You do not have internet access and the file ${file.file.name} has not been uploaded`,
    );
    return;
  }
  if (file) {
    let type: 'video' | 'image';
    if (file.file?.type.startsWith('image')) {
      type = 'image';
    } else if (file.file.type.startsWith('video')) {
      type = 'video';
    } else {
      return alert('Null or Invalid File');
    }

    if (type === 'video') {
      const videoFileReader = new FileReader();
      videoFileReader.readAsDataURL(file.file);
      videoFileReader.onload = function () {
        const videoBase64 = videoFileReader.result as string;
        c.dispatch(
          c.requestedMediaUpload({
            items: [
              {
                customerID: customerID,
                name: v1(),
                ofWhat,
                type: type,
                path: videoBase64,
                thumbnail: '',
              },
            ],
          }),
        );
      };

      return;
    }

    let thumbnailReader = new FileReader();

    const thumbnail = (await makeThumbnail(file.file)) as Blob;

    thumbnailReader.readAsDataURL(thumbnail as Blob);
    thumbnailReader.onload = async function () {
      const base64 = thumbnailReader.result as string;
      let compressPercent = 60;

      if (file.file.size >= 1000000) {
        compressPercent = 50;
      }

      if (file.file.size >= 5000000) {
        compressPercent = 10;
      }

      const compressImage = (await imageCompress(
        file.file,
        compressPercent,
      )) as Blob;

      const imageFile = blobToFile(compressImage, file.file.name);
      console.log(
        'Image BIN: ',
        imageFile,
        ' IMAGE STRING: ',
        JSON.parse(JSON.stringify(imageFile)),
      );
      const imageFileReader = new FileReader();

      imageFileReader.readAsDataURL(imageFile as Blob);

      imageFileReader.onload = function () {
        const imageFileBase64 = imageFileReader.result as string;
        c.dispatch(
          c.requestedMediaUpload({
            items: [
              {
                customerID: customerID,
                name: v1(),
                ofWhat,
                type: type,
                path: imageFileBase64,
                thumbnail: base64 || '',
              },
            ],
          }),
        );
      };
    };
  } else {
    return alert('Empty input!!');
  }
};

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
};

const imageCompress = (file: File, qualityPercent: number) => {
  return new Promise((resolve, reject) => {
    const $canvas = document.createElement('canvas');
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const maxEdge = 2048;
      const isHorizontal = image.width > image.height;
      const longEdge = isHorizontal ? image.width : image.height;
      const ratio = maxEdge / longEdge;
      $canvas.width = isHorizontal ? maxEdge : image.width * ratio;
      $canvas.height = !isHorizontal ? maxEdge : image.height * ratio;
      $canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, $canvas.width, $canvas.height);
      $canvas.toBlob(
        (blob) => {
          if (blob === null) {
            return reject(blob);
          } else {
            resolve(blob);
          }
        },
        'image/jpeg',
        qualityPercent / 100,
      );
    };
  });
};

const makeThumbnail = (file: File) => {
  return new Promise((resolve, reject) => {
    const $canvas = document.createElement('canvas');
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const width = image.width > image.height ? 320 : 240;
      const ratio = width / image.width;
      $canvas.width = width;
      $canvas.height = image.height * ratio;
      $canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, $canvas.width, $canvas.height);

      $canvas.toBlob(
        (blob) => {
          if (blob === null) {
            return reject(blob);
          } else {
            resolve(blob);
          }
        },
        'image/jpeg',
        100,
      );
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
};
