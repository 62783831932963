import React from 'react';

import { mediaToLabelSiteSurvey, MediaKind } from '../../../common';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MediaInput from '../../comps/MediaInput';
import Pad from '../../comps/Pad';

export interface SiteSurveyProps {
  customerID: string;
}

export default React.memo<SiteSurveyProps>(function SiteSurvey({ customerID }) {
  const mediaRef = React.useRef<HTMLDivElement>(document.createElement('div'));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: '32px', fontWeight: '700' }}>
        Site Survey:
      </Typography>
      <Pad amt={20} />
      <Box ref={mediaRef} sx={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(mediaToLabelSiteSurvey).map((mediaKind) => (
          <React.Fragment key={customerID + mediaKind}>
            <MediaInput
              customerID={customerID}
              ofWhat={mediaKind as MediaKind}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
});
