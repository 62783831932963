import React from 'react';

import * as common from '../../../common';

import * as mui from '@mui/material';

import classNames from './PrivacyPolicy.module.css';

export interface PrivacyPolicyProps {}

export default React.memo<PrivacyPolicyProps>(function PrivacyPolicy() {
  return (
    <div className={classNames['container']}>
      <img
        alt="Ecohome Logo"
        className={classNames['brand']}
        src={common.LOGO_BIG}
        width={200}
      />

      <mui.Typography textAlign="center" variant="h1">
        Privacy Policy for Ecohome Reports
      </mui.Typography>

      <mui.Typography textAlign="center" variant="caption">
        Last Updated: August 16th, 2023
      </mui.Typography>

      <mui.Typography marginBottom={2} variant="h3">
        1. Introduction
      </mui.Typography>
      <mui.Typography marginBottom={2} variant="body1">
        Welcome to Ecohome Reports ("we", "our", or "us"). We are committed to
        protecting your privacy and providing transparency about how we collect,
        use, and share your information. This Privacy Policy outlines the types
        of data we may collect from you and how we use that data. By using our
        app, you agree to the practices described in this policy.
      </mui.Typography>

      <mui.Typography marginBottom={2} variant="h3">
        2. Information We Collect
      </mui.Typography>
      <mui.List sx={sx.list}>
        <mui.ListItem>
          <mui.Typography variant="body1">
            Personal Information: We may collect personal information that you
            voluntarily provide to us, such as your name, email address, and
            other contact details. This information is used to personalize your
            experience and communicate with you.
          </mui.Typography>
        </mui.ListItem>
        <mui.ListItem>
          <mui.Typography variant="body1">
            Usage Data: We may automatically collect information about how you
            use our app. This includes data such as your device type, operating
            system, app version, and interactions within the app. Usage data
            helps us improve our app's performance and user experience.
          </mui.Typography>
        </mui.ListItem>
        {/* <mui.ListItem>
          <mui.Typography variant="body1">
            Location Information: With your consent, we may collect and process
            information about your approximate location. This is used for
            features that require location data, such as [mention relevant
            features].
          </mui.Typography>
        </mui.ListItem> */}
      </mui.List>

      <mui.Typography marginBottom={2} variant="h3">
        3. How We Use Your Information
      </mui.Typography>
      <mui.Typography variant="body1">
        We use the information we collect for various purposes, including but
        not limited to:
      </mui.Typography>
      <mui.List sx={sx.list}>
        <mui.ListItem>
          <mui.Typography>
            Providing and improving our app's functionality and features.
          </mui.Typography>
        </mui.ListItem>
        <mui.ListItem>
          <mui.Typography>
            Personalizing your experience within the app.
          </mui.Typography>
        </mui.ListItem>
        {/* <mui.ListItem>
          <mui.Typography>
            Sending you updates, announcements, and promotional materials (with
            your consent).
          </mui.Typography>
        </mui.ListItem> */}
        <mui.ListItem>
          <mui.Typography>
            Analyzing usage patterns to enhance our app's performance and user
            satisfaction.
          </mui.Typography>
        </mui.ListItem>
      </mui.List>

      <mui.Typography marginBottom={2} variant="h3">
        4. Data Sharing and Disclosure
      </mui.Typography>
      <mui.Typography marginBottom={2}>
        We may share your information with third parties in the following
        circumstances:
      </mui.Typography>
      <mui.List sx={sx.list}>
        <mui.ListItem>
          <mui.Typography>
            Service Providers: We may engage third-party companies and
            individuals to facilitate our app's features or provide services on
            our behalf. These service providers may have access to your personal
            information but are obligated not to disclose or use it for any
            other purpose.
          </mui.Typography>
        </mui.ListItem>

        <mui.ListItem>
          <mui.Typography>
            Legal Compliance: We may disclose your information if required to do
            so by law or in response to valid legal requests.
          </mui.Typography>
        </mui.ListItem>
      </mui.List>

      <mui.Typography marginBottom={2} variant="h3">
        5. Your Choices
      </mui.Typography>
      <mui.List sx={sx.list}>
        <mui.ListItem>
          <mui.Typography>
            Opt-Out: You can opt-out of receiving promotional communications
            from us by following the instructions provided in the communication.
          </mui.Typography>
        </mui.ListItem>
        <mui.ListItem>
          <mui.Typography>
            Access and Update: You can review and update your personal
            information within the app's settings.
          </mui.Typography>
        </mui.ListItem>
      </mui.List>

      <mui.Typography marginBottom={2} variant="h3">
        6. Security
      </mui.Typography>
      <mui.Typography marginBottom={2}>
        We take reasonable measures to protect your information from
        unauthorized access or disclosure. However, no data transmission over
        the internet is entirely secure. We cannot guarantee the security of
        your information.
      </mui.Typography>

      <mui.Typography marginBottom={2} variant="h3">
        7. Changes to This Privacy Policy
      </mui.Typography>
      <mui.Typography marginBottom={2}>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the updated policy in the app. Your continued
        use of the app after such changes will constitute your consent to the
        updated policy.
      </mui.Typography>

      <mui.Typography marginBottom={2} variant="h3">
        8. Contact Us
      </mui.Typography>
      <mui.Typography marginBottom={2}>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at privacy@ecohomereports.com.
      </mui.Typography>
    </div>
  );
});

const sx = {
  list: {
    listStyleType: 'disc',
    marginBottom: 2,
    pl: 2,
  },
};
