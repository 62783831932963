import React, { ReactElement, memo } from 'react';
import * as rn from 'react-native';
import {
  Dimensions,
  Modal,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import * as c from '../../common';
import { MediaKind } from '../../common';

import Feather from 'react-native-vector-icons/Feather';
// import FastImage from 'react-native-fast-image'

import DealCertificate from '../assets/img/decal_certificate.png';
import FoundationPicture from '../assets/img/foundation_picture.png';
import PropertyTitle from '../assets/img/property_title.png';
import HudPlate from '../assets/img/hud_plate.png';
import InstallationCertificate from '../assets/img/installation_certificate.png';

import { ALL_EXTERIOR_WALLS_SITE_SURVEY } from '../assets/img/AllExteriorWalls';
import { ELECTRICAL_PANEL_SITE_SURVEY } from '../assets/img/ElectricalPanel';

import {
  MPU_BREAKER_RATING,
  MPU_BUS_RATING,
} from '../assets/img/MainPanelUpgrade';
import { RAFTERS_SITE_SURVEY } from '../assets/img/Rafters';
import {
  ROOF_CLAIMS_3D_VIEW,
  ROOF_CLAIMS_ADDRESS_VERIFICATION,
  ROOF_CLAIMS_DRIP,
  ROOF_CLAIMS_FLASHINGS,
  ROOF_CLAIMS_FRONT_HOUSE,
  ROOF_CLAIMS_GUTTER,
  ROOF_CLAIMS_HAIL,
  ROOF_CLAIMS_LAYERS,
  ROOF_CLAIMS_LENGTH_SHINGLE,
  ROOF_CLAIMS_OTHER_PROPERTY,
  ROOF_CLAIMS_PENETRATIONS,
  ROOF_CLAIMS_ROOF_INCLINATION,
  ROOF_CLAIMS_ROOF_OVERVIEW,
  ROOF_CLAIMS_SIDING,
  ROOF_CLAIMS_WIRES_HOME,
  ROOF_CLAIMS_SHINGLES_COLOR,
  ROOF_CLAIMS_GARAGE,
  ROOF_CLAIMS_ICE_SHIELD,
} from '../assets/img/RoofClaims';
import { ROOF_ALL_PANELS_SITE_SURVEY } from '../assets/img/RoofFromAllPlanes';

export interface MediaExampleModalProps {
  readonly mediaKind: MediaKind | 'mpu_bus_rating' | 'mpu_breaker_rating';
  readonly onRequestClose: () => void;
  readonly title?: string;
  readonly visible: boolean;
}

export default memo<MediaExampleModalProps>(function MediaExampleModal({
  mediaKind,
  onRequestClose,
  title,
  visible,
}): ReactElement {
  const sources = (() => {
    // eslint-disable-next-line no-lone-blocks
    {
      switch (mediaKind) {
        case 'foundation_pic_mh':
          return FoundationPictureBoxed;
        case 'hud_plate_mh':
          return HudPlateBoxed;
        case 'install_certificate_mh':
          return InstallationCertificateBoxed;
        case 'deal_certificate_mh':
          return DealCertificateBoxed;
        case 'property_title_mh':
          return PropertyTitleBoxed;
        case 'all_planes_roof_site_survey':
          return ROOF_ALL_PANELS_SITE_SURVEY;
        case 'all_exterior_walls_site_survey':
          return ALL_EXTERIOR_WALLS_SITE_SURVEY;
        case 'rafters_site_survey':
          return RAFTERS_SITE_SURVEY;
        case 'electrical_panel_site_survey':
          return ELECTRICAL_PANEL_SITE_SURVEY;
        case 'mpu_bus_rating':
          return MPU_BUS_RATING;
        case 'mpu_breaker_rating':
          return MPU_BREAKER_RATING;
        case 'rc_front_house':
          return ROOF_CLAIMS_FRONT_HOUSE;
        case 'rc_address_verification':
          return ROOF_CLAIMS_ADDRESS_VERIFICATION;
        case 'rc_3d_view':
          return ROOF_CLAIMS_3D_VIEW;
        case 'rc_wires_home':
          return ROOF_CLAIMS_WIRES_HOME;
        case 'rc_siding':
          return ROOF_CLAIMS_SIDING;
        case 'rc_other_property_wind_damage':
          return ROOF_CLAIMS_OTHER_PROPERTY;
        case 'rc_wind_damages':
          return ROOF_CLAIMS_OTHER_PROPERTY;
        case 'rc_layers':
          return ROOF_CLAIMS_LAYERS;
        case 'rc_roof_inclination':
          return ROOF_CLAIMS_ROOF_INCLINATION;
        case 'rc_roof_overview':
          return ROOF_CLAIMS_ROOF_OVERVIEW;
        case 'rc_gutters':
          return ROOF_CLAIMS_GUTTER;
        case 'rc_flashings':
          return ROOF_CLAIMS_FLASHINGS;
        case 'rc_penetrations':
          return ROOF_CLAIMS_PENETRATIONS;
        case 'rc_hail':
          return ROOF_CLAIMS_HAIL;
        case 'rc_length_shingle':
          return ROOF_CLAIMS_LENGTH_SHINGLE;
        case 'rc_drip_edge':
          return ROOF_CLAIMS_DRIP;
        case 'rc_shingles_color':
          return ROOF_CLAIMS_SHINGLES_COLOR;
        case 'rc_garage':
          return ROOF_CLAIMS_GARAGE;
        case 'rc_ice_shield':
          return ROOF_CLAIMS_ICE_SHIELD;
        default:
          return c.EMPTY_ARRAY;
      }
    }
  })();

  return (
    <Modal
      animationType="fade"
      onRequestClose={onRequestClose}
      transparent={false}
      visible={visible}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          <View style={styles.contentBtn}>
            <TouchableOpacity
              onPress={onRequestClose}
              style={styles.btnCloseModal}
            >
              <Feather color="white" name="x" size={24} />
            </TouchableOpacity>
            <Text style={styles.title}>{title}</Text>
          </View>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={styles.contentContainerStyle}
            bouncesZoom
            horizontal
            pagingEnabled
            maximumZoomScale={5}
            minimumZoomScale={0.5}
            centerContent
          >
            {sources.map(
              (source): React.ReactElement => (
                <View
                  key={mediaKind + source.source}
                  style={styles.contentExampleItem}
                >
                  {Boolean(source.label) && (
                    <Text style={styles.imageTitleExample}>{source.label}</Text>
                  )}
                  <rn.Image
                    source={source.source}
                    style={source.label ? styles.image : styles.fullImage}
                    resizeMode="contain"
                  />
                </View>
              ),
            )}
          </ScrollView>
        </View>
      </SafeAreaView>
    </Modal>
  );
});

const { width, height } = Dimensions.get('screen');

const styles = StyleSheet.create({
  btnCloseModal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 10,
    justifyContent: 'center',
    padding: 5,
  },
  container: {
    backgroundColor: 'black',
    flex: 1,
  },
  contentBtn: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 15,
    marginVertical: 10,
  },
  contentContainerStyle: {
    alignItems: 'center',
    flexGrow: 0.8,
    justifyContent: 'center',
  },
  contentExampleItem: { height: '100%', width },
  fullImage: { height: '100%', width },
  image: { height: '80%', width },
  imageTitleExample: {
    color: '#FFF',
    fontFamily: c.themeTuple.light.fontFamily,
    fontSize: 20,
    marginBottom: 5,
    marginTop: '10%',
    textAlign: 'center',
  },
  scrollView: { height, width },
  title: {
    color: '#FFF',
    flexGrow: 1,
    fontFamily: c.themeTuple.light.fontFamily,
    fontSize: 18,
    marginLeft: '5%',
  },
  webView: {
    flex: 1,
  },
});

const FoundationPictureBoxed = [{ source: FoundationPicture }];
const HudPlateBoxed = [{ source: HudPlate }];
const InstallationCertificateBoxed = [{ source: InstallationCertificate }];
const DealCertificateBoxed = [{ source: DealCertificate }];
const PropertyTitleBoxed = [{ source: PropertyTitle }];
