import React from 'react';

import {
  Customer,
  selectThumbnail,
  SimpleCustomer,
  updateCustomer,
  useSelector,
} from '../../../common';

import * as Mui from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Pad from '../Pad';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export interface CustomerCardProps {
  actionText?: string;
  customer: SimpleCustomer | Customer;
  extraAttr?: string;
  nonLink?: boolean;
  onAction?: (id: string) => void;
  onCustomerDeletion?: (customerID: string) => void;
}

export default React.memo<CustomerCardProps>(function ({
  actionText,
  extraAttr,
  customer,
  nonLink,
  onAction,
  onCustomerDeletion,
}) {
  const theme = useTheme();

  const mediaQuery = useMediaQuery();

  const thumbnail = useSelector(
    selectThumbnail(customer.objectID, customer.objectID),
  );

  const getInitials = (name: string) => {
    let _name = name.split(' ', 2);
    if (!_name.length) {
      return 'U';
    }

    if (_name.length > 1) {
      const first = _name[0];
      const last = _name[1];
      //@ts-ignore
      return `${first[0]?.toUpperCase() as string}${last[0]?.toUpperCase()}`;
    } else {
      const first = _name[0];
      //@ts-ignore
      return `${first[0]?.toUpperCase() as string}`;
    }
  };

  const handleAction = React.useCallback(() => {
    if (onCustomerDeletion) {
      updateCustomer(customer.objectID, {
        deleted: true,
      });
      onCustomerDeletion(customer.objectID);
    }
    onAction?.(customer.objectID);
  }, [customer.objectID, onAction, onCustomerDeletion]);

  return (
    <Mui.Card elevation={5} component={Mui.Paper} sx={sx['card']}>
      {!nonLink && (
        <Link to={`${customer.objectID}`}>
          <ChevronRightIcon
            sx={
              theme.palette.mode === 'light'
                ? sx['arrowIconDark']
                : sx['arrowIconLight']
            }
          />
        </Link>
      )}

      <Mui.CardContent sx={sx['cardContent']}>
        <Mui.Box sx={sx['customerData']}>
          <Mui.Box sx={sx['thumbnailBox']}>
            {thumbnail ? (
              <Mui.Avatar
                alt={getInitials(customer.customerName)}
                src={thumbnail}
                sx={sx['avatar']}
              />
            ) : (
              <Mui.Avatar {...stringAvatar(customer.customerName)}>
                {getInitials(customer.customerName)}
              </Mui.Avatar>
            )}
          </Mui.Box>
          <Pad amt={20} row />
          <Mui.Box sx={sx['nameDateBox']}>
            <Mui.Box sx={sx['flexMainDataBox']}>
              <Mui.Typography
                color={
                  theme.palette.mode === 'dark' ? 'text.secondary' : 'text.dark'
                }
                sx={
                  mediaQuery.width < 450 ? sx['mainSmText'] : sx['mainMdText']
                }
              >
                {customer.customerName}
              </Mui.Typography>
              <Mui.Typography
                color={
                  theme.palette.mode === 'dark' ? 'text.secondary' : 'text.dark'
                }
                sx={mediaQuery.width < 450 ? sx['smText'] : sx['mdText']}
              >
                {new Date(customer.sort_key * -1).toLocaleDateString('en')}
              </Mui.Typography>
            </Mui.Box>
            <Pad amt={10} />
            <Mui.Typography
              color="text.secondary"
              sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
            >
              {customer.customerAddress}
            </Mui.Typography>
            <Mui.Typography
              color="text.secondary"
              sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
            >
              {customer.solarRep}
            </Mui.Typography>
            <Mui.Typography
              color="text.secondary"
              sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
            >
              {customer.homeRep}
            </Mui.Typography>
            {extraAttr && (
              <Mui.Typography
                color="text.secondary"
                sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
              >
                {extraAttr}
              </Mui.Typography>
            )}
          </Mui.Box>
        </Mui.Box>
      </Mui.CardContent>

      <Mui.CardActions sx={sx['cardActions']}>
        <Mui.Button size="small" onClick={handleAction}>
          {actionText || 'Delete'}
        </Mui.Button>
      </Mui.CardActions>
    </Mui.Card>
  );
});

const sx = {
  arrowIconDark: {
    color: '#000',
    cursor: 'pointer',
    fontSize: '32px',
    position: 'absolute',
    right: '2px',
    top: 'calc( 50% - 16px )',
  },
  arrowIconLight: {
    color: '#fff',
    cursor: 'pointer',
    fontSize: '32px',
    position: 'absolute',
    right: '2px',
    top: 'calc( 50% - 16px )',
  },
  avatar: {
    height: '60px',
    width: '60px',
  },
  card: {
    display: {
      md: 'none',
      xs: 'block',
    },
    marginBottom: '30px',
    position: 'relative',
    width: '90%',
  },
  cardActions: {
    paddingBottom: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: 0,
  },
  cardContent: {
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '16px',
  },
  customerData: {
    display: 'flex',
  },
  flexMainDataBox: {
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '2px',
    paddingTop: '2px',
    width: '100%',
  },
  nameDateBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainMdText: {
    fontSize: '20px',
  },
  mainSmText: {
    fontSize: '18px',
  },
  smText: {
    fontSize: '12px',
  },
  mdText: {
    fontSize: '14px',
  },
  thumbnailBox: {
    alignItems: 'center',
    display: 'flex',
  },
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: '60px',
      width: '60px',
    },
  };
}
