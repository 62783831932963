import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { getDatabase, getUserID } from '../api';
import { Customer } from '../schema';
import { DeepReadonly, log, processErr } from '../utils';

import { YieldReturn } from './common';

const PREFIX = 'bookmarks';

export type BookmarksState = DeepReadonly<{
  IDs: Record<string, boolean>;
  isRefreshing: boolean;
}>;

const initialState: BookmarksState = {
  IDs: {},
  isRefreshing: true,
};

const bookmarksSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    bookmarked(
      { IDs },
      { payload: { customerID } }: PayloadAction<{ customerID: string }>,
    ) {
      IDs[customerID] = true;
    },
    receivedBookmarks(
      state,
      { payload: { IDs } }: PayloadAction<{ IDs: Record<string, boolean> }>,
    ) {
      state.IDs = IDs;
      state.isRefreshing = false;
    },
    requestedBookmarksRefresh(state) {
      state.isRefreshing = true;
    },
    unBookmarked(
      { IDs },
      { payload: { customerID } }: PayloadAction<{ customerID: string }>,
    ) {
      delete IDs[customerID];
    },
  },
});

export const {
  actions: { bookmarked, requestedBookmarksRefresh, unBookmarked },
  reducer: bookmarks,
} = bookmarksSlice;

const {
  actions: { receivedBookmarks },
} = bookmarksSlice;

//#region selectors

interface GlobalState {
  [PREFIX]: BookmarksState;
}

const selectBookmarksMap = ({ bookmarks: { IDs } }: GlobalState) => IDs;

export type SelectBookmarks = (state: GlobalState) => string[];

export const selectBookmarks: SelectBookmarks = createSelector(
  selectBookmarksMap,
  (map) => Object.keys(map),
);

export type SelectIsBookmarked = (
  state: GlobalState,
  params: { customerID: string },
) => boolean;

export const selectIsBookmarked =
  (customerID: string) =>
  ({ bookmarks: { IDs } }: GlobalState): boolean =>
    !!IDs[customerID];

export const selectIsRefreshingBookmarks = ({
  bookmarks: { isRefreshing },
}: GlobalState) => isRefreshing;

//#endregion selectors

function* bookmarkedWatcher({
  payload: { customerID },
}: ReturnType<typeof bookmarked>) {
  try {
    const userID = getUserID();
    if (!userID) {
      throw new ReferenceError(`No user ID inside bookmarksRefreshWatcher()`);
    }

    const op = () => {
      const db = getDatabase();
      return db.ref('Bookmarks').child(userID).child(customerID).set(true);
    };

    yield call(op);
  } catch (e) {
    const errMsg = processErr(e);
    log(`bookmarkedWatcher*() -> ${errMsg}`);
    log(e);
  }
}

function* unBookmarkedWatcher({
  payload: { customerID },
}: ReturnType<typeof unBookmarked>) {
  try {
    const userID = getUserID();
    if (!userID) {
      throw new ReferenceError(`No user ID inside bookmarksRefreshWatcher()`);
    }

    const checkBookmarker = () => {
      const db = getDatabase();
      return db.ref('Bookmarks').child(userID).child(customerID).once('value');
    };

    const res: YieldReturn<typeof checkBookmarker> = yield call(
      checkBookmarker,
    );

    const bookmarker = res.val();

    if (bookmarker === null) return;

    const op = () => {
      const db = getDatabase();
      return db.ref('Bookmarks').child(userID).child(customerID).remove();
    };

    yield call(op);
  } catch (e) {
    const errMsg = processErr(e);
    log(`unBookmarkedWatcher*() -> ${errMsg}`);
    log(e);
  }
}

function* bookmarksRefreshWatcher() {
  try {
    const userID = getUserID();
    if (!userID) {
      throw new ReferenceError(`No user ID inside bookmarksRefreshWatcher()`);
    }

    const db = getDatabase();

    const op = () => db.ref('Bookmarks').child(userID).once('value');

    const res: YieldReturn<typeof op> = yield call(op);

    const IDs = Object.keys(res.val() as Record<string, boolean>);
    const IDsFiltered: Record<string, boolean> = {};

    if (IDs.length > 0) {
      for (const key of IDs) {
        // @ts-ignore TODO
        const customerSnapshot = yield call(
          [db.ref('Customers').child(key), 'once'],
          'value',
        );
        const customer = customerSnapshot.val() as Customer;

        if (customer?.deleted) continue;

        IDsFiltered[key] = true;
      }

      yield put(
        receivedBookmarks({
          IDs: IDsFiltered,
        }),
      );
    } else {
      yield put(
        receivedBookmarks({
          IDs: {},
        }),
      );
    }
  } catch (e) {
    const errMsg = processErr(e);
    log(`bookmarksRefreshWatcher*() -> ${errMsg}`);
    log(e);
  }
}

export function* bookmarksSaga() {
  yield all([
    takeEvery(bookmarked, bookmarkedWatcher),
    takeEvery(unBookmarked, unBookmarkedWatcher),
    takeEvery(requestedBookmarksRefresh, bookmarksRefreshWatcher),
  ]);
}
