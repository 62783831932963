import * as E from 'redux-saga/effects';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Task as SagaTask, eventChannel } from 'redux-saga';

import * as api from '../api';
import * as env from '../env';
import * as schema from '../schema';
import * as utils from '../utils';

import { YieldReturn } from './common';

const PREFIX = 'invoices';

export const MAX_INVOICES_AT_ONCE = 80;

interface InvoicesState {
  readonly byID: readonly string[];
  readonly currentPage: number;
  readonly data: schema.Invoices;
  readonly filteringQueries: readonly schema.FilteringQuery<schema.Invoice>[];
  readonly invoiceUsers: schema.InvoiceUsers;
  readonly pages: number;
  readonly sortQueries: readonly schema.SortQuery<schema.Invoice>[];
}

const initialState: InvoicesState = {
  byID: [],
  currentPage: 0,
  data: {},
  filteringQueries: [],
  invoiceUsers: {},
  pages: 0,
  sortQueries: [],
};

const invoicesSlice = createSlice({
  extraReducers(builder) {
    return builder.addCase('persist/REHYDRATE', (state) => {
      state.byID = [];

      for (const invoice of utils.values(state.data)) {
        if (invoice.status === 'uploading' || invoice.status === 'error') {
          invoice.status = 'pending';
        }
      }
    });
  },
  initialState,
  name: PREFIX,
  reducers: {
    beganInvoiceUpload: (
      state,
      {
        payload: { customerID },
      }: PayloadAction<{
        readonly customerID: string;
      }>,
    ) => {
      // CAST: It's expected the invoice being there if uploading is about to
      // begin
      state.data[customerID]!.status = 'uploading';
    },
    clearedFilters: (state) => {
      state.byID = [];
      state.filteringQueries = [];
      state.currentPage = 0;
      state.pages = 0;
    },
    clearedSort: (state) => {
      state.byID = [];
      state.sortQueries = [];
    },
    deletedInvoice(
      state,
      {
        payload: { customerID },
      }: PayloadAction<{
        readonly customerID: string;
      }>,
    ) {
      state.byID = state.byID.filter((id) => id !== customerID);
      delete state.data[customerID];
    },
    filteredColumn: (
      state,
      {
        payload: { filteringQuery },
      }: PayloadAction<{
        filteringQuery: schema.FilteringQuery<schema.Invoice>;
      }>,
    ) => {
      state.byID = [];
      state.currentPage = 0;
      state.filteringQueries.push(filteringQuery);
      state.pages = 0;
    },
    invoiceCreated(
      state,
      {
        payload: { invoice: invoiceInfo },
      }: PayloadAction<{
        readonly invoice: schema.Invoice;
      }>,
    ) {
      state.data[invoiceInfo.customerID] = invoiceInfo;
      // TODO: Account for sorting when sorting is implemented
      const isOfCurrentCompany =
        state.filteringQueries.some(
          (fq) => fq.column === 'company' && fq.query === invoiceInfo.company,
        ) || state.filteringQueries.length === 0;
      const isInFirstPage = state.currentPage === 0;

      if (isOfCurrentCompany && isInFirstPage) {
        state.byID.splice(0, 0, invoiceInfo.customerID);
      }
    },
    receivedInvoices(
      state,
      {
        payload: { invoices, pages },
      }: PayloadAction<{
        readonly invoices: readonly schema.Invoice[];
        readonly pages: number;
      }>,
    ) {
      for (const invoice of invoices) {
        state.data[invoice.objectID] = invoice;
      }
      state.byID = invoices.map((i) => i.objectID);
      state.pages = pages;
    },
    receivedSingleInvoice(
      state,
      {
        payload: { invoice },
      }: PayloadAction<{
        invoice: schema.Invoice;
      }>,
    ) {
      state.data[invoice.customerID] = invoice;
    },
    setInvoiceUsers(
      state,
      {
        payload: { invoiceUsers },
      }: PayloadAction<{ invoiceUsers: schema.InvoiceUsers }>,
    ) {
      state.invoiceUsers = invoiceUsers;
    },
    sortedBy: (
      state,
      {
        payload: { sortQuery },
      }: PayloadAction<{
        sortQuery: schema.SortQuery<schema.Invoice>;
      }>,
    ) => {
      state.byID = [];
      state.currentPage = 1;
      state.sortQueries.push(sortQuery);
    },
  },
});

export const {
  actions: {
    clearedFilters,
    clearedSort,
    filteredColumn,
    setInvoiceUsers,
    sortedBy,
  },
  reducer: invoices,
} = invoicesSlice;

export const requestedInvoiceCreation = createAction<{
  readonly data: {
    readonly company: schema.InvoiceCompany;
    readonly customerID: string;
    readonly efficiencyInfo: readonly schema.InvoiceEfficiencyInfo[];
  };
}>(`${PREFIX}/requestedInvoiceCreation`);

export const subToSingleInvoice = createAction<{
  customerID: string;
}>(`${PREFIX}/subToSingleInvoice`);

export const unSubFromSingleInvoice = createAction<{ customerID: string }>(
  `${PREFIX}/unSubFromSingleInvoice`,
);

//#region selectors
interface GlobalState {
  [PREFIX]: InvoicesState;
}

export const selectAllInvoices =
  () =>
  ({ invoices: { data } }: GlobalState): schema.Invoices =>
    data;

export const selectCurrentInvoicesPage =
  () =>
  ({ invoices: { byID, data } }: GlobalState): readonly schema.Invoice[] =>
    // TODO: use re-select
    // Cast, if it's in byID, it's in data
    byID.map((id) => data[id] as schema.Invoice);

export const selectInvoice =
  (customerID: string) =>
  ({ invoices }: GlobalState): schema.Invoice | null =>
    invoices.data[customerID] || null;

export const selectCurrentSortQueries = ({
  invoices: { sortQueries: sortQuery },
}: GlobalState) => sortQuery;

export const selectCurrentFilteringQueries = ({
  invoices: { filteringQueries },
}: GlobalState) => filteringQueries;

export const selectPages = ({ invoices: { pages } }: GlobalState) => pages;

export const selectInvoiceUsers = ({
  invoices: { invoiceUsers },
}: GlobalState) => invoiceUsers;

//#endregion selectors

// #region fetching

interface ResponseSearch {
  readonly invoices: readonly schema.Invoice[];
  readonly pages: number;
}

const invoicesIndex = api.searchClient.initIndex('invoices');

const getAlgoliaSearch = async ({
  invoices,
}: GlobalState): Promise<ResponseSearch> => {
  const { filteringQueries, currentPage } = invoices;

  let filterString = '';

  filteringQueries.forEach((filter, i) => {
    if (i === 0) {
      filterString = `${filter.column}:${filter.query}`;
    }
    if (i > 0) {
      filterString += ` AND ${filter.column}:${filter.query}`;
    }
  });

  const { hits, nbPages } = await invoicesIndex.search('', {
    filters: filterString,
    page: currentPage,
  });

  const sanitizedHits = hits.map((hit) => {
    const {
      company,
      customerID,
      date,
      efficiencyInfo,
      invoiceNumber,
      lastErr,
      objectID,
      sort_key,
      status,
    } = hit as schema.Invoice;

    const invoice: schema.Invoice = {
      company,
      customerID,
      date,
      efficiencyInfo,
      invoiceNumber,
      lastErr,
      objectID,
      sort_key,
      status,
    };

    return invoice;
  });

  return {
    invoices: sanitizedHits,
    pages: nbPages,
  };
};

function* invoicesFetcher() {
  const state: GlobalState = yield E.select();

  const searchResponse: ResponseSearch = yield E.call(getAlgoliaSearch, state);

  const { invoices, pages } = searchResponse;

  yield E.put(
    invoicesSlice.actions.receivedInvoices({
      invoices,
      pages,
    }),
  );
}

//#region singleInvoice

type InvoiceChannelPayload = schema.Invoice | '$$__DELETED__' | Error;

const createInvoiceChannel = (customerID: string) =>
  eventChannel<InvoiceChannelPayload>((emit) => {
    const ref = api.getDatabase().ref(`Invoices/${customerID}`);

    ref.on('value', (data) => {
      const value = data.val() || null;

      if (schema.isInvoice(value)) {
        emit(value);
      } else if (value === null) {
        emit('$$__DELETED__');
      } else {
        const errMsg = `createInvoiceChannel() -> expected Invoice got: ${JSON.stringify(
          value,
        )}`;
        utils.log(errMsg);
        emit(new TypeError(errMsg));
      }
    });

    return () => {
      ref.off();
    };
  });

function* subToInvoiceSaga(customerID: string) {
  const customerChan: YieldReturn<typeof createInvoiceChannel> = yield E.call(
    createInvoiceChannel,
    customerID,
  );

  try {
    while (true) {
      const invoice: InvoiceChannelPayload = yield E.take(customerChan);

      if (invoice instanceof Error) {
        const e = invoice;
        // Fast solution ideally errors in here should be rare
        yield E.put(
          invoicesSlice.actions.receivedSingleInvoice({
            invoice: {
              company: 'tello',
              customerID,
              date: 0,
              efficiencyInfo: '[]',
              invoiceNumber: 0,
              lastErr: e.message,
              objectID: customerID,
              sort_key: 0,
              status: 'error',
            },
          }),
        );
      } else {
        if (invoice === '$$__DELETED__') {
          yield E.put(
            invoicesSlice.actions.deletedInvoice({
              customerID,
            }),
          );
        } else {
          yield E.put(
            invoicesSlice.actions.receivedSingleInvoice({
              invoice,
            }),
          );
        }
      }
    }
  } finally {
    if ((yield E.cancelled()) as boolean) {
      customerChan.close();
    }
  }
}

function* invoiceSubWatcher() {
  while (true) {
    const action: ReturnType<typeof subToSingleInvoice> = yield E.take(
      subToSingleInvoice,
    );

    const {
      payload: { customerID },
    } = action;

    const invoiceSubTask: SagaTask = yield E.fork(subToInvoiceSaga, customerID);

    yield E.take(unSubFromSingleInvoice);

    yield E.cancel(invoiceSubTask);
  }
}

//#endregion singleInvoice

// #endregion fetching

function* requestedInvoiceCreationWatcher({
  payload: {
    data: { company, customerID, efficiencyInfo },
  },
}: ReturnType<typeof requestedInvoiceCreation>) {
  const invoice: schema.Invoice = {
    company,
    customerID,
    date: utils.normalizeTimestampToMs(Date.now()),
    efficiencyInfo: JSON.stringify(efficiencyInfo),
    invoiceNumber: 0,
    lastErr: '',
    objectID: customerID,
    sort_key: -utils.normalizeTimestampToMs(Date.now()),
    status: 'pending',
  };

  yield E.put(
    invoicesSlice.actions.invoiceCreated({
      invoice,
    }),
  );

  yield E.put(
    invoicesSlice.actions.beganInvoiceUpload({
      customerID,
    }),
  );

  const invoiceRef = api.getDatabase().ref(`Invoices`).child(customerID);

  yield E.call(async () => {
    // TODO: Check if closure is affected here
    await invoiceRef.set({
      ...invoice,
      status: 'uploaded',
    });
  });
}

const createInvoiceUsersChannel = () =>
  eventChannel((emit) => {
    const ref = api.getDatabase().ref('Misc/InvoiceUsers');

    ref.on('value', (data) => {
      try {
        emit((data.val() || utils.EMPTY_OBJ) as schema.InvoiceUsers);
      } catch (e) {
        const errMessage = utils.processErr(e);
        utils.log(`createInvoiceUsersChannel() -> value -> ${errMessage}`);
        utils.log(e);
      }
    });

    return () => {
      ref.off();
    };
  });

function* invoiceUsersSaga() {
  const invoiceUsers: YieldReturn<typeof createInvoiceUsersChannel> =
    yield E.call(createInvoiceUsersChannel);

  try {
    while (true) {
      const payload: schema.InvoiceUsers = yield E.take(invoiceUsers);

      yield E.put(
        setInvoiceUsers({
          invoiceUsers: payload,
        }),
      );
    }
  } finally {
    if ((yield E.cancelled()) as boolean) {
      invoiceUsers.close();
    }
  }
}

export function* invoicesSaga() {
  if (env.IS_MAIN) {
    yield E.all([
      E.takeEvery(requestedInvoiceCreation, requestedInvoiceCreationWatcher),
      E.takeEvery(
        ['persist/REHYDRATE', ...utils.keys(invoicesSlice.actions)],
        invoicesFetcher,
      ),
      E.call(invoiceSubWatcher),
      E.call(invoiceUsersSaga),
    ]);
  }
}
