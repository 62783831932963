import React from 'react';

const calcTimeLeft = (t: number) => {
  if (!t) return 0;

  const left = t - new Date().getTime();

  if (left < 0) return 0;

  return left;
};

export default function useCountDown(endTime: number) {
  const [end, setEnd] = React.useState(endTime);

  const [timeLeft, setTimeLeft] = React.useState(() => calcTimeLeft(end));

  React.useEffect(() => {
    setTimeLeft(calcTimeLeft(end));

    const timer = setInterval(() => {
      const targetLeft = calcTimeLeft(end);
      setTimeLeft(targetLeft);

      if (targetLeft === 0) {
        clearInterval(timer);
      }
    });

    return () => clearInterval(timer);
  }, [end]);

  return [timeLeft, setEnd];
}
