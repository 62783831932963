import React from 'react';

import * as common from '../../../common';

import * as Mui from '@mui/material';

import useCountDown from '../../hooks/useCountDown/useCountDown';

export interface ToastAlertProps {
  duration: number;
  handleClose: () => void;
  message?: string;
  onClick?: () => void;
  open: boolean;
  severity: Mui.AlertColor;
  stopTime?: boolean;
  type: 'alert' | 'button' | 'undoButton';
}

export default React.memo<ToastAlertProps>(function ToastAlert({
  duration,
  handleClose,
  message,
  onClick,
  open,
  severity,
  stopTime,
  type,
}) {
  const undoButton = React.useMemo(
    () => (
      <UndoButton
        onClick={onClick || common.EMPTY_FN}
        stopTime={stopTime}
        time={duration}
      />
    ),
    [duration, onClick, stopTime],
  );

  const actionButton = React.useMemo(
    () => (
      <Mui.Button onClick={onClick} sx={sx['button']}>
        {message}
      </Mui.Button>
    ),
    [message, onClick],
  );

  if (type === 'alert' && message) {
    return (
      <Mui.Snackbar
        autoHideDuration={duration}
        onClose={handleClose}
        open={open}
        sx={sx['snackbar']}
      >
        <Mui.Alert onClose={handleClose} severity={severity} sx={sx['alert']}>
          {message}
        </Mui.Alert>
      </Mui.Snackbar>
    );
  }
  if (type === 'undoButton' && typeof onClick === 'function' && duration) {
    return (
      <Mui.Snackbar
        action={undoButton}
        autoHideDuration={duration}
        message={message}
        onClose={handleClose}
        open={open}
        sx={sx['snackbar']}
      />
    );
  }

  if (type === 'button' && typeof onClick === 'function' && message) {
    return (
      <Mui.Snackbar
        action={actionButton}
        autoHideDuration={duration}
        message={message}
        onClose={handleClose}
        open={open}
        sx={sx['snackbar']}
      />
    );
  }

  return null;
});

const sx = {
  alert: {
    fontSize: '18px',
    width: '298px',
  },
  button: {
    fontSize: '18px',
    width: '298px',
  },
  message: {
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  snackbar: {
    textTransform: 'capitalize',
    '&:MuiSnackbarContent-message': {
      fontSize: '18px !important',
    },
  },
};

interface UndoButtonProps {
  onClick: () => void;
  stopTime: boolean | undefined;
  time: number;
}

const UndoButton = React.memo<UndoButtonProps>(
  ({ onClick, stopTime, time }) => {
    const handleClick = React.useCallback(() => {
      onClick();
    }, [onClick]);

    const endTime = stopTime ? 0 : new Date().getTime() + time;

    const [timeLeft] = useCountDown(endTime);

    const seconds = React.useMemo(
      () => Math.floor((timeLeft as number) / 1000) % 60,
      [timeLeft],
    );

    return (
      <Mui.Button color="inherit" size="small" onClick={handleClick}>
        {/* <Mui.Typography component="span" sx={sx['message']} variant="inherit">
          {message}
        </Mui.Typography>{' '} */}
        {seconds} UNDO
      </Mui.Button>
    );
  },
);
