import React, { forwardRef, useMemo, useState } from 'react';
import * as rn from 'react-native';

import * as c from '../../../common';
import * as r from '../../../react-utils';
import {
  Customer,
  SelectCustomerFieldParams,
  ValueOf,
  companyOptions,
  selectClosers,
  selectSetters,
  makeSelectCustomerField,
  processErr,
  updateCustomer,
  useSelector,
} from '../../../common';

import { FullscreenDataProvider } from './FullscreenData';

// import auth from '@react-native-firebase/auth'
import AntDesign from 'react-native-vector-icons/AntDesign';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import type { NavigationProp } from '@react-navigation/native';

import * as cp from '../../components';
import * as gs from '../../gStyles';
import Checkbox from '../../components/Checkbox';
import Pad from '../../components/Pad';
import ModalMpuImage from '../../components/ModalMpuImage';
import View from '../../components/View';

import MediaGroup from './MediaGroup';

export interface CustomerInfoProps {
  /**
   * An empty string will represent an "is adding" state.
   */
  customerKey: string;
  hide: boolean;
  readonly inputsEditable?: boolean;
  readonly shouldShowAsterisks?: boolean;
  readonly navigation?: NavigationProp<{}>;

  closerWhenAdding?: string;
  onChangeCloserWhenAdding?: (text: string) => void;
  customerNameWhenAdding?: string;
  onChangeCustomerNameWhenAdding?: (text: string) => void;
  customerAddressWhenAdding?: string;
  onChangeCustomerAddressWhenAdding?: (text: string) => void;
  customerPhoneWhenAdding?: string;
  onChangeCustomerPhoneWhenAdding?: (text: string) => void;
  customerPhoneAltWhenAdding?: string;
  onChangeCustomerPhoneAltWhenAdding?: (text: string) => void;
  solarCompanyWhenAdding?: string;
  onChangeSolarCompanyWhenAdding?: (text: string) => void;
  setterWhenAdding?: string;
  onChangeSetterWhenAdding?: (text: string) => void;

  readonly loading?: boolean;
  readonly onSaveWhenAdding?: c.VoidFn;
}
type CIP = CustomerInfoProps;

type RefType = rn.FlatList | rn.ScrollView;
const CustomerInfoForwarded = forwardRef<RefType, CIP>(function CustomerInfo(
  {
    customerKey: customerID,
    hide,
    inputsEditable,
    navigation,
    shouldShowAsterisks,

    closerWhenAdding,
    onChangeCloserWhenAdding,
    customerNameWhenAdding,
    onChangeCustomerNameWhenAdding,
    customerAddressWhenAdding,
    onChangeCustomerAddressWhenAdding,
    customerPhoneWhenAdding,
    onChangeCustomerPhoneWhenAdding,
    customerPhoneAltWhenAdding,
    onChangeCustomerPhoneAltWhenAdding,
    solarCompanyWhenAdding,
    onChangeSolarCompanyWhenAdding,
    setterWhenAdding,
    onChangeSetterWhenAdding,

    loading,
    onSaveWhenAdding,
  }: CustomerInfoProps,
  ref,
): React.ReactElement {
  //#region styling
  const colorScheme = rn.useColorScheme() || 'light';
  const styles = gs.useThemedStyleSheet(themedStyles);
  const t = r.useTheme();
  const gStyles = gs.useGlobalStyles('canvas');
  const rowGap = gs.useRowGap('canvas');
  //#endregion styling
  const [showModalMpuImage, setShowModalMpuImage] = useState<boolean>(false);

  const closers = useSelector(selectClosers());
  const setters = useSelector(selectSetters());

  // #region currCustomerName
  const selectCustomerField = useMemo(makeSelectCustomerField, []);
  const selectCustomerFieldArgs = useMemo(
    (): SelectCustomerFieldParams => ({
      customerID,
      field: 'customerName' as const,
    }),
    [customerID],
  );
  // Remember to subscribe to the customer elsewhere!
  const currCustomerName = useSelector(
    (_): ValueOf<Customer> => selectCustomerField(_, selectCustomerFieldArgs),
  );
  // #endregion currCustomerName
  // #region currSolarCompany
  const selectCustomerFieldSolarCompany = useMemo(makeSelectCustomerField, []);
  const selectCustomerFieldArgsSolarCompany = useMemo(
    (): SelectCustomerFieldParams => ({
      customerID,
      field: 'solarCompany' as const,
    }),
    [customerID],
  );
  // Remember to subscribe to the customer elsewhere!
  const currSolarCompany = useSelector(
    (_): ValueOf<Customer> =>
      selectCustomerFieldSolarCompany(_, selectCustomerFieldArgsSolarCompany),
  );
  // #endregion currSolarCompany

  // TODO: Remove short-circuit logic.
  const isAdding = !customerID;
  const [predictions] = r.usePredictions(
    customerID,
    customerAddressWhenAdding || '',
    process.env['REACT_APP_G_API_KEY']!,
  );

  const handleAuditorReportPress = React.useCallback((): void => {
    rn.Linking.openURL(
      `https://${c.IS_MAIN ? 'www' : c.CODENAME}.${
        c.REACT_APP_BASE_URL
      }/customers/${customerID}/auditor`,
    ).catch((e): void => {
      const errMsg = processErr(e);
      console.log(`Could not open auditor report link: ${errMsg}`);
      console.log(e);
    });
  }, [customerID]);
  const handleCloserCalcPress = React.useCallback((): void => {
    // @ts-expect-error
    navigation?.navigate('CloserCalc', { customerKey: customerID });
  }, [customerID, navigation]);

  const handleCustomerReportPress = React.useCallback((): void => {
    rn.Linking.openURL(
      `https://${c.IS_MAIN ? 'www' : c.CODENAME}.${
        c.REACT_APP_BASE_URL
      }/customers/${customerID}/customer`,
    ).catch((e): void => {
      const errMsg = processErr(e);
      console.log(`Could not open website link: ${errMsg}`);
      console.log(e);
    });
  }, [customerID]);

  const handleCustomerMpuRule = React.useCallback((): void => {
    setShowModalMpuImage(true);
  }, []);
  const onRequestClose = React.useCallback((): void => {
    setShowModalMpuImage(false);
  }, []);

  const [isAdmin] = useState(false);

  const [willDeleteSignatureDate, setWillDeleteSignatureDate] = useState(false);
  const toggleWillDeleteSignatureDate = React.useCallback((): void => {
    setWillDeleteSignatureDate(
      (currentlyChecked): boolean => !currentlyChecked,
    );
  }, []);

  const handleDeleteSignature = React.useCallback((): void => {
    if (rn.Platform.OS === 'ios') {
      rn.Alert.prompt(
        'Really delete signature?',
        "Type the Customer's name to confirm",
        (text): void => {
          if (Boolean(currCustomerName) && text === currCustomerName) {
            updateCustomer(
              customerID,
              willDeleteSignatureDate
                ? {
                    customer_signature: '',
                    release_liability_date: 0,
                  }
                : {
                    customer_signature: '',
                  },
            );
          }
        },
      );
    }
  }, [currCustomerName, customerID, willDeleteSignatureDate]);

  const handleDeleteCustomer = React.useCallback((): void => {
    rn.Alert.alert('Really delete customer?', '', [
      { text: 'No', style: 'cancel' },
      {
        text: 'Yes',
        style: 'destructive',
        onPress: () => {
          updateCustomer(customerID, { deleted: true });
          navigation?.goBack();
        },
      },
    ]);
  }, [customerID, navigation]);

  const [copied, setCopied] = React.useState(false);

  const handleCustomerIDPress = React.useCallback((): void => {
    rn.Clipboard.setString(customerID);

    setCopied(true);

    setTimeout((): void => {
      setCopied(false);
    }, 2000);
  }, [customerID]);

  if (isAdding) {
    const showPredictions =
      predictions.length > 0 &&
      !predictions.find((p) => p.label === customerAddressWhenAdding);

    const IsAddingRoot = rn.Platform.OS === 'web' ? rn.View : rn.ScrollView;

    return (
      <IsAddingRoot
        style={rn.Platform.OS === 'web' ? gStyles.content : styles.rootPadded}
      >
        {rowGap}
        <cp.Picker
          disabled={!inputsEditable}
          label={c.fieldToLabel.homeRep}
          on="canvas"
          onChange={onChangeCloserWhenAdding}
          opts={closers}
          value={closerWhenAdding!}
        />
        {rowGap}
        <cp.Picker
          disabled={!inputsEditable}
          label={c.getFieldLabel('solarRep')}
          on="canvas"
          onChange={onChangeSetterWhenAdding}
          opts={setters}
          value={setterWhenAdding}
        />
        {rowGap}
        <cp.TextInput
          clearButtonMode="while-editing"
          editable={inputsEditable}
          label={c.getFieldLabel('customerName')}
          labelShowAsterisk={
            shouldShowAsterisks && customerNameWhenAdding?.length === 0
          }
          on="canvas"
          onChangeText={onChangeCustomerNameWhenAdding}
          value={customerNameWhenAdding}
        />
        {rowGap}
        <cp.TextInput
          editable={inputsEditable}
          elementLeftBlurred={
            cp.inputDecorations[colorScheme].canvas.countryCode
          }
          elementLeftFocused={
            cp.inputDecorations[colorScheme].canvas.countryCode
          }
          keyboardType="phone-pad"
          label={c.getFieldLabel('customerPhone')}
          labelShowAsterisk={
            shouldShowAsterisks && customerPhoneWhenAdding?.length !== 10
          }
          mask={cp.masks.phone}
          on="canvas"
          onChangeText={onChangeCustomerPhoneWhenAdding}
          shrink
          value={customerPhoneWhenAdding}
        />
        {rowGap}
        <cp.TextInput
          editable={inputsEditable}
          elementLeftBlurred={
            cp.inputDecorations[colorScheme].canvas.countryCode
          }
          elementLeftFocused={
            cp.inputDecorations[colorScheme].canvas.countryCode
          }
          label={c.getFieldLabel('customerPhoneAlt')}
          keyboardType="phone-pad"
          mask={cp.masks.phone}
          on="canvas"
          onChangeText={onChangeCustomerPhoneAltWhenAdding}
          shrink
          value={customerPhoneAltWhenAdding}
        />
        {rowGap}
        <cp.TextInput
          clearButtonMode="while-editing"
          editable={inputsEditable}
          label={c.getFieldLabel('customerAddress')}
          labelShowAsterisk={
            shouldShowAsterisks && customerAddressWhenAdding?.length === 0
          }
          on="canvas"
          onChangeText={onChangeCustomerAddressWhenAdding}
          shrink={false}
          value={customerAddressWhenAdding}
        />
        {rowGap}
        <cp.Picker
          disabled={!inputsEditable}
          inline
          label={c.getFieldLabel('customerAddress')}
          labelShowAsterisk={
            shouldShowAsterisks && customerAddressWhenAdding?.length === 0
          }
          on="canvas"
          onChange={onChangeCustomerAddressWhenAdding}
          open={showPredictions && inputsEditable}
          opts={predictions}
        />
        {rowGap}
        <cp.Picker
          disabled={!inputsEditable}
          grow
          label={c.getFieldLabel('solarCompany')}
          labelShowAsterisk={
            shouldShowAsterisks && solarCompanyWhenAdding === ''
          }
          on="canvas"
          opts={companyOptions}
          onChange={onChangeSolarCompanyWhenAdding}
          value={solarCompanyWhenAdding}
        />
        {rowGap}
        {onSaveWhenAdding && (
          <rn.View style={styles.createBtnWrapper}>
            {loading ? (
              <rn.ActivityIndicator color={t.accent} size="large" />
            ) : (
              <cp.Button on="canvas" onPress={onSaveWhenAdding} shrink>
                Create Customer
              </cp.Button>
            )}
          </rn.View>
        )}

        {/**
         * This is so the user can scroll a bit more on devices to account for
         * the bottom bar or to simply have the last item closer to the center of
         * the screen which is more ergonomic.
         */}
        {rn.Platform.OS !== 'web' && gs.rowGap256}
      </IsAddingRoot>
    );
  }

  return (
    <>
      <FullscreenDataProvider>
        <rn.ScrollView
          // @ts-expect-error
          ref={ref}
          style={hide ? gStyles.displayNone : styles.root}
        >
          {rowGap}

          <MediaGroup
            customerID={customerID}
            ofWhat="owner_house"
            on="canvas"
          />

          <rn.View style={gStyles.content}>
            <rn.View style={gStyles.rowSpaceBetween100}>
              <rn.View style={gs.width48}>
                <cp.Moment
                  customerID={customerID}
                  field="createdAt"
                  format={c.readableDateFormat}
                  on="paper"
                  // shrink
                />
              </rn.View>

              <rn.View style={gs.width48}>
                <cp.Moment
                  alignRight
                  customerID={customerID}
                  field="sort_key"
                  format={c.readableDateFormat}
                  on="paper"
                  // shrink
                />
              </rn.View>
            </rn.View>
            {rowGap}
            <cp.ConnectedPicker
              customerID={customerID}
              field="homeRep" // Closer
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="customerName"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="customerAddress"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="customerPhone"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="customerPhoneAlt"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="customerEmail"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="ssn"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedPicker
              customerID={customerID}
              field="solarCompany"
              on="canvas"
            />
            {rowGap}
            {c.getCompanyState(currSolarCompany as string) === 'california' && (
              <>
                <cp.ConnectedPicker
                  customerID={customerID}
                  field="main_panel_upgrade_installation_company"
                  on="canvas"
                />
                {rowGap}
              </>
            )}
            <cp.ConnectedPicker
              customerID={customerID}
              field="solarRep" // Setter
              on="canvas"
            />
            {rowGap}
            {rowGap}
            <rn.View style={gStyles.separatorH} />
            {rowGap}
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="yearHomeBuilt"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="attic_square_footage"
              on="canvas"
            />
            {rowGap}
            <cp.ConnectedInput
              customerID={customerID}
              field="globalNotes"
              on="canvas"
            />
            {rowGap}
            {rowGap}
            <rn.View style={gStyles.separatorH} />
            {rowGap}
            {rowGap}
            <rn.View>
              <rn.TouchableOpacity onPress={handleCustomerIDPress}>
                <View style={styles.link}>
                  <cp.Text on="canvas">Customer ID</cp.Text>

                  <cp.Text on="canvas">{customerID}</cp.Text>

                  <Pad amt={8} horizontal />

                  <AntDesign
                    color={t.canvas.color as string}
                    name={copied ? 'check' : 'copy1'}
                    size={18}
                  />
                </View>
              </rn.TouchableOpacity>
              {rowGap}
              <rn.TouchableOpacity onPress={handleAuditorReportPress}>
                <View style={styles.link}>
                  <cp.Text style={styles.href}>Go To Auditor report</cp.Text>

                  <EvilIcons color={t.href} name="external-link" size={24} />
                </View>
              </rn.TouchableOpacity>
              {rowGap}
              <rn.TouchableOpacity onPress={handleCloserCalcPress}>
                <View style={styles.link}>
                  <cp.Text style={styles.href}>Commission Calculator</cp.Text>
                </View>
              </rn.TouchableOpacity>
              {rowGap}
              {c.IS_MAIN && currSolarCompany !== 'affordable' && (
                <>
                  <rn.TouchableOpacity onPress={handleCustomerReportPress}>
                    <View style={styles.link}>
                      <cp.Text style={styles.href}>
                        Go To Customer report
                      </cp.Text>

                      <EvilIcons
                        color={t.href}
                        name="external-link"
                        size={24}
                      />
                    </View>
                  </rn.TouchableOpacity>
                  {rowGap}
                </>
              )}

              <rn.TouchableOpacity onPress={handleCustomerMpuRule}>
                <rn.View style={styles.link}>
                  <cp.Text style={styles.href}>Mpu rule</cp.Text>

                  <EvilIcons color={t.href} name="external-link" size={24} />
                </rn.View>
              </rn.TouchableOpacity>

              {rowGap}

              <rn.TouchableOpacity onPress={handleDeleteCustomer}>
                <View style={styles.link}>
                  <cp.Text style={styles.href}>Delete Customer</cp.Text>
                  <EvilIcons color={t.danger} name="trash" size={24} />
                </View>
              </rn.TouchableOpacity>

              {c.IS_MAIN && isAdmin && (
                <>
                  {rowGap}
                  {rowGap}
                  <rn.View style={gStyles.separatorH} />
                  {rowGap}
                  {rowGap}
                  <rn.TouchableOpacity onPress={handleDeleteSignature}>
                    <View style={styles.link}>
                      <cp.Text style={styles.href}>
                        Delete Signature (iPhone only)
                      </cp.Text>

                      <EvilIcons color={t.danger} name="trash" size={24} />
                    </View>
                  </rn.TouchableOpacity>
                  {rowGap}
                  {rowGap}
                  <Checkbox
                    checked={willDeleteSignatureDate}
                    label="Also delete signature date"
                    on="canvas"
                    onPress={toggleWillDeleteSignatureDate}
                  />
                </>
              )}
            </rn.View>
          </rn.View>

          {/**
           * This is so the user can scroll a bit more on devices to account for
           * the bottom bar or to simply have the last item closer to the center of
           * the screen which is more ergonomic.
           */}
          <Pad amt={160} />
        </rn.ScrollView>
        <ModalMpuImage
          onRequestClose={onRequestClose}
          visible={showModalMpuImage}
        />
      </FullscreenDataProvider>
    </>
  );
});

const themedStyles = gs.ThemedStyleSheet.create((t) => {
  const root = {
    flexGrow: 1,
    backgroundColor: t.canvas.backgroundColor,
    // paddingHorizontal: t.canvas.paddingHorizontal,
  };
  return {
    /* Avoids jump when switching from button to spinner */
    createBtnWrapper: { ...gs.deadCenter, height: 64, width: '100%' },
    link: { ...gs.row, paddingHorizontal: t.canvas.paddingHorizontal },
    root,
    rootPadded: { ...root, padding: t.canvas.gap },
    href: { color: t.href, fontFamily: t.fontFamily },
  };
});

export default React.memo(CustomerInfoForwarded);
