import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import MaskInput, { Mask } from 'react-native-mask-input';

import * as appUtils from '../utils';
import * as gs from '../gStyles';

import Labeled from './Labeled';
import Text from './Text';

export type TextInputProps = c.r<{
  elementLeftBlurred?: React.ReactElement;
  elementLeftFocused?: React.ReactElement;
  guideline?: string;
  guidelineType?: c.Guideline['type'];
  hide?: boolean;
  label?: string | false;
  labelIconRight?: React.ReactElement;
  labelShowAsterisk?: boolean;
  mask?: Mask;
  on: c.Surface;
  onPressElementLeft?(): void;
  shrink?: boolean;
  warn?: boolean;
}> &
  rn.TextInputProps;

const each = 56;
const cleanBtnHitSlop = { left: each, top: each, bottom: each, right: each };
const cleanIconSize = 16;

const screenHeight = rn.Dimensions.get('window').height;

/**
 * Text inputs do not grow by default in web, they do in RN, ours will grow by
 * default (e.g. has width: 100%).
 */
const TextInputForwarded = React.forwardRef<
  rn.TextInput | undefined,
  TextInputProps
>(function TextInput(
  {
    elementLeftBlurred,
    elementLeftFocused,
    guideline,
    guidelineType,
    hide,
    label = '',
    labelIconRight,
    labelShowAsterisk,
    mask,
    on,
    onPressElementLeft,
    shrink,
    warn,
    // Native passthrough props
    clearButtonMode,
    editable = true,
    multiline,
    onChangeText,
    value,
    ...restProps
  },
  ref,
) {
  //#region ref
  const inputRef = React.useRef<rn.TextInput>();
  React.useImperativeHandle(ref, () => inputRef.current as rn.TextInput);
  //#endregion ref
  //#region focus
  const [focused, setFocused, toggleFocused] = r.useBool(false);
  const handleFocus = React.useCallback((): void => {
    setFocused(true);
    if (!multiline || !appUtils.flatListSalesRef.current) return;

    const scrollOffset = appUtils.flatListOffsetSales;

    inputRef.current?.measureInWindow((_x, y, _width, height) => {
      const offset = y + scrollOffset - screenHeight / 2 + height / 2;
      appUtils.flatListSalesRef.current?.scrollToOffset({
        offset,
        animated: true,
      });
    });
  }, [multiline, setFocused]);
  //#endregion focus

  //#region clear
  const willRenderClearBtn =
    editable &&
    clearButtonMode !== 'never' &&
    rn.Platform.OS === 'android' &&
    ((clearButtonMode === 'while-editing' && focused) ||
      (clearButtonMode === 'unless-editing' && !focused) ||
      clearButtonMode === 'always');
  const handleClear = React.useCallback(
    () => void onChangeText?.(''),
    [onChangeText],
  );
  //#endregion clear

  //#region styling
  const t = r.useTheme();
  const styles = gs.useThemedStyleSheet(themedStyles, on);

  const isWrapped = Boolean(
    elementLeftBlurred || elementLeftFocused || label || willRenderClearBtn,
  );
  const inputStyle = (() => {
    if (isWrapped || !shrink) {
      if (!editable) {
        return multiline
          ? styles.inputMultilineDisabled
          : elementLeftBlurred || elementLeftFocused
          ? styles.inputDisabledElLeft
          : styles.inputDisabled;
      }
      if (multiline) {
        return focused
          ? styles.inputMultilineFocused
          : warn
          ? styles.inputMultilineWarn
          : styles.inputMultiline;
      }
      if (elementLeftBlurred || elementLeftFocused) {
        return focused
          ? styles.inputElementLeftFocused
          : warn
          ? styles.inputElementLeftWarn
          : styles.inputElementLeft;
      }
      return focused
        ? styles.inputFocused
        : warn
        ? styles.inputWarn
        : styles.input;
    }

    if (multiline) {
      if (!editable) {
        return styles.inputMultilineShrinkDisabled;
      }
      return focused
        ? styles.inputMultilineShrinkFocused
        : warn
        ? styles.inputMultilineShrinkWarn
        : styles.inputMultilineShrink;
    }
    if (elementLeftBlurred || elementLeftFocused) {
      return focused
        ? styles.inputShrinkElementLeftFocused
        : warn
        ? styles.inputShrinkElementLeftWarn
        : styles.inputShrinkElementLeft;
    }
    if (!editable) return styles.inputShrinkDisabled;
    return focused
      ? styles.inputShrinkFocused
      : warn
      ? styles.inputShrinkWarn
      : styles.inputShrink;
  })();
  //#endregion styling

  const handleChangeTextMask = React.useCallback(
    (_: string, unmasked: string): void => {
      onChangeText?.(unmasked);
    },
    [onChangeText],
  ) as rn.TextInputProps['onChangeText'];
  const InputRenderer = mask ? MaskInput : rn.TextInput;
  let txtNodeInput = (
    <InputRenderer
      mask={mask as Mask}
      onBlur={toggleFocused}
      onFocus={handleFocus}
      placeholderTextColor={t.input[on].placeholderColor}
      ref={inputRef as any}
      scrollEnabled
      style={inputStyle}
      // Native passthrough props
      clearButtonMode={clearButtonMode}
      editable={editable}
      multiline={multiline}
      onChangeText={mask ? handleChangeTextMask : onChangeText}
      value={value || ''}
      {...restProps}
    />
  );

  const ElementLeftWrapper = onPressElementLeft ? rn.TouchableOpacity : rn.View;

  if (elementLeftBlurred || elementLeftFocused || willRenderClearBtn) {
    txtNodeInput = (
      <rn.View
        style={(() => {
          if (label) return gs.inputGrow;
          return shrink ? styles.innerWrapperShrink : styles.innerWrapper;
        })()}
      >
        {txtNodeInput}

        {(elementLeftBlurred || elementLeftFocused) && (
          <>
            {/* @ts-ignore */}
            <ElementLeftWrapper
              onPress={onPressElementLeft}
              style={styles.elementLeft}
            >
              <Switch
                a={elementLeftBlurred || r.empty}
                b={elementLeftFocused || r.empty}
                current={focused ? 'b' : 'a'}
              />
            </ElementLeftWrapper>
          </>
        )}

        {willRenderClearBtn && !!value && (
          <rn.TouchableOpacity
            hitSlop={cleanBtnHitSlop}
            onPress={handleClear}
            style={styles.btnCleanAndroid}
          >
            <Icon
              color={t.input[on].highlight}
              name="close"
              size={cleanIconSize}
            />
          </rn.TouchableOpacity>
        )}
      </rn.View>
    );
  }

  if (label) {
    txtNodeInput = (
      <Labeled
        grow={!shrink}
        guideline={guideline}
        guidelineType={guidelineType}
        hide={hide}
        iconRight={labelIconRight}
        label={label}
        on={on}
        showAsterisk={labelShowAsterisk}
      >
        {txtNodeInput}
      </Labeled>
    );
  }

  return txtNodeInput;
});

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const input = { ...t.input[on], ...gs.inputGrow, fontWeight: '400' } as const;
  const inputFocused = { ...input, borderColor: t[on].highlight };
  const inputWarning = { ...input, borderColor: t.warning };
  const inputShrink = { ...input, width: c.fieldWidthWeb };
  const inputShrinkFocused = { ...inputShrink, borderColor: t[on].highlight };
  const inputShrinkWarning = {
    ...inputShrink,
    borderColor: t.warning,
    width: c.fieldWidthWeb,
  };

  const inputMultiline = {
    ...input,
    paddingTop: t[on].gap,
    height: 160,
    width: '100%',
  };
  const inputMultilineFocused = {
    ...inputMultiline,
    borderColor: t[on].highlight,
  };
  const inputMultilineShrink: rn.ViewStyle = {
    ...inputMultiline,
    width: c.fieldWidthWeb,
  };

  const btnCleanAndroid = {
    ...gs.deadCenter,
    // backgroundColor: '#09A',
    borderRadius: t[on].borderRadius,
    position: 'absolute',
    paddingHorizontal: t.input[on].paddingHorizontal,
    top: 0,
    right: 0,
    height: '100%',
  } as const;

  return {
    btnCleanAndroid,
    iconRight: {
      ...gs.deadCenter,
      height: '100%',
      position: 'absolute',
      paddingHorizontal: 24,
      right: 0,
      top: 0,
    },
    innerWrapper: {
      ...gs.grow,
      marginHorizontal: r.isMobile ? t[on].paddingHorizontal : undefined,
    },
    innerWrapperShrink: {
      ...gs.rowCentered,
      borderRadius: t.input[on].borderRadius,
      width: c.fieldWidthWeb,
      backgroundColor: '#919',
    },
    input,
    inputFocused,
    inputDisabled: { ...input, ...gs.disabled },
    inputDisabledElLeft: { ...input, ...gs.disabled, paddingLeft: 64 },
    inputWarn: inputWarning,
    inputElementLeft: { ...input, paddingLeft: 64 },
    inputElementLeftFocused: { ...inputFocused, paddingLeft: 64 },
    inputElementLeftWarn: { ...inputWarning, paddingLeft: 64 },
    inputShrink,
    inputShrinkDisabled: { ...inputShrink, ...gs.disabled },
    inputShrinkFocused,
    inputShrinkWarn: inputShrinkWarning,
    inputShrinkElementLeft: { ...inputShrink, paddingLeft: 64 },
    inputShrinkElementLeftFocused: { ...inputShrinkFocused, paddingLeft: 64 },
    inputShrinkElementLeftWarn: { ...inputShrinkWarning, paddingLeft: 64 },
    inputMultiline,
    inputMultilineDisabled: { ...inputMultiline, ...gs.disabled },
    inputMultilineFocused,
    inputMultilineWarn: { ...inputMultiline, borderColor: t.warning },
    inputMultilineShrink,
    inputMultilineShrinkDisabled: { ...inputMultilineShrink, ...gs.disabled },
    inputMultilineShrinkFocused: {
      ...inputMultilineShrink,
      borderColor: t[on].highlight,
    },
    inputMultilineShrinkWarn: {
      ...inputMultilineShrink,
      borderColor: t.warning,
    },
    outerWrapper: {
      borderRadius: t.input[on].borderRadius,
      width: c.fieldWidthWeb,
    },
    elementLeft: {
      ...gs.deadCenter,
      borderRadius: t.input[on].borderRadius,
      borderWidth: 1,
      borderColor: 'transparent',
      height: '100%',
      left: 0,
      paddingHorizontal: 24,
      position: 'absolute',
      top: 0,
      // backgroundColor: '#9c1',
    },
  };
});
//#region decorations
export const callOnCanvasInputDark = (
  <MIcon color={c.dark.input.canvas.highlight} name="add-ic-call" size={22} />
);
export const callOnCanvasInputLight = (
  <MIcon color={c.light.input.canvas.highlight} name="add-ic-call" size={22} />
);
export const callOnPaperInputDark = (
  <MIcon color={c.dark.input.paper.highlight} name="add-ic-call" size={22} />
);
export const callOnPaperInputLight = (
  <MIcon color={c.light.input.paper.highlight} name="add-ic-call" size={22} />
);

export const pinOnCanvasInputDark = (
  <MCIcon
    color={c.dark.input.canvas.highlight}
    name="map-marker-radius"
    size={24}
  />
);
export const pinOnCanvasInputLight = (
  <MCIcon
    color={c.light.input.canvas.highlight}
    name="map-marker-radius"
    size={24}
  />
);
export const pinOnPaperInputDark = (
  <MCIcon
    color={c.dark.input.paper.highlight}
    name="map-marker-radius"
    size={24}
  />
);
export const pinOnPaperInputLight = (
  <MCIcon
    color={c.light.input.paper.highlight}
    name="map-marker-radius"
    size={24}
  />
);
export const pinDisabledOnCanvasInputDark = (
  <MCIcon
    color={c.dark.input.canvas.disabled}
    name="map-marker-radius"
    size={24}
  />
);
export const pinDisabledOnCanvasInputLight = (
  <MCIcon
    color={c.light.input.canvas.disabled}
    name="map-marker-radius"
    size={24}
  />
);
export const pinDisabledOnPaperInputDark = (
  <MCIcon
    color={c.dark.input.paper.disabled}
    name="map-marker-radius"
    size={24}
  />
);
export const pinDisabledOnPaperInputLight = (
  <MCIcon
    color={c.light.input.paper.highlight}
    name="map-marker-radius"
    size={24}
  />
);

const countryCodeOnCanvasInputDark = (
  <Text
    style={{
      borderRadius: c.dark.input.canvas.borderRadius,
      // borderWidth: c.dark.input.canvas.borderWidth,
      color: c.dark.input.canvas.color,
      fontFamily: c.dark.input.canvas.fontFamily,
    }}
  >
    🇺🇸 +1
  </Text>
);
const countryCodeOnCanvasInputLight = (
  <Text
    style={{
      borderRadius: c.light.input.canvas.borderRadius,
      // borderWidth: c.dark.input.canvas.borderWidth,
      color: c.light.input.canvas.color,
      fontFamily: c.light.input.canvas.fontFamily,
    }}
  >
    🇺🇸 +1
  </Text>
);
const countryCodeOnPaperInputDark = (
  <Text
    style={{
      borderRadius: c.dark.input.paper.borderRadius,
      // borderWidth: c.dark.input.paper.borderWidth,
      color: c.dark.input.paper.color,
      fontFamily: c.dark.input.paper.fontFamily,
    }}
  >
    🇺🇸 +1
  </Text>
);
const countryCodeOnPaperInputLight = (
  <Text
    style={{
      borderRadius: c.light.input.paper.borderRadius,
      // borderWidth: c.light.input.paper.borderWidth,
      color: c.light.input.paper.color,
      fontFamily: c.light.input.paper.fontFamily,
    }}
  >
    🇺🇸 +1
  </Text>
);

const chainOnCanvasInputDark = (
  <FontAwesome
    color={c.dark.input.canvas.placeholderColor}
    name="chain"
    size={24}
  />
);
const chainOnCanvasInputLight = (
  <FontAwesome
    color={c.light.input.canvas.placeholderColor}
    name="chain"
    size={24}
  />
);
const chainOnPaperInputDark = (
  <FontAwesome
    color={c.dark.input.canvas.placeholderColor}
    name="chain"
    size={24}
  />
);
const chainOnPaperInputLight = (
  <FontAwesome
    color={c.light.input.canvas.placeholderColor}
    name="chain"
    size={24}
  />
);

const chainBrokenOnCanvasInputDark = (
  <FontAwesome
    color={c.dark.input.canvas.placeholderColor}
    name="chain-broken"
    size={24}
  />
);
const chainBrokenOnCanvasInputLight = (
  <FontAwesome
    color={c.light.input.canvas.placeholderColor}
    name="chain-broken"
    size={24}
  />
);
const chainBrokenOnPaperInputDark = (
  <FontAwesome
    color={c.dark.input.canvas.placeholderColor}
    name="chain-broken"
    size={24}
  />
);
const chainBrokenOnPaperInputLight = (
  <FontAwesome
    color={c.light.input.canvas.placeholderColor}
    name="chain-broken"
    size={24}
  />
);

const emptyDecors = {
  call: r.empty,
  chain: r.empty,
  chainBroken: r.empty,
  countryCode: r.empty,
  locked: r.empty,
  pin: r.empty,
  pinDisabled: r.empty,
  unlocked: r.empty,
} as const;

export const inputDecorations = {
  dark: {
    backdrop: emptyDecors,
    canvas: {
      call: callOnCanvasInputDark,
      chain: chainOnCanvasInputDark,
      chainBroken: chainBrokenOnCanvasInputDark,
      countryCode: countryCodeOnCanvasInputDark,
      locked: r.empty,
      pin: pinOnPaperInputDark,
      pinDisabled: pinDisabledOnCanvasInputDark,
      unlocked: r.empty,
    },
    paper: {
      call: callOnPaperInputDark,
      chain: chainOnPaperInputDark,
      chainBroken: chainBrokenOnPaperInputDark,
      countryCode: countryCodeOnPaperInputDark,
      locked: (
        <FontAwesome
          color={c.dark.input.paper.placeholderColor}
          name="lock"
          size={22}
        />
      ),
      pin: pinOnPaperInputDark,
      pinDisabled: pinDisabledOnPaperInputDark,
      unlocked: (
        <FontAwesome
          color={c.dark.input.paper.placeholderColor}
          name="unlock"
          size={22}
        />
      ),
    },
  },
  light: {
    backdrop: emptyDecors,
    canvas: {
      call: callOnCanvasInputLight,
      chain: chainOnCanvasInputLight,
      chainBroken: chainBrokenOnCanvasInputLight,
      countryCode: countryCodeOnCanvasInputLight,
      locked: r.empty,
      pin: pinOnCanvasInputLight,
      pinDisabled: pinDisabledOnCanvasInputLight,
      unlocked: r.empty,
    },
    paper: {
      call: callOnPaperInputLight,
      chain: chainOnPaperInputLight,
      chainBroken: chainBrokenOnPaperInputLight,
      countryCode: countryCodeOnPaperInputLight,
      locked: (
        <FontAwesome
          color={c.light.input.paper.placeholderColor}
          name="lock"
          size={24}
        />
      ),
      pin: pinOnPaperInputLight,
      pinDisabled: pinDisabledOnPaperInputLight,
      unlocked: (
        <FontAwesome
          color={c.light.input.paper.placeholderColor}
          name="unlock"
          size={24}
        />
      ),
    },
  },
};
//#endregion decorations

export const masks = {
  phone: [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  ssn: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
} as const;

interface SwitchProps {
  readonly a: React.ReactElement;
  readonly b: React.ReactElement;
  readonly current: 'a' | 'b';
}
const Switch = React.memo<SwitchProps>(({ a, b, current }) =>
  current === 'a' ? a : b,
);

export default React.memo(TextInputForwarded);
