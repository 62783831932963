import React from 'react';
import CreateIcon from '@mui/icons-material/Create';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

export interface EditCustomerButtonProps {
  handleAddCustomerModal: () => void;
  right?: number;
  left?: number;
  bottom?: number;
  top?: number;
}

export default React.memo<EditCustomerButtonProps>(function EditCustomerButton({
  handleAddCustomerModal,
  bottom,
  left,
  right,
  top,
}) {
  const theme = useTheme();
  return (
    <Box
      component={Paper}
      elevation={5}
      sx={{
        position: 'fixed',
        backgroundColor: theme.palette.mode === 'dark' ? '#90caf9' : '#ffff',
        left: left ? left : 'unset',
        right: right ? right : 'unset',
        top: top ? top : 'unset',
        bottom: bottom ? bottom : 'unset',
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        height: { xs: '70px', sm: '90px', md: '100px' },
        justifyContent: 'center',
        width: { xs: '70px', sm: '90px', md: '100px' },
      }}
      onClick={handleAddCustomerModal}
    >
      <CreateIcon
        sx={{ width: { xs: '30px', sm: '45px', md: '60px' }, height: 60 }}
      />
    </Box>
  );
});
