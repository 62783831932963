import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';

import * as gs from '../gStyles';

import Guideline from './Guideline';
import Label from './Label';

export type LabeledProps = c.r<{
  alignRight?: boolean;
  bottomText?: string;
  bottomTextIcon?: 'info';
  children: any;
  danger?: boolean;
  disabled?: boolean;
  grow?: boolean;
  guideline?: false | string;
  guidelineType?: c.Guideline['type'];
  hide?: boolean;
  iconRight?: React.ReactElement;
  label: string;
  on: c.Surface;
  onPress?: c.VoidFn | false;
  onPressLabelOnly?: boolean;
  showAsterisk?: boolean;
}>;

export default React.memo<LabeledProps>(function Labeled({
  alignRight,
  children,
  danger,
  disabled,
  grow,
  guideline,
  guidelineType,
  hide,
  iconRight,
  label,
  on,
  onPress,
  onPressLabelOnly,
  showAsterisk,
}) {
  const Wrapper =
    onPress && !onPressLabelOnly && !disabled ? rn.TouchableOpacity : rn.View;

  return (
    // @ts-expect-error
    <Wrapper
      onPress={!disabled && onPress}
      style={
        grow
          ? hide
            ? gs.inputGrowNewHidden
            : gs.inputGrowNew
          : hide
          ? gs.inputShrinkNewHidden
          : gs.inputShrinkNew
      }
    >
      <Label
        alignRight={alignRight}
        danger={danger}
        disabled={disabled}
        iconRight={iconRight}
        on={on}
        onPress={onPressLabelOnly && onPress}
        showAsterisk={showAsterisk}
      >
        {label}
      </Label>

      {onPress ? (
        <rn.TouchableOpacity onPress={onPress}>
          {labeledGap}
        </rn.TouchableOpacity>
      ) : (
        labeledGap
      )}

      {!!alignRight && <rn.View style={gs.alignItemsEnd}>{children}</rn.View>}
      {!alignRight && children}

      {guideline && (
        <Guideline on={on} type={guidelineType}>
          {guideline}
        </Guideline>
      )}
    </Wrapper>
  );
});

export const labeledGap = gs.rowGap16;
