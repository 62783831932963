import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import moment from 'moment';

import Picker from './Picker';
import TextInput, {
  TextInputProps,
  inputDecorations,
  masks,
} from './TextInput';

export type ConnectedInputProps = c.r<{
  customerID: string;
  field: c.CustomerField;
}> &
  TextInputProps;

export default React.memo<ConnectedInputProps>(function ConnectedInput({
  customerID,
  field,
  // TextInput props
  on,
  ...restProps
}) {
  //#region styling
  const colorScheme = rn.useColorScheme() || 'light';
  const inputDecors = inputDecorations[colorScheme][on];
  const willRenderInsidePicker =
    field === 'customerAddress' && rn.Platform.OS === 'web';
  const isCostAndMobile = field.endsWith('cost') && r.isMobile;
  const shouldGrow =
    willRenderInsidePicker || c.fieldToGrow[field] || isCostAndMobile;
  //#endregion styling
  const textInput = React.useRef<rn.TextInput | null | undefined>();

  const [admins] = r.useSetting<c.DictB<boolean>>('admins');
  const [userID] = r.useSetting<string>('isAuth');
  const [customer] = r.useCustomer(customerID);
  const [data, setData] = r.useCustomerField(customerID, field);

  //#region address
  const [predictions, handlePredictionSelect] = r.usePredictions(
    customerID,
    field === 'customerAddress' ? (data as string) : '',
    process.env['REACT_APP_G_API_KEY']!,
  );
  const predictionAlreadySelected = !!predictions.find((o) => o.label === data);
  const predictionsVisible =
    !predictionAlreadySelected && predictions.length > 0;

  const [addressLat] = r.useCustomerField(customerID, 'addressLat');
  const [addressLng] = r.useCustomerField(customerID, 'addressLng');

  const handleMapPinPress = React.useCallback(async (): Promise<void> => {
    r.openMapsAt(addressLat, addressLng);
  }, [addressLat, addressLng]);

  const hasCoordinates = addressLat !== 0 && addressLng !== 0;
  //#endregion address
  //#region phone
  const handleCall = React.useCallback(() => {
    rn.Linking.openURL(`tel:${data}`);
  }, [data]);
  const isPhone = field === 'customerPhone' || field === 'customerPhoneAlt';
  const phoneNumberWhole = isPhone && (data as string).length === 10;
  //#endregion phone

  //#region cost
  const handleUnlockCost = React.useCallback(() => {
    const isAdmin = admins[userID];
    if (isAdmin) {
      const efficiency = field.replace('_cost', '');
      c.updateCustomer(customerID, {
        has_touched_cost: { [efficiency]: true },
      });
    } else {
      rn.ToastAndroid.show(
        'Only admins can unlock the cost',
        rn.ToastAndroid.LONG,
      );
    }
  }, [admins, customerID, field, userID]);
  const handleLockCost = React.useCallback(() => {
    const efficiency = field.replace('_cost', '');
    c.updateCustomer(customerID, { has_touched_cost: { [efficiency]: false } });
  }, [customerID, field]);

  const handleLinkCustomerCost = React.useCallback(() => {
    const efficiency = field.replace('_customer_cost', '');
    c.updateCustomer(customerID, {
      has_touched_customer_cost: { [efficiency]: false },
    });
  }, [customerID, field]);
  const handleUnlinkCustomerCost = React.useCallback(() => {
    const efficiency = field.replace('_customer_cost', '');
    c.updateCustomer(customerID, {
      has_touched_customer_cost: {
        [efficiency]: true,
      },
    });
  }, [customerID, field]);

  const costElLeft = (() => {
    const isUnlocked = customer.has_touched_cost[field.replace('_cost', '')];
    return isUnlocked ? inputDecors.unlocked : inputDecors.locked;
  })();
  const costOnPressElLeft = (() => {
    const isUnlocked = customer.has_touched_cost[field.replace('_cost', '')];
    return isUnlocked ? handleLockCost : handleUnlockCost;
  })();

  const customerCostElLeft = (() => {
    const isUnlinked =
      customer.has_touched_customer_cost[field.replace('_customer_cost', '')];

    return isUnlinked ? inputDecors.chainBroken : inputDecors.chain;
  })();
  const customerCostOnPressElLeft = (() => {
    const isUnlinked =
      customer.has_touched_customer_cost[field.replace('_customer_cost', '')];
    return isUnlinked ? handleLinkCustomerCost : handleUnlinkCustomerCost;
  })();
  //#endregion cost

  //#region local
  const guideline = c.getFieldGuideline(customer, field);
  const label = c.getFieldLabel(field, customer);
  const keyboardType = ((): rn.KeyboardType => {
    if (field.endsWith('_cost')) return 'decimal-pad';
    if (isPhone) return 'phone-pad';
    if (field === 'attic_fan_quantity') return 'decimal-pad';
    if (field === 'attic_square_footage') return 'decimal-pad';
    if (field === 'attic_insulation_how_much') return 'decimal-pad';
    if (field === 'cash_amount') return 'decimal-pad';
    if (field === 'customerEmail') return 'email-address';
    if (field === 'duct_repair_quantity') return 'decimal-pad';
    if (field === 'flat_roof_panels_how_many') return 'decimal-pad';
    if (field === 'glow_power_output_mw') return 'decimal-pad';
    if (field === 'glow_price_per_watt') return 'decimal-pad';
    if (field === 'panel_removal_how_many') return 'decimal-pad';
    if (field === 'roof_fascia_included_square_footage') return 'decimal-pad';
    if (field === 'roof_patio_how_many') return 'decimal-pad';
    if (field === 'roof_plywood_replaced_square') return 'decimal-pad';
    if (field === 'roof_square_footage') return 'decimal-pad';
    if (field === 'smart_thermostat_how_many') return 'decimal-pad';
    if (field === 'ssn') return 'number-pad';
    if (field === 'yearHomeBuilt') return 'number-pad';

    return 'default';
  })();
  const handleTextChange = React.useCallback(
    (newText: string) => {
      if (keyboardType === 'number-pad' || keyboardType === 'decimal-pad') {
        setData(newText.replace('-', '').replace('+', '').replace(',', ''));
      } else {
        setData(newText);
      }
    },
    [keyboardType, setData],
  );
  //#endregion local

  const txtNode = (
    <TextInput
      autoCapitalize={(() => {
        if (field === 'customerAddress') return 'words';
        if (field === 'customerName') return 'words';
        if (field === 'new_windows_replace_custom') return 'none';
        if (field.endsWith('notes')) {
          return 'sentences';
        }
        return 'sentences';
      })()}
      autoCompleteType={(() => {
        if (field.endsWith('email')) return 'email';
        return 'off';
      })()}
      autoCorrect={(() => {
        if (field === 'new_windows_replace_custom') return true;
        if (field.endsWith('notes')) return true;

        return false;
      })()}
      clearButtonMode="while-editing"
      editable={(() => {
        if (field.endsWith('_customer_cost')) {
          const efficiency = field.replace(
            '_customer_cost',
            '',
          ) as c.CustomerField;
          return !!customer.has_touched_customer_cost[efficiency];
        }
        if (field.endsWith('_cost')) {
          const efficiency = field.replace('_cost', '') as c.CustomerField;
          const isAutoCost = c.efficiencyToCalculator[efficiency];
          if (!isAutoCost) return true;
          return !!customer.has_touched_cost[efficiency];
        }
        return c.shouldEnableInput(field);
      })()}
      elementLeftBlurred={(() => {
        if (field === 'customerAddress') {
          return hasCoordinates ? inputDecors.pin : inputDecors.pinDisabled;
        }
        if (isPhone) {
          if ((data as string).length !== 10) return inputDecors.countryCode;
          return inputDecors.call;
        }
        if (field.endsWith('_customer_cost')) return customerCostElLeft;
        if (field.endsWith('_cost')) {
          const efficiency = field.replace('_cost', '') as c.CustomerField;
          const isAutoCost = c.efficiencyToCalculator[efficiency];
          if (isAutoCost) return costElLeft;
        }

        return undefined;
      })()}
      elementLeftFocused={(() => {
        if (field === 'customerAddress') {
          return hasCoordinates ? inputDecors.pin : inputDecors.pinDisabled;
        }
        if (isPhone) return inputDecors.countryCode;
        if (field.endsWith('_customer_cost')) return customerCostElLeft;
        if (field.endsWith('_cost')) {
          const efficiency = field.replace('_cost', '') as c.CustomerField;
          const isAutoCost = c.efficiencyToCalculator[efficiency];
          if (isAutoCost) return costElLeft;
        }
        return undefined;
      })()}
      guideline={guideline?.text}
      guidelineType={guideline?.type}
      hide={c.shouldHideInput(field, customer)}
      keyboardType={keyboardType}
      label={!willRenderInsidePicker && label}
      mask={(() => {
        if (isPhone) return masks.phone;
        if (field === 'ssn') return masks.ssn;
        return undefined;
      })()}
      maxLength={(() => {
        if (isPhone) return 14;
        if (field === 'attic_square_footage') return 4;
        if (field === 'attic_insulation_how_much') return 4;
        if (field === 'roof_square_footage') return 4;
        if (field === 'yearHomeBuilt') return 4;
        if (field === 'yearHomeYearBuilt') return 4;
        return undefined;
      })()}
      multiline={
        field.toLowerCase().endsWith('notes') ||
        field === 'new_windows_replace_custom'
      }
      on={on}
      onChangeText={handleTextChange}
      onPressElementLeft={(() => {
        if (field === 'customerAddress') {
          return hasCoordinates ? handleMapPinPress : undefined;
        }
        if (field === 'customerPhone') {
          return phoneNumberWhole ? handleCall : undefined;
        }
        if (field === 'customerPhoneAlt') {
          return phoneNumberWhole ? handleCall : undefined;
        }
        if (field.endsWith('_customer_cost')) return customerCostOnPressElLeft;
        if (field.endsWith('_cost')) return costOnPressElLeft;
        return undefined;
      })()}
      placeholder={
        field === 'new_windows_replace_custom'
          ? 'living room, kitchen, master bedroom'
          : undefined
      }
      ref={textInput}
      shrink={!shouldGrow}
      value={(() => {
        if (field === 'createdAt') {
          const timestamp = data as number;
          return moment(timestamp).format(c.readableDateFormat);
        }
        if (field === 'sort_key') {
          const timestamp = data as number;
          return moment(timestamp * -1).format(c.readableDateFormatWTime);
        }
        return data as string;
      })()}
      warn={(() => {
        if (field === 'customerPhone') return (data as string).length !== 10;
        if (field === 'customerPhoneAlt') {
          const dataS = data as string;
          return !(dataS.length === 10 || dataS.length === 0);
        }
        return undefined;
      })()}
      {...restProps}
    />
  );

  if (willRenderInsidePicker) {
    return (
      <Picker
        childRef={textInput}
        grow={c.fieldToGrow[field]}
        label={label}
        noModal
        on={on}
        onChange={handlePredictionSelect}
        open={predictionsVisible}
        opts={predictions}
      >
        {txtNode}
      </Picker>
    );
  }

  if (field === 'customerAddress') {
    return (
      <>
        {txtNode}

        {predictionsVisible && (
          <Picker
            inline
            on={on}
            onChange={handlePredictionSelect}
            open={!predictionAlreadySelected && predictions.length > 0}
            opts={predictions}
          />
        )}
      </>
    );
  }

  return txtNode;
});
