import React, { useCallback, useMemo } from 'react';
import * as rn from 'react-native';
import { Alert, View } from 'react-native';

import * as c from '../../../common';
import * as r from '../../../react-utils';

import Feather from 'react-native-vector-icons/Feather';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';

import * as cp from '../../components';
import * as gs from '../../gStyles';
import ModalMpuCalculator from '../../components/ModalMpuCalculator';
import Popover, { PopoverOptionType } from '../../components/Popover';

import ConnectedYesOrNo from '../../components/ConnectedYesOrNo';

import ModalPrice from './ModalPrice';
// import WarningContainer from './WarningContainer'

export interface CheckGroupProps {
  as?: c.Surface;
  customerID: string;
  field: c.CustomerField;
}

const CostWrapper = r.isMobile ? rn.View : React.Fragment;

const CheckGroup = React.memo<CheckGroupProps>(
  ({ as = 'paper', customerID, field: efficiency }) => {
    //#region styling
    const t = r.useTheme();
    const styles = gs.useThemedStyleSheet(themedStyles);
    const gStyles = gs.useGlobalStyles(as);
    const rowGap = gs.useRowGap(as);
    //#endregion styling
    //#region global
    // const efficiencyPrices = c.useSelector(c.selectEfficiencyPrices)
    const [customer] = r.useCustomer(customerID);
    const [yesOrNo] = r.useCustomerField(customerID, efficiency);
    //#endregion global

    //#region local
    const [ctxMenuOpen, , toggleCtxMenuOpen] = r.useBool(false);
    const [priceModalOpen, , togglePriceModalOpen] = r.useBool(false);
    const [mpuCalculatorOpen, , toggleMpuCalculatorOpen] = r.useBool(false);
    const inputs = useMemo((): c.CustomerFields => {
      const _inputs: c.CustomerFields =
        c.efficiencyToInputs[efficiency] ||
        c.fieldToDependents[efficiency] ||
        [];

      return _inputs.filter((inputOrInputs) => {
        if (c.isArray(inputOrInputs)) return true;
        const input = inputOrInputs;
        return c.shouldDisplayInput(input, customer);
      });
    }, [customer, efficiency]);
    const isSubEfficiency = !c.efficiencyToInputs[efficiency];
    //#endregion local

    const showContents = isSubEfficiency
      ? inputs.length > 0
      : yesOrNo === 'yes';

    const handleUpdatePrices = useCallback(async (): Promise<void> => {
      c.updateCustomer(customerID, {
        efficiency_updated: efficiency,
      });
    }, [customerID, efficiency]);

    const onPressResetEfficiency = useCallback(() => {
      if (r.isMobile) {
        Alert.alert(
          `Are you sure to restore efficiency "${c.getFieldLabel(
            efficiency,
          )}"?`,
          '',
          [
            {
              text: 'Yes',
              onPress: () => void c.resetEfficiency(customerID, efficiency),
            },
            { text: 'No' },
          ],
        );
      }
      if (r.isWeb) {
        if (
          // @ts-ignore TODO
          window.confirm(
            `Are you sure to restore efficiency "${c.getFieldLabel(
              efficiency,
            )}"?`,
          )
        ) {
          c.resetEfficiency(customerID, efficiency);
        }
      }
    }, [customerID, efficiency]);

    const handlePopoverSelection = useCallback(
      (type: PopoverOptionType) => {
        if (r.isWeb) toggleCtxMenuOpen();
        switch (type) {
          case 'reset-efficiency':
            return onPressResetEfficiency();
          case 'refresh-price':
            return handleUpdatePrices();
          case 'view-preset-prices':
            return togglePriceModalOpen();
          case 'mpu-calculator':
            return toggleMpuCalculatorOpen();
          default:
            return;
        }
      },
      [
        toggleCtxMenuOpen,
        onPressResetEfficiency,
        handleUpdatePrices,
        togglePriceModalOpen,
        toggleMpuCalculatorOpen,
      ],
    );

    const inputMapper = React.useCallback(
      (
        subField: c.CustomerField,
        i: number,
        { length }: readonly unknown[],
      ) => (
        <React.Fragment key={customerID + subField}>
          <cp.Field customerID={customerID} field={subField} on={as} />
          {i !== length - 1 && rowGap}
        </React.Fragment>
      ),
      [as, customerID, rowGap],
    );

    const hasExtraNotes = Boolean(
      c.CustomerSchema[(efficiency + '_extra_notes') as c.CustomerField],
    );

    const moreIconRef = React.useRef();

    if (c.shouldHideInput(efficiency, customer)) {
      return null;
    }

    return (
      <>
        <rn.View
          accessibilityLabel={efficiency}
          style={as === 'paper' ? gStyles.paper : gStyles.canvas}
        >
          <View style={isSubEfficiency ? styles.cardInner : gStyles.content}>
            <View style={styles.header}>
              <rn.View style={gs.grow}>
                <ConnectedYesOrNo customerID={customerID} field={efficiency} />
              </rn.View>

              {r.isWeb &&
                !isSubEfficiency &&
                !c.efficiencyToCalculator[efficiency] && (
                  <FontAwesome5Icon
                    color={t[as].highlight}
                    name="undo"
                    onPress={onPressResetEfficiency}
                    size={24}
                    style={yesOrNo === 'yes' ? gs.opacity1 : gs.opacity0}
                  />
                )}

              {r.isWeb && c.efficiencyToCalculator[efficiency] && (
                <cp.Select
                  childRef={moreIconRef}
                  // @ts-ignore
                  onChange={handlePopoverSelection}
                  on={as}
                  onRequestClose={toggleCtxMenuOpen}
                  open={ctxMenuOpen}
                  openToTheLeft
                  opts={
                    efficiency === 'main_panel_upgrade'
                      ? ctxMenuOptsMPU
                      : ctxMenuOpts
                  }
                >
                  <rn.View
                    // @ts-ignore
                    ref={moreIconRef}
                    style={gs.alignSelfCenter}
                  >
                    <Feather
                      color={t[as].highlight}
                      name="more-vertical"
                      onPress={toggleCtxMenuOpen}
                      size={24}
                      style={yesOrNo === 'yes' ? gs.opacity1 : gs.opacity0}
                    />
                  </rn.View>
                </cp.Select>
              )}

              {r.isMobile && !isSubEfficiency && (
                <View style={gs.rowSpaceBetween}>
                  <View style={styles.separator} />
                  {gs.colGap8}
                  {rn.Platform.OS !== 'web' && (
                    <Popover
                      customerID={customerID}
                      field={efficiency}
                      onSelection={handlePopoverSelection}
                    />
                  )}
                </View>
              )}
            </View>

            {showContents && (
              <>
                {rowGap}

                {inputs.map(inputMapper)}

                {!isSubEfficiency && (
                  <>
                    {!!inputs.length && (
                      <>
                        {rowGap}
                        {rowGap}
                        <rn.View style={gStyles.separatorH} />
                        {rowGap}
                        {rowGap}
                      </>
                    )}

                    <rn.View
                      style={
                        r.isWeb
                          ? gStyles.contentNoMargin
                          : gStyles.rowSpaceBetween100
                      }
                    >
                      <CostWrapper style={gStyles.width48}>
                        <cp.Field
                          customerID={customerID}
                          field={(efficiency + '_cost') as c.CustomerField}
                          on={as}
                        />
                      </CostWrapper>

                      <CostWrapper style={gStyles.width48}>
                        <cp.Field
                          customerID={customerID}
                          field={
                            (efficiency + '_customer_cost') as c.CustomerField
                          }
                          on={as}
                        />
                      </CostWrapper>
                    </rn.View>

                    {rowGap}

                    <rn.View style={gStyles.contentNoMargin}>
                      <cp.Field
                        customerID={customerID}
                        field={(efficiency + '_notes') as c.CustomerField}
                        on={as}
                      />

                      {hasExtraNotes && (
                        <>
                          {rowGap}
                          <cp.Field
                            customerID={customerID}
                            field={
                              (efficiency + '_extra_notes') as c.CustomerField
                            }
                            on={as}
                          />
                        </>
                      )}
                    </rn.View>
                  </>
                )}
              </>
            )}
          </View>
        </rn.View>

        <ModalPrice
          customer={customer}
          field={efficiency}
          onRequestClose={togglePriceModalOpen}
          title={c.getFieldLabel(efficiency)}
          visible={priceModalOpen}
        />
        <ModalMpuCalculator
          onRequestClose={toggleMpuCalculatorOpen}
          visible={mpuCalculatorOpen}
        />
      </>
    );
  },
);

// const validateCashback = (customer: c.Customer) => {
//   if (!customer) {
//     return false
//   }
//   const cashbackOptions = c.parseCashbackOptions(customer.cash_options)
//   const cashbackTotal = c.getTotalCashback(cashbackOptions, customer)

//   return (
//     Number(customer.cash_amount) === cashbackTotal && !!customer?.cash_amount
//   )
// }

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const cardInner = t[on];

  return {
    actions: { flexDirection: 'row', flexWrap: 'wrap', gap: 8, width: '100%' },
    cardInner,
    center: { alignItems: 'center', justifyContent: 'center' },
    contentWeb: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 1,
      flexWrap: 'wrap',
      gap: t[on].gap,
      justifyContent: 'space-between',
    },
    contentButtons: { justifyContent: 'space-between' },
    header: { ...gs.rowSpaceBetween, width: '100%' },
    separator: { backgroundColor: t[on].separator, width: 1 },
  };
});

const ctxMenuOpts = [
  { label: 'Reset efficiency', value: 'reset-efficiency' },
  { label: 'Refresh prices', value: 'refresh-price' },
  { label: 'View preset prices', value: 'view-preset-prices' },
];

const ctxMenuOptsMPU = [
  ...ctxMenuOpts,
  { label: 'Mpu calculator', value: 'mpu-calculator' },
];

export default CheckGroup;
