import React from 'react';

import notFound from '../../img/Not Found.jpg';
import styles from './NotFound.module.css';

export interface NotFoundProps {}

export default React.memo<NotFoundProps>(function NotFound() {
  return (
    <div className={styles['container']}>
      <img src={notFound} alt="Not Found" />
    </div>
  );
});
