import React from 'react';

export function useMediaQuery() {
  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handler = () => {
    setSize({ height: window.innerHeight, width: window.innerWidth });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return size;
}
