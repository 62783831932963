import React from 'react';

import * as common from '../../../common';

import * as Mui from '@mui/material';

import MediaInput from '../../comps/MediaInput';
import Pad from '../../comps/Pad';

export interface RoofingClaimsProps {
  customerID: string;
}

export default React.memo<RoofingClaimsProps>(function RoofingClaims({
  customerID,
}) {
  return (
    <Mui.Box>
      <Mui.Typography sx={sx['mainTitle']}>Roof Claims</Mui.Typography>
      <Pad amt={24} />

      {mediaList.map((media) => (
        <MediaInput
          customerID={customerID}
          key={media}
          ofWhat={media as common.MediaKind}
        />
      ))}
    </Mui.Box>
  );
});

const sx = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitle: {
    fontSize: '32px',
    fontWeight: 700,
  },
};

const mediaList = Object.keys(common.mediaToLabelRoofClaims);
