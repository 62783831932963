import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import * as schema from '../schema';
import { FireEvent, getDatabase } from '../api';
import { DeepReadonly, log } from '../utils';
import { YieldReturn } from './common';

const PREFIX = 'setters';

type SettersState = DeepReadonly<{
  setters: schema.CloserOrSetter[];
}>;

const initialState: SettersState = {
  setters: [],
};

type SettersChannelPayload = FireEvent<schema.CloserOrSetter>[];

const settersSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    settersAction() {
      // TODO: ??
      // state.setters
    },
    receivedSetters(
      state,
      {
        payload: { setters },
      }: PayloadAction<{ setters: schema.CloserOrSetter[] }>,
    ) {
      state.setters = setters;
    },
  },
});

export const {
  actions: { settersAction, receivedSetters },
  reducer: setters,
} = settersSlice;

//#region selectors

interface GlobalState {
  [PREFIX]: SettersState;
}

export type SelectSetters = (state: GlobalState) => schema.CloserOrSetter[];

export const selectSetters =
  () =>
  ({ setters: { setters } }: GlobalState): schema.CloserOrSetter[] =>
    setters as schema.CloserOrSetter[];

//#endregion selectors

const createSettersChannel = () =>
  eventChannel<SettersChannelPayload | Error>((emit) => {
    const dbRef = getDatabase().ref(`Setters`);

    dbRef.on('value', (data) => {
      emit(data.val() as SettersChannelPayload);
    });

    return () => {
      dbRef.off();
    };
  });

function* settersWatcher() {
  const settersChannel: YieldReturn<typeof createSettersChannel> = yield call(
    createSettersChannel,
  );
  try {
    while (true) {
      const payload: SettersChannelPayload = yield take(settersChannel);
      yield put(
        receivedSetters({
          setters: payload as unknown as schema.CloserOrSetter[],
        }),
      );
    }
  } finally {
    log(`setters sub finally()`);

    if ((yield cancelled()) as boolean) {
      log('setters sub finally() -> cancelled() , will close channel.');
      settersChannel.close();
    }
  }
}

export function* settersSaga() {
  yield all([takeEvery(settersAction, settersWatcher)]);
}
