import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  memo,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface FullscreenData {
  customerID: string | null;
  editing: boolean;
  mediaItemID: string | null;
  mediaKind: string | null;
  autoFocus?: boolean;
}

type FullscreenDataContext = [
  fullscreenData: FullscreenData,
  setFullscreenData: Dispatch<SetStateAction<FullscreenData>>,
];

// We'll follow this rule for future cases.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const FullscreenDataContext = React.createContext<FullscreenDataContext>([
  {
    customerID: null,
    editing: false,
    mediaItemID: null,
    mediaKind: null,
  },
  function setFullscreenData(): void {
    throw new Error(
      `Called default setFullscreenData() from FullscreenData context`,
    );
  },
]);

export const FullscreenDataProvider = memo(({ children }): ReactElement => {
  const [fullscreenData, setFullscreenData] = useState<FullscreenData>({
    customerID: null,
    editing: false,
    mediaItemID: null,
    mediaKind: null,
  });

  const currValue = useMemo(
    (): FullscreenDataContext => [fullscreenData, setFullscreenData],
    [fullscreenData],
  );

  return (
    <FullscreenDataContext.Provider value={currValue}>
      {children}
    </FullscreenDataContext.Provider>
  );
});

export const useFullscreenData = (): FullscreenDataContext =>
  useContext<FullscreenDataContext>(FullscreenDataContext);
