import ElectricalPanel01 from '../ElectricalPanel/Electrical-Panel-01.jpeg';
import ElectricalPanel02 from '../ElectricalPanel/Electrical-Panel-02.jpeg';
import ElectricalPanel03 from '../ElectricalPanel/Electrical-Panel-03.jpeg';
import ElectricalPanel04 from '../ElectricalPanel/Electrical-Panel-04.jpeg';
import ElectricalPanel05 from '../ElectricalPanel/Electrical-Panel-05.jpeg';
import ElectricalPanel06 from '../ElectricalPanel/Electrical-Panel-06.jpeg';
import ElectricalPanel07 from '../ElectricalPanel/Electrical-Panel-07.jpeg';
import ElectricalPanel08 from '../ElectricalPanel/Electrical-Panel-08.jpeg';
import ElectricalPanel09 from '../ElectricalPanel/Electrical-Panel-09.jpeg';

import { ExampleImage } from '..';

export const ELECTRICAL_PANEL_SITE_SURVEY: ExampleImage[] = [
  { label: 'Main Breaker', source: ElectricalPanel01 },
  { label: 'Bottom of Panel', source: ElectricalPanel02 },
  { label: 'Panel showing all Breakers', source: ElectricalPanel03 },
  { label: 'Spec Sheet', source: ElectricalPanel04 },
  { label: 'Top of Panel', source: ElectricalPanel05 },
  { label: 'Wiring of Panel', source: ElectricalPanel06 },
  { label: 'Delidded Panel', source: ElectricalPanel07 },
  { label: 'Whole Panel', source: ElectricalPanel08 },
  { label: 'Panel zoom out', source: ElectricalPanel09 },
];
