import { createSlice } from '@reduxjs/toolkit';

import { normalizeThumbnail } from '../helpers';
import { DeepReadonly } from '../utils';

import {
  receivedCustomersFirebaseEvents,
  receivedSingleCustomerFirebaseEvents,
} from './customers';
import { receivedMediaFBEvents, requestedMediaUpload } from './media';

const PREFIX = 'thumbnails';

export type ThumbnailsState = DeepReadonly<Record<string, string>>;

export const { reducer: thumbnails } = createSlice({
  name: PREFIX,
  initialState: {} as ThumbnailsState,
  reducers: {},
  extraReducers(builder) {
    return builder
      .addCase(
        receivedCustomersFirebaseEvents,
        (thumbnails, { payload: { events } }) => {
          for (const event of events) {
            // TODO: In the future records might will come with the thumbnail removed
            thumbnails[event.key] = event.item.owner_house || '';
          }
        },
      )
      .addCase(
        receivedSingleCustomerFirebaseEvents,
        (thumbnails, { payload: { customerID, updates } }) => {
          for (const { field, value } of updates) {
            if (field === 'owner_house') {
              // TODO: In the future records might will come with the thumbnail
              // removed
              thumbnails[customerID] = (value as string) || '';
            }
          }
        },
      )
      .addCase(receivedMediaFBEvents, (thumbnails, { payload: { events } }) => {
        for (const {
          key,
          item: { thumbnail },
        } of events) {
          // TODO: In the future records might will come with the thumbnail
          // removed
          thumbnails[key] = thumbnail || '';
        }
      })
      .addCase(requestedMediaUpload, (thumbnails, { payload: { items } }) => {
        for (const { name: mediaItemID, thumbnail } of items) {
          // TODO: In the future records might will come with the thumbnail
          // removed
          thumbnails[mediaItemID] = thumbnail || '';
        }
      });
  },
});

//#region selectors

interface GlobalState {
  [PREFIX]: ThumbnailsState;
}

export const selectThumbnail =
  (customerID: string, customerOrMediaItemID: string) =>
  ({ thumbnails }: GlobalState): string =>
    normalizeThumbnail(
      customerID,
      thumbnails[customerOrMediaItemID || ''] || '',
    );

export const selectPlainThumbnail =
  (customerOrMediaItemID: string) =>
  ({ thumbnails }: GlobalState): string =>
    thumbnails[customerOrMediaItemID || ''] || '';

//#endregion selectors
