import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import { FireEvent, getDatabase } from '../api';
import { SalePrices, SaleTag } from '../schema';
import { DeepReadonly, log } from '../utils';
import { YieldReturn } from './common';

const PREFIX = 'efficiencyPrices';

type EfficiencyPricesState = DeepReadonly<{
  prices: SaleTag;
}>;

const initialState: EfficiencyPricesState = {
  prices: {} as SaleTag,
};

type EfficiencyPricesChannelPayload = FireEvent<SaleTag>[];

const efficiencyPricesSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    efficiencyPricesAction() {
      // TODO: ??
      // state.prices
    },
    receivedEfficiencyPrices(
      state,
      { payload: { prices } }: PayloadAction<{ prices: SaleTag }>,
    ) {
      state.prices = prices;
    },
  },
});

export const {
  actions: { efficiencyPricesAction, receivedEfficiencyPrices },
  reducer: efficiencyPrices,
} = efficiencyPricesSlice;

//#region selectors

interface GlobalState {
  [PREFIX]: EfficiencyPricesState;
}

export const selectEfficiencyPrice =
  (saleTag: string) =>
  ({ efficiencyPrices: { prices } }: GlobalState): SalePrices | undefined => {
    if (!saleTag || !prices) {
      return;
    }
    const jsonData: SaleTag = prices as SaleTag;
    // @ts-ignore
    return jsonData[saleTag] as SalePrices;
  };

export const selectEfficiencyPrices = ({
  efficiencyPrices: { prices },
}: GlobalState): DeepReadonly<SaleTag> => {
  return prices;
};

//#endregion selectors
let pricesLastUpdated = -1;

const createEfficiencyPricesChannel = () =>
  eventChannel<EfficiencyPricesChannelPayload | Error>((emit) => {
    // console.log('Prices channel created')
    const lastUpdatedRef = getDatabase()
      .ref('Env')
      .child('efficiencyPricesLastUpdate');
    const pricesRef = getDatabase().ref(`EfficiencyPrices`);

    lastUpdatedRef.on('value', async (data) => {
      const lastUpdated = (data.val() as number) || 0;

      // Connection unstable, check your internet connection

      if (lastUpdated > pricesLastUpdated) {
        pricesLastUpdated = lastUpdated;

        // TODO: Frequent updates will break this due to race conditions but
        // these updates aren't too frequent
        // console.log('Obtaining from EfficiencyPrices')
        emit(
          (
            await pricesRef.once('value')
          ).val() as EfficiencyPricesChannelPayload,
        );
      }
    });

    return () => {
      console.log('Prices channel tear down');
      lastUpdatedRef.off();
    };
  });

function* efficiencyPricesWatcher() {
  const efficiencyChannel: YieldReturn<typeof createEfficiencyPricesChannel> =
    yield call(createEfficiencyPricesChannel);
  try {
    while (true) {
      const payload: EfficiencyPricesChannelPayload = yield take(
        efficiencyChannel,
      );
      yield put(
        receivedEfficiencyPrices({ prices: payload as unknown as SaleTag }),
      );
    }
  } finally {
    log(`efficiency sub finally()`);

    if ((yield cancelled()) as boolean) {
      log('efficiency sub finally() -> cancelled() , will close channel.');
      efficiencyChannel.close();
    }
  }
}

export function* efficiencyPriceSaga() {
  yield all([takeEvery(efficiencyPricesAction, efficiencyPricesWatcher)]);
}
