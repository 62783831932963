import React from 'react';
import * as rn from 'react-native';

import ImageResizer, { Response } from 'react-native-image-resizer';
import { ImageOrVideo } from 'react-native-image-crop-picker';
import { PhotoFile } from 'react-native-vision-camera';

const isAndroid = rn.Platform.OS === 'android';

export const flatListSalesRef = React.createRef<rn.FlatList>();
export let flatListOffsetSales: number = 0;
export const setFlatListOffsetSales = (offset: number) =>
  (flatListOffsetSales = offset);

export const resizeImage = async (
  res: ImageOrVideo | PhotoFile,
  rotation?: number,
): Promise<Response> => {
  try {
    const maxEdge = 2048;
    const isHorizontal = res.width > res.height;
    const longEdge = isHorizontal ? res.width : res.height;
    const ratio = maxEdge / longEdge;
    const newWidth = isHorizontal ? maxEdge : Math.round(res.width * ratio);
    const newHeight = !isHorizontal ? maxEdge : Math.round(res.height * ratio);

    const quality = isAndroid ? 30 : 20;

    const resCompress = await ImageResizer.createResizedImage(
      res.path,
      newWidth,
      newHeight,

      'JPEG',
      quality,
      rotation || 0,
      undefined,
      false,
    );

    return resCompress;
  } catch (e) {
    throw new TypeError(`Unknown resize image ${e}`);
  }
};

// Weird cast?
export const emptyTextInput = (<rn.TextInput />) as unknown as rn.TextInput;

export const useLayout = () => {
  const [layout, setLayout] = React.useState<rn.LayoutRectangle>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const handleLayoutEvent = React.useCallback(
    ({ nativeEvent: { layout } }: rn.LayoutChangeEvent): void => {
      setLayout(layout);
    },
    [],
  );

  return [layout, handleLayoutEvent] as const;
};
