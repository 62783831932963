import React from 'react';
import {
  StyleProp,
  StyleSheet,
  useColorScheme,
  View,
  ViewStyle,
} from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import DropDownPicker from 'react-native-dropdown-picker';

import * as gs from '../gStyles';

import ConnectedInput from './ConnectedInput';
import Text from './Text';

export interface CashbackProps {
  customerID: string;
  field: keyof c.Customer;
}

export default React.memo<CashbackProps>(function Cashback({
  customerID,
  field,
}): React.ReactElement {
  const colorScheme = useColorScheme();
  const t = r.useTheme();
  const rowGap = gs.useRowGap('paper');

  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  const hasPressed = React.useRef<boolean>(false);

  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  );
  const selectCustomerArgs = React.useMemo((): c.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      };
    } else {
      return {
        customerID: '',
      };
    }
  }, [customerID]);
  const customer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  );

  const cashbackOptions = React.useMemo(
    (): c.Opts => c.parseCashbackOptions(customer.cash_options),
    [customer],
  );

  const dropDownValueSelected = React.useMemo(
    (): string[] =>
      cashbackOptions.length
        ? cashbackOptions.map((option): string => option.value)
        : [],
    [cashbackOptions],
  );

  const cashbackTotal = React.useMemo(
    (): number => c.getTotalCashback(cashbackOptions, customer),
    [cashbackOptions, customer],
  );

  const cashbackShowError = React.useMemo((): boolean => {
    for (const cashbackOption of cashbackOptions) {
      const value = customer[
        cashbackOption.value as keyof c.Customer
      ] as string;
      if (!value) {
        return true;
      }
    }
    return false;
  }, [cashbackOptions, customer]);

  const hideCashbackWarning = React.useMemo(() => {
    return (
      Number(customer.cash_amount) === cashbackTotal ||
      cashbackShowError ||
      !customer.cash_amount ||
      !cashbackOptions.length
    );
  }, [
    cashbackOptions.length,
    cashbackShowError,
    cashbackTotal,
    customer.cash_amount,
  ]);

  const styleCashBackWarning = React.useMemo(
    (): StyleProp<ViewStyle> => ({
      backgroundColor: t.warning,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      display: hideCashbackWarning ? 'none' : 'flex',
      paddingHorizontal: 10,
      paddingVertical: '5%',
    }),
    [hideCashbackWarning, t.warning],
  );

  const styleContainerCashBackWarning = React.useMemo(
    (): StyleProp<ViewStyle> => ({
      borderColor: t.warning,
      borderRadius: 15,
      borderWidth: hideCashbackWarning ? 0 : 2.5,
    }),
    [hideCashbackWarning, t.warning],
  );

  const dropdownStyle = React.useMemo(
    (): StyleProp<ViewStyle>[] => [
      colorScheme === 'dark' ? styles.inputDark : styles.input,
      error && inputError,
    ],
    [colorScheme, error],
  );

  const flatListProps = React.useMemo(() => {
    return {
      contentContainerStyle:
        colorScheme === 'dark' && styles.containerDropDownStyleDark,
      nestedScrollEnabled: true,
      style: colorScheme === 'dark' && styles.containerDropDownStyleDark,
    };
  }, [colorScheme]);

  const formattedSelectedItems = React.useCallback((): c.Opt[] => {
    const itemsSelected = c
      .cashBackOptions()
      .map((cashBackOption): c.Opt | null => {
        const index = selectedValues.findIndex(
          (value): boolean => value === cashBackOption.value,
        );
        if (index > -1) {
          return { ...cashBackOption };
        }
        return null;
      })
      .filter((cashBackOption): boolean => cashBackOption !== null) as c.Opt[];
    return itemsSelected;
  }, [selectedValues]);

  const onFocusInput = (): void => {
    if (!open) {
      return;
    }
    setOpen(false);
  };

  const onPressSetValue = (
    value: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    hasPressed.current = true;
    const newValue = value as unknown as string[];
    setSelectedValues(newValue);
  };

  const onChangeSelector = React.useCallback((): void => {
    if (!hasPressed.current) {
      return;
    }
    const cashOptions = formattedSelectedItems();

    c.updateCustomer(customerID, {
      cash_options: JSON.stringify(cashOptions),
    });
    setError(!cashOptions.length);
  }, [customerID, formattedSelectedItems]);

  React.useEffect((): void => {
    onChangeSelector();
  }, [onChangeSelector]);

  const onClose = React.useCallback((): void => {
    const noValue = !selectedValues.length;
    setError(c.fieldShouldBeFilled(field) && noValue);
  }, [field, selectedValues]);

  return (
    <>
      <ConnectedInput customerID={customerID} field={field} on="paper" />

      {rowGap}

      {!!customer.cash_amount && (
        <DropDownPicker
          dropDownDirection="TOP"
          flatListProps={flatListProps}
          items={c.cashBackOptions()}
          labelStyle={
            colorScheme === 'dark'
              ? styles.containerDropDownStyleDark
              : styles.containerDropDownStyle
          }
          listItemContainerStyle={
            colorScheme === 'dark' && styles.containerDropDownStyleDark
          }
          mode="BADGE"
          multiple={true}
          placeholder="Cashback Efficiencies"
          setOpen={setOpen}
          setValue={onPressSetValue}
          theme={colorScheme === 'dark' ? 'DARK' : 'LIGHT'}
          onClose={onClose}
          open={open}
          style={dropdownStyle}
          value={dropDownValueSelected}
        />
      )}
      {error && <Text style={styles.textError}>! Empty value</Text>}
      <View style={styleContainerCashBackWarning}>
        <View style={styleCashBackWarning}>
          <Text bold size={16}>
            The amounts entered don't add up to the cashback amount above
          </Text>
        </View>
        <View style={!hideCashbackWarning && styles.contentInputs}>
          {!!customer.cash_amount &&
            cashbackOptions.map(
              (option): React.ReactElement => (
                <React.Fragment key={option.label}>
                  {rowGap}
                  <ConnectedInput
                    customerID={customerID}
                    field={option.value as keyof c.Customer}
                    keyboardType="numeric"
                    on="paper"
                    onFocus={onFocusInput}
                    placeholder="e.g. 4500"
                  />
                </React.Fragment>
              ),
            )}
        </View>
      </View>
    </>
  );
});

const inputBase = {
  backgroundColor: c.light.canvas.backgroundColor,
  borderRadius: 10,
  borderWidth: 0,
  color: c.light.canvas.color,
  fontFamily: c.themeTuple.light.fontFamily,
  minHeight: 40,
  paddingHorizontal: 10,
  paddingVertical: 5,
} as const;

const inputError = {
  borderColor: 'red',
  borderWidth: 1,
} as const;

const styles = StyleSheet.create({
  containerDropDownStyle: {
    backgroundColor: c.light.canvas.backgroundColor,
    color: c.light.canvas.color,
  },
  containerDropDownStyleDark: {
    backgroundColor: c.dark.canvas.backgroundColor,
    color: c.light.canvas.color,
  },
  contentInputs: {
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  input: inputBase,
  inputDark: {
    ...inputBase,
    backgroundColor: c.dark.canvas.backgroundColor,
    color: c.dark.canvas.color,
  },
  textError: {
    color: c.themeTuple.light.danger,
    marginTop: 5,
  },
});
