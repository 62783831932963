import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import * as schema from '../schema';
import { FireEvent, getDatabase } from '../api';
import { DeepReadonly, log } from '../utils';
import { YieldReturn } from './common';

const PREFIX = 'closers';

type ClosersState = DeepReadonly<{
  closers: schema.CloserOrSetter[];
}>;

const initialState: ClosersState = {
  closers: [],
};

type ClosersChannelPayload = FireEvent<schema.CloserOrSetter>[];

const closersSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    closersAction() {
      // TODO: ??
      // state.closers
    },
    receivedClosers(
      state,
      {
        payload: { closers },
      }: PayloadAction<{ closers: schema.CloserOrSetter[] }>,
    ) {
      state.closers = closers;
    },
  },
});

export const {
  actions: { closersAction, receivedClosers },
  reducer: closers,
} = closersSlice;

//#region selectors

interface GlobalState {
  [PREFIX]: ClosersState;
}

export type SelectClosers = (state: GlobalState) => schema.CloserOrSetter[];

export const selectClosers =
  () =>
  ({ closers: { closers } }: GlobalState): schema.CloserOrSetter[] =>
    closers as schema.CloserOrSetter[];

//#endregion selectors

const createClosersChannel = () =>
  eventChannel<ClosersChannelPayload | Error>((emit) => {
    const dbRef = getDatabase().ref(`Closers`);

    dbRef.on('value', (data) => {
      emit(data.val() as ClosersChannelPayload);
    });

    return () => {
      dbRef.off();
    };
  });

function* closersWatcher() {
  const closersChannel: YieldReturn<typeof createClosersChannel> = yield call(
    createClosersChannel,
  );
  try {
    while (true) {
      const payload: ClosersChannelPayload = yield take(closersChannel);
      yield put(
        receivedClosers({
          closers: payload as unknown as schema.CloserOrSetter[],
        }),
      );
    }
  } finally {
    log(`closers sub finally()`);

    if ((yield cancelled()) as boolean) {
      log('closers sub finally() -> cancelled() , will close channel.');
      closersChannel.close();
    }
  }
}

export function* closersSaga() {
  yield all([takeEvery(closersAction, closersWatcher)]);
}
