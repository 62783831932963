import RoofImage01 from '../RoofFromAllPlanes/Roof-from-all-planes-01.jpeg';
import RoofImage02 from '../RoofFromAllPlanes/Roof-from-all-planes-02.jpeg';
import RoofImage03 from '../RoofFromAllPlanes/Roof-from-all-planes-03.jpeg';
import RoofImage04 from '../RoofFromAllPlanes/Roof-from-all-planes-04.jpeg';
import RoofImage05 from '../RoofFromAllPlanes/Roof-from-all-planes-05.jpeg';
import RoofImage06 from '../RoofFromAllPlanes/Roof-from-all-planes-06.jpeg';
import RoofImage07 from '../RoofFromAllPlanes/Roof-from-all-planes-07.jpeg';
import RoofImage08 from '../RoofFromAllPlanes/Roof-from-all-planes-08.jpeg';
import RoofImage09 from '../RoofFromAllPlanes/Roof-from-all-planes-09.jpeg';
import RoofImage10 from '../RoofFromAllPlanes/Roof-from-all-planes-10.jpeg';
import RoofImage11 from '../RoofFromAllPlanes/Roof-from-all-planes-11.jpeg';
import RoofImage12 from '../RoofFromAllPlanes/Roof-from-all-planes-12.jpeg';

import { ExampleImage } from '..';

export const ROOF_ALL_PANELS_SITE_SURVEY: ExampleImage[] = [
  { source: RoofImage01 },
  { source: RoofImage02 },
  { source: RoofImage03 },
  { source: RoofImage04 },
  { source: RoofImage05 },
  { source: RoofImage06 },
  { source: RoofImage07 },
  { source: RoofImage08 },
  { source: RoofImage09 },
  { source: RoofImage10 },
  { source: RoofImage11 },
  { source: RoofImage12 },
];
