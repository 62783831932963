import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import * as gs from '../gStyles';

export type NavMenuProps = c.r<{
  canHide?: boolean;
  hide?: boolean;
  onPressAuditor(): void;
  onPressCommissionCalc(): void;
  onPressEfficiency(field: c.CustomerField): void;
  onPressMultimedia(): void;
}>;

export default React.memo<NavMenuProps>(function NavMenu({
  canHide,
  hide,
  onPressAuditor,
  onPressCommissionCalc,
  onPressEfficiency,
  onPressMultimedia,
}) {
  // const gStyles = gs.useGlobalStyles('backdrop');
  const styles = gs.useThemedStyleSheet(themedStyles);

  //#region local
  const handleCustomPress = React.useCallback((): void => {
    onPressEfficiency('customFields');
  }, [onPressEfficiency]);
  //#endregion local

  return (
    <rn.View
      style={(() => {
        if (canHide) {
          return hide ? styles.containerHidden : styles.container;
        }
        return styles.container;
      })()}
    >
      {c.nonCustomEfficiencyKeys.map((field) => (
        <NavMenuItem field={field} key={field} onPress={onPressEfficiency} />
      ))}

      <rn.TouchableOpacity onPress={handleCustomPress} style={styles.item}>
        <rn.Text style={styles.itemText}>Custom Efficiencies</rn.Text>
      </rn.TouchableOpacity>

      <rn.TouchableOpacity onPress={onPressMultimedia} style={styles.item}>
        <rn.Text style={styles.itemText}>Multimedia</rn.Text>
      </rn.TouchableOpacity>

      <rn.TouchableOpacity onPress={onPressAuditor} style={styles.item}>
        <rn.Text style={styles.itemText}>Auditor Report</rn.Text>
      </rn.TouchableOpacity>

      <rn.TouchableOpacity onPress={onPressCommissionCalc} style={styles.item}>
        <rn.Text style={styles.itemText}>Commission Calculator</rn.Text>
      </rn.TouchableOpacity>
    </rn.View>
  );
});

type NavMenuItemProps = c.r<{
  field: c.CustomerField;
  onPress(field: c.CustomerField): void;
}>;

const NavMenuItem = React.memo<NavMenuItemProps>(function NavMenuItem({
  field,
  onPress,
}) {
  const gStyles = gs.useGlobalStyles('backdrop');
  const styles = gs.useThemedStyleSheet(themedStyles);
  const handlePress = React.useCallback(() => {
    onPress(field);
  }, [field, onPress]);

  return (
    <React.Fragment>
      <rn.TouchableOpacity onPress={handlePress} style={styles.item}>
        <rn.Text style={styles.itemText}>{c.getFieldLabel(field)}</rn.Text>
      </rn.TouchableOpacity>

      <rn.View style={gStyles.separatorH} />
    </React.Fragment>
  );
});

const touchSurfaceWidth = 24;

const themedStyles = gs.ThemedStyleSheet.create((t) => {
  const container = {
    backgroundColor: t.backdrop.backgroundColor,
    borderRadius: t.backdrop.borderRadius,
    // @ts-ignore 'fixed' not available on mobile
    position: (r.isWeb ? 'fixed' : 'absolute') as 'absolute',
    left: Number(t.canvas.gap) * 2,
    /* Breadcrumb height */
    top: 32,
    // TODO: Why 0.72 instead of 0.72? Probably because of the top above
    maxHeight: `${r.isSafari() ? 100 : 100 / 0.74}vh`,
    overflow: 'scroll',
    padding: t.backdrop.gap || 0,
    ...gs.shadowFull,
  } as const;

  return {
    container,
    containerHidden: { ...container, left: -216, overflow: 'hidden' },
    dot: {
      backgroundColor: t.backdrop.highlight,
      borderRadius: Number.MAX_SAFE_INTEGER,
      height: 12,
      width: 12,
    },
    item: {
      padding: t.backdrop.gap,
    },
    itemText: {
      color: t.backdrop.color,
      fontFamily: t.backdrop.fontFamily,
      fontSize: 14,
    },
    touchSurface: {
      ...gs.deadCenter,
      position: 'absolute',
      right: 0,
      height: '100%',
      width: touchSurfaceWidth,
    },
  };
});
