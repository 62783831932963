import React, { useState } from 'react';

import * as c from '../common';
import * as r from '../react-utils';

import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  User,
  getAuth,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  GoogleAuthProvider,
} from 'firebase/auth';
import {
  DatabaseReference as V9DatabaseReference,
  QueryConstraint,
  child,
  get,
  getDatabase,
  limitToFirst,
  off,
  onChildAdded,
  onChildMoved,
  onChildChanged,
  onChildRemoved,
  onValue,
  orderByChild,
  //  push,
  ref,
  remove,
  set,
  update,
  query,
  limitToLast,
} from 'firebase/database';
import { getStorage, uploadBytes, ref as storageRef } from 'firebase/storage';
import { Navigate, useLocation } from 'react-router-dom';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env['REACT_APP_G_API_KEY']!,
  projectId: c.commonEnv.FB_PROJECT_ID,
  storageBucket: c.commonEnv.FB_PROJECT_ID + '.appspot.com',
  authDomain: c.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

export const app = initializeApp(firebaseConfig, 'UPS');

// Use initializeAuth later
// https://firebase.google.com/docs/auth/web/custom-dependencies
export const auth = getAuth(app);
export const signInWithEmailAndPassword = (email: string, pass: string) =>
  _signInWithEmailAndPassword(auth, email, pass);

export const provider = new GoogleAuthProvider();

export const RequireAuth: React.FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const isMounted = r.useIsMounted();

  React.useEffect(() => {
    const unSubscribe = auth.onAuthStateChanged(
      (user: User | null) => {
        isMounted() && setAuthenticated(!!user);
        if (!!user) {
          c.dispatch(
            c.setSetting({
              key: 'isAuth',
              value: user.uid,
            }),
          );
        }
      },
      (e) => {
        // TODO: analytics
        console.log(e);
        isMounted() && setAuthenticated(false);
        auth.signOut();
      },
    );

    return () => {
      unSubscribe();
    };
  }, [isMounted]);

  const location = useLocation();

  // move to redux?
  if (authenticated === null) {
    return <></>;
  }

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // CAST: `children` or FC typings wack
  return (<>{children}</>) as any;
};

export const db = getDatabase(app);

// export const logFireWrite = (
//   path: string,
//   type: 'new' | 'put' | 'remove' | 'set' | 'update',
//   data?: unknown,
// ): void => {
//   let msg = ''

//   if (type === 'new') {
//     msg = `${auth.currentUser?.email} created new customer: ${path}`
//   } else if (type === 'put') {
//     msg = `${auth.currentUser?.email} putting files at path ${path}`
//   } else if (type === 'remove') {
//     msg = `${auth.currentUser?.email} removing data at path ${path}`
//   } else if (type === 'set') {
//     msg = `${
//       auth.currentUser?.email
//     } writing (set) to path ${path} data: ${JSON.stringify(data, null, 2)}`
//   } else if (type === 'update') {
//     msg = `${
//       auth.currentUser?.email
//     } writing (update) to path ${path} data: ${JSON.stringify(data, null, 2)}`
//   } else {
//     throw new TypeError(`Wrong type ${type} provided to logFireWrite()`)
//   }

//   const logsRef = ref(db, 'Logs')

//   push(logsRef, msg)
// }

export interface AdapterOpts {
  queryConstraints?: QueryConstraint[];
}

const fb9to8RefAdapter = (
  v9Ref: V9DatabaseReference,
  { queryConstraints }: AdapterOpts = {},
): c.DatabaseRef => {
  // const path = v9Ref.toString().substring(v9Ref.root.toString().length - 1)

  return {
    child(key: string) {
      if (queryConstraints) {
        throw new Error('Cannot call child() after providing queryConstraints');
      }
      return fb9to8RefAdapter(child(v9Ref, key));
    },
    off() {
      off(v9Ref);
    },
    on(event, cb, errCb) {
      const _query = query(v9Ref, ...(queryConstraints || []));

      if (event === 'child_added') {
        onChildAdded(_query, cb, errCb);
      } else if (event === 'child_changed') {
        onChildChanged(_query, cb, errCb);
      } else if (event === 'child_removed') {
        onChildRemoved(_query, cb, errCb);
      } else if (event === 'value') {
        onValue(_query, cb, errCb);
      } else if (event === 'child_moved') {
        onChildMoved(_query, cb, errCb);
      } else {
        throw new Error(`fb9to8RefAdapter() -> Un-Expected event: ${event}`);
      }
    },
    once() {
      if (queryConstraints) {
        return get(query(v9Ref, ...queryConstraints));
      }
      return get(v9Ref);
    },
    remove() {
      if (queryConstraints) {
        throw new Error(
          'Cannot call remove() after providing queryConstraints',
        );
      }
      //  logFireWrite(path, 'remove')
      remove(v9Ref);
    },
    update(data) {
      if (queryConstraints) {
        throw new Error(
          'Cannot call update() after providing queryConstraints',
        );
      }

      //  logFireWrite(path, 'update', data)

      update(v9Ref, data);
    },
    limitToFirst(howMany: number) {
      return fb9to8RefAdapter(v9Ref, {
        queryConstraints: (queryConstraints || [])?.concat(
          limitToFirst(howMany),
        ),
      });
    },
    limitToLast(howMany: number) {
      return fb9to8RefAdapter(v9Ref, {
        queryConstraints: (queryConstraints || [])?.concat(
          limitToLast(howMany),
        ),
      });
    },
    orderByChild(child) {
      return fb9to8RefAdapter(v9Ref, {
        queryConstraints: (queryConstraints || [])?.concat(orderByChild(child)),
      });
    },
    async set(value, onComplete) {
      try {
        //  logFireWrite(path, 'set', value)
        await set(v9Ref, value);
        onComplete && onComplete(null);
      } catch (e) {
        if (onComplete) {
          onComplete(e instanceof Error ? e : new Error(c.processErr(e)));
        } else {
          throw e;
        }
      }
    },
  };
};

export const customersDB = ref(db, '/Customers');
export const closersDB = ref(db, '/Closers');
export const settersDB = ref(db, '/Setters');
// export const logsDB = (path: string) => ref(db, `/NewLogs${path}`)

const database = {
  ref(key: string) {
    return fb9to8RefAdapter(ref(db, key));
  },
};

// @ts-expect-error
window.db = database;

c.setDatabase(database);

const v9Storage = getStorage(app);

export const fireStorage: c.Storage = {
  ref(key: string) {
    return {
      putFile(blob) {
        //  logFireWrite(key, 'put')

        // CAST: temp
        const uploadPromise = uploadBytes(
          storageRef(v9Storage, key),
          blob as unknown as Blob,
        );

        return {
          on(e, cb) {
            if (e === 'state_changed') {
              uploadPromise
                .then(() => {
                  cb({
                    state: 'success',
                  });
                })
                .catch((e) => {
                  if (typeof e === 'string') {
                    cb({
                      error: new Error(e),
                      state: 'error',
                    });
                  }

                  if (e instanceof Error) {
                    cb({
                      error: e,
                      state: 'error',
                    });
                  }
                });
            } else {
              throw new Error('Unknown event');
            }
          },
        };
      },
    };
  },
};
