import React from 'react';
import {
  Platform,
  StatusBar,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';

export type PlatformAwareSafeViewProps = ViewProps & {
  children: React.ReactNode;
  usePaddingAndroid?: boolean;
};

export default React.memo<PlatformAwareSafeViewProps>(
  function PlatformAwareSafeView({
    usePaddingAndroid,
    ...props
  }): React.ReactElement {
    return Platform.OS === 'ios' ? (
      <SafeAreaView {...props} />
    ) : (
      <View
        {...props}
        style={StyleSheet.compose<ViewStyle>(
          usePaddingAndroid ? styles.androidPadding : styles.android,
          props.style,
        )}
      />
    );
  },
);

const styles = StyleSheet.create({
  android: {
    marginTop: StatusBar.currentHeight,
  },
  androidPadding: {
    paddingTop: StatusBar.currentHeight,
  },
});
