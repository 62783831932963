import RafterImage01 from '../Rafters/Rafters-measurements-09.jpeg';
import RafterImage02 from '../Rafters/Rafters-measurements-01.jpeg';
import RafterImage03 from '../Rafters/Rafters-measurements-02.jpeg';
import RafterImage04 from '../Rafters/Rafters-measurements-03.jpeg';
import RafterImage05 from '../Rafters/Rafters-measurements-04.jpeg';
import RafterImage06 from '../Rafters/Rafters-measurements-05.jpeg';
import RafterImage07 from '../Rafters/Rafters-measurements-06.jpeg';
import RafterImage08 from '../Rafters/Rafters-measurements-07.jpeg';
import RafterImage09 from '../Rafters/Rafters-measurements-08.jpeg';

import { ExampleImage } from '..';

export const RAFTERS_SITE_SURVEY: ExampleImage[] = [
  { source: RafterImage01 },
  { source: RafterImage02 },
  { source: RafterImage03 },
  { source: RafterImage04 },
  { source: RafterImage05 },
  { source: RafterImage06 },
  { source: RafterImage07 },
  { source: RafterImage08 },
  { source: RafterImage09 },
];
