import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as rn from 'react-native';

import * as c from '../common';

// import axios from 'axios'
import moment from 'moment';
import { Provider } from 'react-redux';

export const log = (obj: c.ReadonlyRecord<unknown>): void => {
  const deps = c.values(obj);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    console.log(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

interface RoofClaimsProps {
  readonly customerID: string;
}

export function useIsRoofClaims({ customerID }: RoofClaimsProps) {
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  );

  const selectCustomerArgs = React.useMemo((): c.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      };
    } else {
      return {
        customerID: '',
      };
    }
  }, [customerID]);

  const currentCustomer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  );

  return c.roofCompanies.includes(currentCustomer.solarCompany);
}

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

export const useBool = (initialState: boolean) => {
  const [currState, setState] = useState<boolean>(initialState);

  const toggle = useCallback((): void => {
    setState((curr) => !curr);
  }, []);

  return [currState, setState, toggle] as const;
};

export const useInputVal = (initVal = '') => {
  const [val, setVal] = React.useState<string>(initVal);
  const handleClean = React.useCallback(() => void setVal(''), []);
  const handleInputChange = React.useCallback(
    (e: { target: { value: string } }): void => {
      setVal(e.target.value);
    },
    [],
  );

  return [val, setVal, handleInputChange, handleClean] as const;
};

export function useObj<R extends object>(initialState: R = c.EMPTY_OBJ) {
  const [obj, setObj] = React.useState<R>(initialState);

  const assign = React.useCallback((data: R): void => {
    setObj((_) => ({
      ..._,
      ...data,
    }));
  }, []);

  return [obj, setObj, assign] as const;
}

export const useWhatChanged = (deps: unknown[], name: string): void => {
  const refs = deps.map(
    // Hooks can be called inside map() if the array doesn't change length.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (val): React.MutableRefObject<unknown> => useRef<unknown>(val),
  );

  useEffect((): void => {
    for (let i = 0; i < deps.length; i++) {
      const dep = deps[i];

      if (dep !== refs[i]!.current) {
        c.log(`${name} -> dep changed at index ${i}`);
        refs[i]!.current = dep;
      }
    }
    // TODO: Look at why deps are specified this way
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useForceUpdate = () => {
  const [, setTimestamp] = useState(Date.now());

  return useCallback(() => {
    setTimestamp(Date.now());
  }, []);
};

export const useBattery = (customerID: string) => {
  const [installationCompaniesStr] = useSetting<string>(
    'installationCompanies',
  );
  const [batteryTypeStr] = useSetting<string>('batteryType');
  const [allBatterySizes] = useSetting<Record<string, string>>('batterySize');
  const [installer] = useCustomerField(
    customerID,
    'main_panel_upgrade_installation_company',
  );
  const [customerBatteryType] = useCustomerField(customerID, 'battery_type');

  const batterySize = React.useMemo(() => {
    if (
      !customerBatteryType ||
      !customerBatteryType ||
      !installer ||
      !allBatterySizes
    ) {
      return c.EMPTY_ARRAY;
    }

    const key = `Battery ${customerBatteryType}-${installer}`;
    return allBatterySizes[key];
  }, [allBatterySizes, installer, customerBatteryType]);

  const batteryTypes = React.useMemo((): c.Opts => {
    const allOptions = c.convertToOptions(batteryTypeStr);
    if (installer === 'EcoManagement') {
      return allOptions.filter((t) => t.value === 'Backup');
    }
    return allOptions;
  }, [batteryTypeStr, installer]);

  return [
    c.convertToOptions(installationCompaniesStr),
    batteryTypes,
    c.convertToOptions(batterySize),
  ] as const;
};

export const initProvider = ({ fireStorage, getUserID }: c.InitStoreParams) => {
  const store = c.initStore({
    fireStorage,
    getUserID,
  });

  if (c.IS_WEB) {
    // @ts-ignore
    window.store = store;
  }

  return React.memo<{
    children: React.ReactElement;
    loading?: React.ReactElement;
  }>(({ children }) => <Provider store={store}>{children}</Provider>);
};

interface IChangeEvent {
  readonly target: {
    readonly value: string;
  };
}

export const useInput = (initialState: string) => {
  const [currState, setState] = useState<string>(initialState);

  const onChange = useCallback((e: IChangeEvent): void => {
    setState(e.target.value);
  }, []);

  return [currState, setState, onChange] as const;
};

export const useTextInput = (initialState: string) => {
  const [currState, setState] = useState<string>(initialState);

  const onChangeText = useCallback((text: string): void => {
    setState(text);
  }, []);

  return [currState, setState, onChangeText] as const;
};

export function useCustomer(customerID: string) {
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  );

  const selectCustomerArgs = React.useMemo(
    (): c.SelectCustomerParams => ({
      customerID,
    }),
    [customerID],
  );

  const currentCustomer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  );

  return [currentCustomer] as const;
}

/**
 * Customer must be subbed to elsewhere.
 */
export const useCustomerField = <K extends c.CustomerField>(
  customerID: string,
  field: K,
) => {
  type FieldVal = c.Customer[K];

  const isMounted = useIsMounted();

  const fieldType = c.CustomerSchema[field];
  const sanitizer = c.fieldTypeToSanitizer[fieldType.toString()]!;
  let initVal = sanitizer(null) as FieldVal;
  if (initVal === 'no' || !initVal)
    initVal =
      c.selectCustomer(c.getStore().getState(), customerID)[field] || initVal;
  const [localValue, setLocalValue] = React.useState<FieldVal>(initVal);

  React.useEffect(() => {
    const ref = c.getDatabase().ref(`Customers/${customerID}/${field}`);
    ref.on('value', (s) => void setLocalValue(sanitizer(s.val()) as FieldVal));
    return () => void ref.off();
  }, [customerID, field, isMounted, sanitizer]);

  type SetFieldAction = React.SetStateAction<FieldVal>;
  type FieldDispatch = React.Dispatch<SetFieldAction>;

  const writeValue = React.useCallback<FieldDispatch>(
    (newValueOrDispatch) => {
      if (typeof newValueOrDispatch === 'function') {
        setLocalValue((prev) => {
          const newVal = newValueOrDispatch(prev);
          const batch: c.PartialCustomer = {
            [field]: newVal,
          };
          if (field === 'customerAddress') {
            batch.addressLat = 0;
            batch.addressLng = 0;
          }
          c.updateCustomer(customerID, batch);
          return newVal;
        });
      } else {
        const newVal = newValueOrDispatch;
        setLocalValue(newValueOrDispatch);
        const batch: c.PartialCustomer = {
          [field]: newVal,
        };
        if (field === 'customerAddress') {
          batch.addressLat = 0;
          batch.addressLng = 0;
        }
        c.updateCustomer(customerID, batch);
      }
    },
    [customerID, field],
  );

  const toggleValue = React.useCallback(() => {
    // @ts-expect-error
    setLocalValue((b) => !b);
  }, []);

  return [localValue as c.Customer[K], writeValue, toggleValue] as const;
};

export const useSetting = <Cast extends c.ValidRootValue>(key: string) => {
  const selectSetting = React.useMemo(() => c.makeSelectSetting(), []);
  const selectSettingArgs = React.useMemo(
    (): c.SelectSettingParams => ({
      key,
    }),
    [key],
  );
  const currVal = c.useSelector(
    (_): c.ValidRootValue => selectSetting(_, selectSettingArgs),
  ) as Cast;

  type WriteVal = Dispatch<SetStateAction<Cast>>;
  const writeVal = React.useCallback<WriteVal>(
    (valueOrSetter) => {
      c.dispatch(
        c.setSetting({
          key,
          value:
            typeof valueOrSetter === 'function'
              ? valueOrSetter(currVal)
              : valueOrSetter,
        }),
      );
    },
    [currVal, key],
  );

  return [currVal, writeVal] as const;
};

export const ghostDiv: HTMLDivElement = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('div');
  }
  return null as unknown as HTMLDivElement;
})();
export const ghostInput: HTMLInputElement = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('input');
  }
  return null as unknown as HTMLInputElement;
})();
export const ghostP = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('p');
  }
  return null as unknown as HTMLParagraphElement;
})();

export interface RowableUsed<Field extends c.FieldRowable> {
  addRow(): void;
  changeVal(
    col: string,
    rowID: string,
    val: React.SetStateAction<number | string>,
  ): void;
  cols: c.Cols;
  deleteRow(rowID: string): void;
  rows: readonly c.FieldToRowable<Field>[];
}

export const useRowable = <Field extends c.FieldRowable>(
  customerID: string,
  field: c.FieldRowable,
): RowableUsed<Field> => {
  const [createdAt] = useCustomerField(customerID, 'createdAt');
  const [homeRep] = useCustomerField(customerID, 'homeRep');

  const [windowColorsStr] = useSetting<string>('windowColors');
  const windowColors = JSON.parse(windowColorsStr || '[]') as readonly string[];

  const cols = React.useMemo((): c.Cols => {
    const _cols = c.rowableToCols[field];

    if (field === 'new_windows_sqft_each_window') {
      const shouldShowGlobalColor = createdAt > c.Jun4_2024;

      const theCols = _cols.slice();

      if (windowColors)
        (theCols[2] as c.OptCol) = {
          ...(theCols[2] as c.OptCol),
          opts: windowColors.map(
            (c): c.Opt => ({
              label: c,
              value: c,
            }),
          ),
        };

      const isGiovanniCustomer = homeRep.toLowerCase().includes('giovanni');
      // always an old customer
      if (isGiovanniCustomer) {
        theCols.push(c.windowTypeCol);
      }

      if (shouldShowGlobalColor) {
        theCols.splice(2, 1);
        theCols[0] = {
          ...theCols[0],
          widthPer: 30,
        } as c.Col;
        theCols[1] = {
          ...theCols[1],
          widthPer: 30,
        } as c.Col;
        theCols[2] = {
          ...theCols[2],
          widthPer: 30,
        } as c.Col;
      }

      // Remove individual color selector for newer customers
      return theCols;
    }

    return _cols;
  }, [createdAt, field, homeRep, windowColors]);

  const [rowsStr, setRowsStr] = useCustomerField(customerID, field);

  const addRow = React.useCallback<RowableUsed<Field>['addRow']>((): void => {
    setRowsStr((currRowsStr) => {
      const newRows = JSON.parse(currRowsStr || '[]') as c.Rowable[];

      if (field === 'mini_split_tons') {
        (newRows as c.MiniSplit[]).push(c.newMiniSplit());
      }
      if (field === 'new_windows_sliding_glass_sqft') {
        (newRows as c.SlidingGlassDoorGroup[]).push(
          c.newSlidingGlassDoorGroup(),
        );
      }
      if (field === 'new_windows_sqft_each_window') {
        (newRows as c.WindowGroup[]).push(c.newWindowGroup());
      }

      return JSON.stringify(newRows);
    });
  }, [field, setRowsStr]);

  const changeVal = React.useCallback<RowableUsed<Field>['changeVal']>(
    (col, rowID, val): void => {
      setRowsStr((currRowsStr) => {
        const newRows = JSON.parse(
          currRowsStr || '[]',
        ) as c.Writable<c.Rowable>[];

        const theRow = newRows.find((r) => r.id === rowID);

        if (!theRow) {
          // The row was deleted
          return currRowsStr;
        }

        const colKey = col as keyof c.Rowable;

        const colType = cols.find((_) => _.key === colKey)!.type;

        const newVal = (() => {
          if (typeof val === 'function') {
            return val(theRow[colKey]).toString();
          } else {
            return val.toString();
          }
        })();
        const newValSanitized =
          colType === 'input'
            ? newVal.replace('-', '').replace('+', '').replace(',', '')
            : newVal;

        theRow[colKey] = newValSanitized;

        return JSON.stringify(newRows);
      });
    },
    [cols, setRowsStr],
  );

  const deleteRow = React.useCallback<RowableUsed<Field>['deleteRow']>(
    (rowID) => {
      setRowsStr((currRowsStr) => {
        const newRows = JSON.parse(currRowsStr || '[]') as c.Rowable[];
        const rowIdx = newRows.findIndex((r) => r.id === rowID);
        newRows.splice(rowIdx, 1);

        return JSON.stringify(newRows);
      });
    },
    [setRowsStr],
  );

  const rows = React.useMemo(() => JSON.parse(rowsStr || '[]'), [rowsStr]);

  if (!c.rowableFields.includes(field)) {
    throw new Error(`Wrong field: ${field}`);
  }

  return {
    addRow,
    changeVal,
    cols,
    deleteRow,
    rows,
  };
};

export const useWindowColors = (): c.Opts => {
  const [windowColorsStr] = useSetting<string>('windowColors');
  const windowColors = JSON.parse(windowColorsStr || '[]') as c.strings;
  const windowColorOpts = windowColors.map(
    (c): c.Opt => ({
      label: c,
      value: c,
    }),
  );
  return windowColorOpts;
};

export const useInsulation = (customerID: string): c.Opts => {
  const [createdAt] = useCustomerField(customerID, 'createdAt');
  const [solarCompany] = useCustomerField(customerID, 'solarCompany');
  const [currInsulationType] = useCustomerField(
    customerID,
    'attic_insulation_type',
  );

  const insulationTypes = React.useMemo((): c.Opts => {
    if (typeof createdAt !== 'number') {
      console.warn(
        `useInsulation() -> ${customerID} customer's createdAt not a number: ${typeof createdAt}`,
      );
      return c.emptyArr as c.Opts;
    }
    if (!solarCompany) {
      console.warn(
        `useInsulation() -> ${customerID} customer's solarCompany blank: ${typeof solarCompany}`,
      );
      return c.emptyArr as c.Opts;
    }

    // White labels won't have such old customers
    const isOldCustomer = moment(createdAt).isBefore(
      c.NEW_INSULATION_TYPES_CUTOFF,
    );
    const insulationTypesToUse = isOldCustomer
      ? c.insulationTypesOld[solarCompany]
      : c.companyToInsulationTypes[solarCompany];

    if (!insulationTypesToUse) {
      return [
        {
          label: currInsulationType,
          value: currInsulationType,
        },
      ];
    }

    return insulationTypesToUse.map((value) => ({
      label: value,
      value,
    }));
  }, [createdAt, currInsulationType, customerID, solarCompany]);

  return insulationTypes;
};

export const useTotalCost = (
  customerID: string,
  deductDisabled?: boolean,
): number => {
  // c.debug('useTotalCost()')
  const customer = c.useSelector((s) => c.selectCustomer(s, customerID));
  if (!customer) {
    // c.debug('useTotalCost() -> returning 0 as no customer found')
    return 0;
  }
  const turnedOn = c.efficiencyKeys
    .filter((k) => customer[k] === 'yes')
    .filter((field) => {
      if (!deductDisabled) return true;
      const showField =
        c.normalizeCheckShows[field] || ((field + '_show') as c.CustomerField);
      return customer[showField] === 1;
    });
  const costFields = turnedOn.map((k) => k + '_cost');
  const costsStr = costFields.map(
    (k) => customer[k as c.CustomerField],
  ) as c.strings;
  const costs = costsStr.map((c) => Number(c)).filter(Number.isFinite);
  const total = costs.reduce((a, b) => Number(a) + Number(b), 0);
  // c.debug(`useTotalCost() -> total -> ${total}`)
  return total;
};

export const MUIBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  laptop: 1090,
} as const;

//#region theming
export const useTheme = () => {
  const colorScheme = rn.useColorScheme() || 'light';
  return c.themeTuple[colorScheme];
};
//#endregion theming
//#region address
// const placesBaseURL = 'https://maps.googleapis.com/maps/api/place'

type Geometry = c.r<{ location: { lat: number; lng: number } }>;

export type Prediction = c.r<{
  description: string;
  /* Not actually contained in the autocomplete endpoint, but in the details one */
  geometry: Geometry;
  place_id: string;
}>;

// let webAutocomplete: google.maps.places.AutocompleteService =
//   null as unknown as google.maps.places.AutocompleteService

export const setGoogService = (
  s: google.maps.places.AutocompleteService,
): void => {
  // @ts-ignore
  if (Math.random() === s) {
  } // avoid unused var
  // webAutocomplete = s
};

// const getPredictionsForAddress = async (q: string): Promise<c.Opts> => {
//   return Promise.resolve([])
//   // if (c.IS_WEB)
//   //   return new Promise((res, rej) => {
//   //     webAutocomplete.getQueryPredictions(
//   //       {
//   //         input: q,
//   //       },
//   //       (data, status) => {
//   //         if (status === 'OK') {
//   //           res(
//   //             data?.map((p) => ({
//   //               label: p.description,
//   //               value: p.place_id!,
//   //             })) || [],
//   //           )
//   //         } else {
//   //           rej(new Error(status))
//   //         }
//   //       },
//   //     )
//   //   })

//   // const placesURL = `${placesBaseURL as string}/autocomplete/json?key=${process
//   //   .env['REACT_APP_G_API_KEY']!}&language=en&components=country:us&input=`
//   // type PlacesRes = c.r<{
//   //   predictions: Prediction[]
//   // }>
//   // const res = await axios.request({
//   //   method: 'get',
//   //   url: `${placesURL}${q}`,
//   // })
//   // if (res.status === 200)
//   //   return (res.data as PlacesRes).predictions.map(
//   //     (p): c.Opt => ({ label: p.description, value: p.place_id }),
//   //   )
//   // else throw new Error(`${getPredictionsForAddress.name} -> res.status != 200`)
// }

// const getPredictionDetails = async (
//   placeID: string,
// ): Promise<Prediction | null> => {
//   return Promise.resolve(null)
//   if (c.IS_WEB) return new Promise((res, rej) => {
//     res()
//   })

//   const placesDetailsURL = `${placesBaseURL}/details/json?key=${process.env[
//     'REACT_APP_G_API_KEY'
//   ]!}&place_id=`
//   type PlaceRes = c.r<{ result: Prediction }>
//   const res = await axios.request({
//     method: 'get',
//     url: placesDetailsURL + placeID,
//   })
//   if (res.status === 200) return (res.data as PlaceRes).result
//   else throw new Error(`${getPredictionDetails.name} -> res.status != 200`)
// }

export const usePredictions = (
  customerID: string,
  q: string,
  gApiKey: string,
) => {
  if (Math.random() > 1) console.log(customerID, q, gApiKey); // avoid unused var

  return [[] as c.Opts, c.emptyFn] as const;
  // const [predictions, setPredictions] = React.useState<c.Opts>([])

  // React.useEffect((): void => {
  //   if (q === '') return void setPredictions(c.EMPTY_ARRAY)
  //   const thisQ = q

  //   thisQ &&
  //     getPredictionsForAddress(thisQ).then((p) => {
  //       if (q === thisQ) setPredictions(p)
  //     })
  // }, [gApiKey, q])

  // const handlePredictionSelect = React.useCallback(
  //   async (placeID: string): Promise<void> => {
  //     const place = predictions.find((p) => p.value === placeID)!
  //     c.updateCustomer(customerID, {
  //       addressLat: 0,
  //       addressLng: 0,
  //       customerAddress: place.label,
  //     })
  //   },
  //   [customerID, predictions],
  // )

  // const [currAddress] = useCustomerField(customerID, 'customerAddress')
  // const [currLat] = useCustomerField(customerID, 'addressLat')
  // const [currLng] = useCustomerField(customerID, 'addressLng')

  // /* Fetch the coordinates for an existing address if the address is actually
  //  recognized by Google */
  // React.useEffect(() => {
  //   ;(async () => {
  //     // const [prediction = { label: '', value: '' }] = predictions
  //     // if (prediction.label === currAddress && currLat === 0 && currLng === 0) {
  //     //   c.debug(`Fetching coordinates for address ${currAddress}`, {
  //     //     predictions,
  //     //     currLat,
  //     //     currLng,
  //     //   })
  //     //   const fullPlace = await getPredictionDetails(prediction.value)
  //     //   if (fullPlace?.geometry) {
  //     //     c.updateCustomer(customerID, {
  //     //       addressLat: fullPlace.geometry.location.lat,
  //     //       addressLng: fullPlace.geometry.location.lng,
  //     //     })
  //     //   } else {
  //     //     c.debug(`No geometry for ${currAddress}`)
  //     //   }
  //     // }
  //   })()
  // }, [currAddress, currLat, currLng, customerID, predictions])

  // return [predictions, handlePredictionSelect] as const
};

export const openMapsAt = async (lat: number, lng: number): Promise<void> => {
  const browserURL = `https://www.google.de/maps/@${lat},${lng}`;
  const url: string =
    rn.Platform.select({
      android: `geo://?q=${lat},${lng}`,
      ios: `maps://?q=${lat},${lng}`,
    }) || browserURL;

  rn.Linking.openURL(url);
};
//#endregion address

export const empty = <rn.View />;

export const isMobile =
  rn.Platform.OS === 'android' || rn.Platform.OS === 'ios';
export const isWeb = rn.Platform.OS === 'web';

export const isSafari = () => {
  if (isMobile) return false;
  // @ts-ignore
  if (typeof navigator !== 'object' || navigator === null) return false;
  // @ts-ignore
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const RowWrapper = isMobile ? rn.View : React.Fragment;
// export const RowWrapper = React.Fragment;
