import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import * as gs from '../gStyles';

import Label from './Label';
import Labeled, { labeledGap } from './Labeled';

import Text from './Text';
import TextInput from './TextInput';

export type CloserCalcProps = c.r<{
  customerID: string;
  /**
   * If activated, it will deduct from the "adder" total the efficiencies
   * disabled via the check-marks on Auditor report.
   */
  deductDisabled?: boolean;
}>;

export default React.memo<CloserCalcProps>(({ customerID, deductDisabled }) => {
  // c.debug('<CloserCalc />')
  const rowGap = gs.useRowGap('canvas');
  const gStyles = gs.useGlobalStyles('canvas');

  const styles = gs.useThemedStyleSheet(themedStyles);
  const [solarCompany] = r.useCustomerField(customerID, 'solarCompany');

  // TODO: Handle california being the default state for companies
  const isCali = solarCompany.startsWith('CPS');
  const isNV = solarCompany.startsWith('NPS') || solarCompany === 'affordable';
  const isWrongState = !isCali && !isNV;
  const redline = isCali ? 3.2 : 2.8;

  const totalCost = r.useTotalCost(customerID, deductDisabled);

  // c.debug(`<CloserCalc /> -> totalCost -> ${totalCost}`)

  //#region global
  const [storedBatteryCost] = r.useCustomerField(customerID, 'battery_cost');
  //#endregion global

  const [ppw, setPPW] = r.useInputVal('');

  const [sysSize, setSysSize] = r.useInputVal('1');
  const handleSysSize = React.useCallback(
    (newSysSize): void => {
      setPPW('');
      setFinalCommission('0');
      setSysSize(newSysSize);
    },
    [setPPW, setSysSize],
  );
  const [adder, setAdder] = r.useInputVal(
    totalCost ? totalCost.toString() : '',
  );
  const handleAdder = React.useCallback(
    (newAdder: string): void => {
      setPPW('');
      setFinalCommission('0');
      setAdder(newAdder);
    },
    [setAdder, setPPW],
  );
  React.useEffect(() => {
    const batteryCostN = Number(storedBatteryCost || '');
    setAdder(totalCost ? (totalCost - batteryCostN).toString() : '');
  }, [setAdder, storedBatteryCost, totalCost]);

  const [batteryCost, setBatteryCost] = r.useInputVal(storedBatteryCost);
  const handleBatteryCost = React.useCallback(
    (newBatteryCost: string): void => {
      setPPW('');
      setFinalCommission('0');
      setBatteryCost(newBatteryCost);
    },
    [setBatteryCost, setPPW],
  );
  React.useEffect(() => {
    setBatteryCost(storedBatteryCost);
  }, [setBatteryCost, storedBatteryCost]);

  const adderPPWRaw = Number(adder) / (Number(sysSize) * 1000);
  const adderPPW = Number.isFinite(adderPPWRaw) ? adderPPWRaw : 0;
  const batteryPPWRaw = Number(batteryCost) / (Number(sysSize) * 1000);
  const batteryPPW = Number.isFinite(batteryPPWRaw) ? batteryPPWRaw : 0;
  const finalPPWRaw = Number(ppw) - adderPPW - batteryPPW;
  const finalPPW = Number.isFinite(finalPPWRaw) ? finalPPWRaw : 0;
  const finalPPWMinusRedline = finalPPW - redline;

  const handlePPW = React.useCallback(
    (newPPW: string): void => {
      setPPW(newPPW);

      const _adderPPWRaw = Number(adder) / (Number(sysSize) * 1000);
      const _adderPPW = Number.isFinite(_adderPPWRaw) ? _adderPPWRaw : 0;
      const _batteryPPWRaw = Number(batteryCost) / (Number(sysSize) * 1000);
      const _batteryPPW = Number.isFinite(_batteryPPWRaw) ? _batteryPPWRaw : 0;
      const _finalPPWRaw = Number(newPPW) - _adderPPW - _batteryPPW;
      const _finalPPW = Number.isFinite(_finalPPWRaw) ? _finalPPWRaw : 0;
      const _finalPPWMinusRedline = _finalPPW - redline;

      setFinalCommission(
        (_finalPPWMinusRedline * Number(sysSize) * 1000).toFixed(0),
      );
    },
    [adder, batteryCost, redline, setPPW, sysSize],
  );

  const [finalCommission, setFinalCommission] = React.useState('0');
  const handleFinalCommission = React.useCallback(
    (newFinalCommission: string) => {
      setFinalCommission(newFinalCommission);
      const topDiv =
        Number(newFinalCommission) + Number(batteryCost) + Number(adder);
      const bottomDiv = Number(sysSize || '1') * 1000;
      setPPW((topDiv / bottomDiv + 3.2).toFixed(2));
    },
    [adder, batteryCost, setPPW, sysSize],
  );

  const suggestedPPW = batteryPPW + adderPPW + 3.2;

  const topInputs = (
    <>
      {rowGap}
      <TextInput
        shrink
        label="System Size (kW)"
        clearButtonMode="always"
        keyboardType="numeric"
        on="canvas"
        onChangeText={handleSysSize}
        value={sysSize}
      />
      {rowGap}
      <TextInput
        shrink
        label="Adder"
        clearButtonMode="always"
        keyboardType="numeric"
        on="canvas"
        onChangeText={handleAdder}
        value={adder}
      />
      {rowGap}
      {isCali && (
        <>
          <TextInput
            shrink
            clearButtonMode="always"
            label="Battery Cost"
            keyboardType="numeric"
            on="canvas"
            onChangeText={handleBatteryCost}
            value={batteryCost}
          />

          {rowGap}
        </>
      )}
      {gs.rowGap12}
      <rn.View style={gStyles.separatorH} />
      {gs.rowGap12}
      <rn.View
        style={r.isWeb ? gStyles.contentNoMargin : gStyles.rowSpaceBetween100}
      >
        <r.RowWrapper style={gStyles.width48}>
          <TextInput
            label="PPW"
            clearButtonMode="always"
            keyboardType="numeric"
            on="canvas"
            onChangeText={handlePPW}
            value={ppw}
          />
          {r.isMobile && gs.rowGap16}
          <Text numberOfLines={Number.MAX_SAFE_INTEGER} size={12}>
            PPW must be {suggestedPPW.toFixed(2)} or more. (Final PPW must be
            greater than {redline})
          </Text>
        </r.RowWrapper>

        <r.RowWrapper style={gs.width48}>
          <TextInput
            label="Final Commission"
            clearButtonMode="always"
            keyboardType="numeric"
            on="canvas"
            onChangeText={handleFinalCommission}
            value={finalCommission}
          />
        </r.RowWrapper>
      </rn.View>
    </>
  );

  return (
    <rn.View style={r.isMobile && gStyles.content}>
      {isWrongState && (
        <Text style={styles.dangerText}>Calculator only for NV & CA</Text>
      )}

      {rn.Platform.OS === 'web' ? (
        <rn.View style={gStyles.contentHorizontal}>{topInputs}</rn.View>
      ) : (
        topInputs
      )}

      {gs.rowGap16}
      <rn.View style={gStyles.separatorH} />
      {gs.rowGap16}

      <rn.View
        style={r.isWeb ? gStyles.contentNoMargin : gStyles.rowSpaceBetween100}
      >
        <r.RowWrapper style={gs.width48}>
          <Labeled label="Adder PPW" on="canvas">
            <Text size={14}>{adderPPW.toPrecision(3)}</Text>
          </Labeled>
        </r.RowWrapper>

        {isCali && (
          <rn.View style={styles.alignToRight}>
            <Label alignRight on="canvas">
              Battery PPW
            </Label>

            {labeledGap}

            <Text style={styles.textAlignRight}>
              {batteryPPW.toPrecision(3)}
            </Text>
          </rn.View>
        )}
      </rn.View>

      {gs.rowGap16}

      <rn.View
        style={r.isWeb ? gStyles.contentNoMargin : gStyles.rowSpaceBetween100}
      >
        <r.RowWrapper style={gs.width48}>
          <Labeled label="Final PPW" on="canvas">
            <Text size={14}>{finalPPW.toPrecision(3)}</Text>
          </Labeled>
        </r.RowWrapper>

        <rn.View style={styles.alignToRight}>
          <Label alignRight on="canvas">{`Final PPW - ${redline}`}</Label>

          {labeledGap}

          <Text style={styles.textAlignRight}>
            {finalPPWMinusRedline.toPrecision(3)}
          </Text>
        </rn.View>
      </rn.View>

      {gs.rowGap16}

      <Labeled on="canvas" label="Closer Commission">
        <Text style={Number(finalCommission) <= 0 && styles.dangerText}>
          {finalCommission}
        </Text>
      </Labeled>
    </rn.View>
  );
});

const themedStyles = gs.ThemedStyleSheet.create((t) => ({
  alignToRight: {
    ...gs.width48,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  dangerText: { color: t.danger, fontFamily: t.fontFamily },
  dangerTextCentered: {
    color: t.danger,
    fontFamily: t.fontFamily,
    textAlign: 'center',
  },
  textAlignRight: {
    color: t.canvas.color,
    fontFamily: t.canvas.fontFamily,
    textAlign: 'center',
  },
}));
