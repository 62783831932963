import createSagaMiddleware from 'redux-saga';
import { AnyAction, Middleware, Store, configureStore } from '@reduxjs/toolkit';
import { useSelector as _useSelector } from 'react-redux';
import { all, call } from 'redux-saga/effects';

import * as api from '../api';
import * as env from '../env';
import * as helpers from '../helpers';
import * as schema from '../schema';
import * as utils from '../utils';
import { GetUserID, Storage, setStorage, setUserIDGetter } from '../api';

// import * as costs from './costs'
import { bookmarks, bookmarksSaga } from './bookmarks';
import { setDispatcher, updatedCustomerActionCreator } from './common';
import { customers, customersSaga } from './customers';
import { closers, closersSaga } from './closers';
import { efficiencyPrices, efficiencyPriceSaga } from './efficiencyPrices';
import { invoicesSaga, invoices } from './invoices';
import { media, mediaSaga } from './media';
import { setters, settersSaga } from './setters';
import { settings, settingsSaga } from './settings';
import { thumbnails } from './thumbnails';

const sagaMiddleware = createSagaMiddleware({
  onError(e) {
    utils.log('saga error', e);
  },
});

let actionsTypeLoggerEnabled = false;
let actionsPayloadLoggerEnabled = false;
const loggerMiddleware: Middleware = (_) => (next) => (action) => {
  if (actionsTypeLoggerEnabled || actionsPayloadLoggerEnabled) {
    utils.log('----Store logger---');
  }

  if (actionsTypeLoggerEnabled) {
    utils.log(action.type);
  }

  if (actionsPayloadLoggerEnabled) {
    utils.log('---Payload---');
    utils.log(action.payload);
  }

  if (actionsTypeLoggerEnabled || actionsPayloadLoggerEnabled) {
    utils.log('////Store logger---');
  }

  next(action);
};

const reducers = {
  bookmarks,
  closers,
  // costs: costs.costs,
  customers,
  efficiencyPrices,
  invoices,
  media,
  setters,
  settings,
  thumbnails,
} as const;

export type State = utils.DeepReadonly<{
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>;
}>;

let exposedStore: Store<State> | undefined;

export const dispatch = (action: unknown) => {
  if (!exposedStore) {
    throw new Error('Tried to dispatch() before initializing the store.');
  }
  exposedStore.dispatch(action as AnyAction);
};

export const getStore = () => exposedStore as Store<State>;

export interface InitStoreParams {
  fireStorage?: Storage;
  getUserID?: GetUserID;
}

export const initStore = ({ fireStorage, getUserID }: InitStoreParams = {}) => {
  if (!fireStorage) {
    throw new ReferenceError(`fireStorage must be provided to initStore()`);
  }
  setStorage(fireStorage);
  if (!getUserID) {
    throw new ReferenceError(`getUserID must be provided to initStore()`);
  }
  setUserIDGetter(getUserID);

  const store = configureStore({
    reducer: reducers,
    middleware(getDefaultMiddleware) {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: { ignoredPaths: ['settings'] },
        thunk: false,
      });

      return [loggerMiddleware]
        .concat(defaultMiddleware)
        .concat(sagaMiddleware);
    },
  });

  setDispatcher(store.dispatch.bind(store));

  exposedStore = store;

  function* rootSaga() {
    yield all([
      call(bookmarksSaga),
      call(closersSaga),
      call(customersSaga),
      call(efficiencyPriceSaga),
      call(invoicesSaga),
      call(mediaSaga),
      call(settersSaga),
      call(settingsSaga),
    ]);
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export const useSelector = <TSelected>(
  selector: (state: State) => TSelected,
): TSelected => _useSelector<State, TSelected>(selector);

export const updateCustomer = (
  customerID: string,
  _data: schema.PartialCustomer,
  opts?: { skipModifyDate?: boolean | undefined | null },
) => {
  const data = { ..._data };
  const currState = getStore().getState();
  const currCustomer =
    currState.customers.data[customerID] ||
    schema.createEmptyCustomer({
      firebaseKey: customerID,
    });
  const efficiencyPrices = currState.efficiencyPrices.prices;

  if (data.main_panel_upgrade_installation_company) {
    data.battery_type = '';
  }
  if (data.main_panel_upgrade_installation_company || data.battery_type) {
    data.battery_size = '';
  }
  if (data.roof_layover_or_tear) {
    data.roof_layers_how_many = '';
  }
  if (data.air_conditioner_working === 'no') {
    data.air_conditioner_current_tons = '0';
  }

  /**
   * Presumably the lock is working fine and it has been unlocked and it's now
   * being edited as normal.
   */
  const costsFieldsChanged = utils
    .keys(data)
    .filter(
      (field) =>
        field.endsWith('_cost') &&
        !field.endsWith('_customer_cost') &&
        !field.includes('touched'),
    );

  /**
   * For any costs manually inputted, replicate to customer cost if it's the
   * linked state.
   */
  for (const field of costsFieldsChanged) {
    const efficiency = field.replace('_cost', '') as schema.CustomerField;
    if (!currCustomer.has_touched_customer_cost[efficiency]) {
      const customerCostField = (efficiency +
        '_customer_cost') as schema.CustomerField;
      // @ts-ignore ??
      data[customerCostField] = data[field];
    }
  }

  const efficienciesChanged = utils
    .entries(data)
    .map(([field, val]) => {
      if (helpers.inputToEfficiency[field]) {
        return helpers.inputToEfficiency[field];
      }
      if (field in helpers.efficiencyToInputs && data[field] === 'yes') {
        return field;
      }
      if (field === 'efficiency_updated') return val as schema.CustomerField;

      return null;
    })
    .filter((_) => !!_) as schema.CustomerField[];

  if (data.main_panel_upgrade_installation_company) {
    if (currCustomer.main_panel_upgrade === 'yes') {
      efficienciesChanged.push('main_panel_upgrade');
    }
    if (currCustomer.battery === 'yes') {
      efficienciesChanged.push('battery');
    }
    if (currCustomer.derate === 'yes') {
      efficienciesChanged.push('derate');
    }
  }

  for (const efficiency of efficienciesChanged) {
    try {
      const [cost, notes] = helpers.efficiencyToCalculator[efficiency]!(
        {
          ...currCustomer,
          ...data,
        },
        efficiencyPrices,
        env.getCompanyState(currCustomer.solarCompany) as
          | 'california'
          | 'nevada',
      );

      if (!currCustomer.has_touched_cost[efficiency]) {
        // @ts-ignore
        data[(efficiency + '_cost') as schema.CustomerField] =
          cost === 0 ? '' : cost.toString();

        if (!currCustomer.has_touched_customer_cost[efficiency]) {
          // @ts-ignore
          data[(efficiency + '_customer_cost') as schema.CustomerField] =
            cost === 0 ? '' : cost.toString();
        }
      }

      // @ts-ignore
      data[(efficiency + '_notes') as schema.CustomerField] = notes;
    } catch (e) {
      utils.log(
        `Could not auto-gen cost/notes for efficiency ${efficiency} at customer ${customerID}`,
      );
      utils.log(e);

      if (!currCustomer.has_touched_cost[efficiency]) {
        // @ts-ignore
        data[(efficiency + '_cost') as schema.CustomerField] = '';
      }
      if (!currCustomer.has_touched_customer_cost[efficiency]) {
        // @ts-ignore
        data[(efficiency + '_customer_cost') as schema.CustomerField] = '';
      }
    }
  }

  if (data.has_touched_cost) {
    data.has_touched_cost = {
      ...currCustomer.has_touched_cost,
      ...data.has_touched_cost,
    };
  }
  if (data.has_touched_customer_cost) {
    const relinked = utils.pickBy(
      // @ts-ignore
      data.has_touched_customer_cost,
      (_) => !_,
    ) as unknown as Readonly<Record<string, boolean>>;

    console.log('relinked', relinked);

    for (const relinkedCustomerCostEff of utils.keys(relinked)) {
      /**
       * The user just re-linked customer cost to the normal cost, therefore,
       * copy over the current normal cost.
       */
      // @ts-ignore
      data[
        (relinkedCustomerCostEff + '_customer_cost') as schema.CustomerField
      ] =
        currCustomer[
          (relinkedCustomerCostEff + '_cost') as schema.CustomerField
        ];
    }

    data.has_touched_customer_cost = {
      ...currCustomer.has_touched_customer_cost,
      ...data.has_touched_customer_cost,
    };
  }

  dispatch(updatedCustomerActionCreator({ customerID, data }));
  api._updateCustomer(customerID, data, opts);
};

export const resetEfficiency = (
  customerID: string,
  efficiency: schema.CustomerField,
) => {
  const currCustomer =
    getStore().getState().customers.data[customerID] ||
    schema.createEmptyCustomer({
      firebaseKey: customerID,
    });
  let batch: Partial<utils.Writable<schema.Customer>> = {};

  const relatedFields = schema.customerFields.filter(
    (t) =>
      t.startsWith(`${efficiency}_`) &&
      !schema.roofClaimingFields.includes(t as any),
  );

  for (const relatedField of relatedFields) {
    batch = {
      ...batch,
      [relatedField]:
        schema.CustomerSchema[relatedField] === schema.YesNo ? 'no' : '',
    };
  }

  if (efficiency === 'new_windows')
    batch.new_windows_sqft_each_window = JSON.stringify([
      schema.newWindowGroup(),
    ]);

  if (efficiency === 'mini_split')
    batch.mini_split_tons = JSON.stringify([schema.newMiniSplit()]);

  api._updateCustomer(customerID, {
    [efficiency]: 'no',
    has_touched_cost: { ...currCustomer.has_touched_cost, [efficiency]: false },
    has_touched_customer_cost: {
      ...currCustomer.has_touched_customer_cost,
      [efficiency]: false,
    },
    ...batch,
  });
};
