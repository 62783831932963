import MpuBreakerRating01 from '../MainPanelUpgrade/Mpu-Breaker-Rating-01.jpg';
import MpuBreakerRating02 from '../MainPanelUpgrade/Mpu-Breaker-Rating-02.jpg';
import MpuBusRating01 from '../MainPanelUpgrade/Mpu-Bus-Rating-01.jpg';
import MpuBusRating02 from '../MainPanelUpgrade/Mpu-Bus-Rating-02.jpg';

// TODO: Better to have an explicit file route
import { ExampleImage } from '..';

export const MPU_BUS_RATING: ExampleImage[] = [
  { source: MpuBusRating01 },
  { source: MpuBusRating02 },
];

export const MPU_BREAKER_RATING: ExampleImage[] = [
  { source: MpuBreakerRating01 },
  { source: MpuBreakerRating02 },
];
