import React, { ReactElement } from 'react';

import * as common from '../../../common';

import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreIcon from '@mui/icons-material/MoreVert';
import Pad from '../Pad';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { auth } from '../../fire';
import { Link } from 'react-router-dom';

export interface NavBarProps {
  children?: ReactElement;
}

export default React.memo<NavBarProps>(function NavBar({ children }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-haspopup="true" color="inherit">
          <AccountCircle />
        </IconButton>
        <Typography>{auth.currentUser?.displayName}</Typography>
      </MenuItem> */}
      <MenuItem onClick={() => auth.signOut()}>
        <IconButton size="large" aria-haspopup="true" color="inherit">
          <LogoutIcon sx={{ fontSize: '32px' }} />
        </IconButton>
        <Typography>Log Out</Typography>
      </MenuItem>

      <MenuItem>
        <Typography>
          Version: {process.env['REACT_APP_APP_VERSION']} (#
          {process.env['REACT_APP_COMMIT_SHORT_HASH']})
        </Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" color="inherit">
          <AccountCircle />
        </IconButton>
        <Typography>{auth.currentUser?.displayName}</Typography>
      </MenuItem>

      <MenuItem onClick={() => auth.signOut()}>
        <IconButton size="large" aria-haspopup="true" color="inherit">
          <LogoutIcon sx={{ fontSize: '32px' }} />
        </IconButton>
        <Typography>Log Out</Typography>
      </MenuItem>
      <MenuItem>
        <Typography>
          Version: {process.env['REACT_APP_APP_VERSION']} (#
          {process.env['REACT_APP_COMMIT_SHORT_HASH']})
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <Box
              sx={{
                display: {
                  xs: children ? 'none' : 'flex',
                  sm: 'flex',
                },
                marginRight: '40px',
                width: {
                  xs: '80px',
                  sm: '120px',
                },
              }}
            >
              <img
                src={common.LOGO_BIG}
                alt="Logotype"
                style={{ width: '100%' }}
              />
            </Box>
          </Link>

          {children && children}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Typography sx={{ alignSelf: 'center' }}>
              {auth.currentUser?.displayName}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Pad amt={30} row />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, padding: 0 }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{ padding: 0 }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
});

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }))

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }))
