import * as E from 'redux-saga/effects';
import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../api';
import * as common from './common';
import * as utils from '../utils';

const PREFIX = 'settings';

export type ValidRootValue =
  | readonly unknown[]
  | undefined
  | utils.Primitive
  | Settings;

export interface Settings extends Readonly<Record<string, ValidRootValue>> {}

const initialState: Settings = utils.emptyObj;

const settingsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    /**
     * Object.assign(oldSettings, newSettings).
     */
    mergeSettings(
      settings,
      { payload: { newSettings } }: PayloadAction<{ newSettings: Settings }>,
    ) {
      Object.assign(settings, newSettings);
    },
    setSetting(
      settings,
      {
        payload: { key, value },
      }: PayloadAction<{ key: string; value: ValidRootValue }>,
    ) {
      // Clash between immer and Readonly<>
      // @ts-expect-error
      settings[key] = value;
    },
  },
});

export const {
  actions: { mergeSettings, setSetting },
  reducer: settings,
} = settingsSlice;

export const subToEnv = createAction(`${PREFIX}/subToEnv`);

export const unSubFromEnv = createAction(`${PREFIX}/unSubFromEnv`);

// #region selectors

type GlobalState = utils.DeepReadonly<{
  [PREFIX]: Settings;
}>;

export const selectAllSettings = ({ settings }: GlobalState): Settings => {
  if (Object.keys(settings).length > 0) {
    return settings;
  }
  return utils.EMPTY_OBJ;
};

export type SelectSettingParams = utils.DeepReadonly<{
  key: string;
}>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const makeSelectSetting = () =>
  createSelector(
    selectAllSettings,
    (_: GlobalState, args: SelectSettingParams) => args,
    (settings, { key }): ValidRootValue => settings[key],
  );

export const selectSetting =
  <Cast = ValidRootValue>(key: string) =>
  (state: GlobalState): Cast | undefined =>
    state[PREFIX][key] as Cast | undefined;

// #endregion selectors

// #region sagas
const createEnvConn = (emit: (newSettings: Settings) => void) => {
  const ref = api.getDatabase().ref('Misc/env');

  ref.on(
    'value',
    (data) => {
      try {
        const val = data.val() as Settings;
        emit(val);
      } catch (e) {
        const errMsg = utils.processErr(e);
        utils.log(`${createEnvConn.name}*() -> value -> ${errMsg}`);
        utils.log(e);
      }
    },
    (e) => {
      const errMsg = utils.processErr(e);
      utils.log(`${createEnvConn.name}*() -> errCb -> ${errMsg}`);
      utils.log(e);
    },
  );

  return {
    off: () => {
      ref.off();
    },
  };
};

let envConn: ReturnType<typeof createEnvConn> | null = null;

function* envWatcher() {
  const authenticated = selectSetting('isAuth')(yield E.select());
  const signedOff = !authenticated;

  if (authenticated && !envConn) {
    envConn = createEnvConn((newSettings) => {
      common.dispatch(
        mergeSettings({
          newSettings,
        }),
      );
    });
  }

  if (signedOff && envConn) {
    envConn.off();
  }
}

export function* settingsSaga() {
  yield E.takeEvery(Object.values(settingsSlice.actions), envWatcher);
}

//#endregion sagas
