import AllExteriorWallImage01 from '../AllExteriorWalls/All-Exterior-walls-08.jpeg';
import AllExteriorWallImage02 from '../AllExteriorWalls/All-Exterior-walls-09.jpeg';
import AllExteriorWallImage03 from '../AllExteriorWalls/All-Exterior-walls-01.jpeg';
import AllExteriorWallImage04 from '../AllExteriorWalls/All-Exterior-walls-02.jpeg';
import AllExteriorWallImage05 from '../AllExteriorWalls/All-Exterior-walls-03.jpeg';
import AllExteriorWallImage06 from '../AllExteriorWalls/All-Exterior-walls-04.jpeg';
import AllExteriorWallImage07 from '../AllExteriorWalls/All-Exterior-walls-05.jpeg';
import AllExteriorWallImage08 from '../AllExteriorWalls/All-Exterior-walls-06.jpeg';
import AllExteriorWallImage09 from '../AllExteriorWalls/All-Exterior-walls-07.jpeg';

import { ExampleImage } from '..';

export const ALL_EXTERIOR_WALLS_SITE_SURVEY: ExampleImage[] = [
  { source: AllExteriorWallImage01 },
  { source: AllExteriorWallImage02 },
  { source: AllExteriorWallImage03 },
  { source: AllExteriorWallImage04 },
  { source: AllExteriorWallImage05 },
  { source: AllExteriorWallImage06 },
  { source: AllExteriorWallImage07 },
  { source: AllExteriorWallImage08 },
  { source: AllExteriorWallImage09 },
];
