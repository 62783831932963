import React from 'react';
import * as rn from 'react-native';

import * as c from '../../common';
import * as r from '../../react-utils';

import * as gs from '../gStyles';

import Text from './Text';

export interface YesOrNoProps {
  canBeEmpty?: boolean;
  centerText?: boolean;
  grow?: boolean;
  hide?: boolean;
  label?: string;
  on?: c.Surface;
  onPress: (newYesOrNo: typeof c.YesNo) => void;
  value: typeof c.YesNoEmpty;
}

export default React.memo<YesOrNoProps>(function Checkbox({
  canBeEmpty,
  grow,
  hide,
  label,
  on,
  onPress,
  value,
}) {
  //#region theming
  const styles = gs.useThemedStyleSheet(themedStyles, on);
  //#endregion theming
  const handlePressYes = React.useCallback(
    () => void onPress('yes'),
    [onPress],
  );
  const handlePressNo = React.useCallback(() => void onPress('no'), [onPress]);

  return (
    <rn.View
      style={
        grow
          ? hide
            ? gs.inputGrowNewHidden
            : gs.inputGrowNew
          : hide
          ? gs.inputShrinkNewHidden
          : gs.inputShrinkNew
      }
    >
      <Text style={styles.label}>{label}</Text>

      {gs.rowGap16}

      <rn.View style={gs.rowCentered}>
        <rn.TouchableOpacity
          onPress={handlePressYes}
          style={value === 'yes' ? styles.btnSelected : styles.btn}
        >
          <Text style={value === 'yes' ? styles.valueSelected : styles.value}>
            Yes
          </Text>
        </rn.TouchableOpacity>
        <rn.TouchableOpacity
          onPress={handlePressNo}
          style={value === 'no' ? styles.btnSelected : styles.btn}
        >
          <Text style={value === 'no' ? styles.valueSelected : styles.value}>
            No
          </Text>
        </rn.TouchableOpacity>
      </rn.View>

      {!canBeEmpty && value === '' && (
        <Text style={styles.textError}>! Select an option</Text>
      )}
    </rn.View>
  );
});

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const btn = {
    borderBottomWidth: 2,
    borderColor: 'transparent',
    flexGrow: 1,
    paddingVertical: 12,
  };
  const value = {
    color: t.paper.color,
    fontFamily: t.paper.fontFamily,
    fontSize: 18,
    fontWeight: '300' as const,
    textAlign: 'center' as const,
    ...gs.userSelectNone,
  };
  return {
    containerActionButtons: {
      ...rn.StyleSheet.absoluteFillObject,
      flexDirection: 'row',
      right: '12%',
    },
    btn,
    btnSelected: { ...btn, borderBottomColor: t[on].highlight },
    grow: {
      ...gs.inputGrow,
      // TODO: do not rely on surface, find way around it
      marginHorizontal:
        r.isMobile && on === 'canvas' ? t[on].paddingHorizontal : undefined,
    },
    label: {
      color: t.paper.color,
      fontFamily: t.paper.fontFamily,
      fontSize: 18,
      fontWeight: '400' as const,
      letterSpacing: 0.8,
      ...gs.userSelectNone,
    },
    textError: { color: t.danger, fontFamily: t.fontFamily },
    value,
    valueSelected: { ...value, fontWeight: '400' },
  };
});
